import React, { useState, useEffect } from "react";
import { ChevronDown, X, SlidersHorizontal } from "lucide-react";
import { predefinedTerms } from "../../../utils/predefinedTerms";
import { searchEstablishments } from "../../../services/establishements.services";

const FilterBar = ({ onResultsUpdate, currentCity }) => {
  const [filters, setFilters] = useState({
    label: "",
    type: "",
    price: "",
    ecoNote: "",
    distance: 10000,
  });
  const [prevFilters, setPrevFilters] = useState(filters);
  const [isOpen, setIsOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);

  useEffect(() => {
    const filtersChanged =
      JSON.stringify(filters) !== JSON.stringify(prevFilters);
    if (filtersChanged) {
      const fetchEstablishments = async () => {
        try {
          const lat = currentCity.lat;
          const lon = currentCity.lon;
          const searchTerm = "";
          const results = await searchEstablishments(
            searchTerm,
            lat,
            lon,
            filters.distance,
            {
              price: filters.price,
              ecoNote: filters.ecoNote,
              type: filters.type,
              labels: filters.label ? [filters.label] : [],
            }
          );
          onResultsUpdate(results);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des établissements :",
            error
          );
        }
      };
      fetchEstablishments();
      setPrevFilters(filters);

      const activeCount = Object.values(filters).filter(
        (value) => value !== "" && value !== 10000
      ).length;
      setActiveFilters(activeCount);
    }
  }, [filters, prevFilters, onResultsUpdate, currentCity]);

  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      label: "",
      type: "",
      price: "",
      ecoNote: "",
      distance: 10000,
    });
  };

  const commonSelectStyles =
    "w-full h-12 px-3 py-2 bg-white border border-tertiary-300 rounded-8 appearance-none cursor-pointer text-primary-600 text-poppins-small focus:outline-none focus:ring-2 focus:ring-primary-400 focus:border-transparent";

  return (
    <div className="w-full bg-white shadow-sm rounded-8">
      {/* Mobile View */}
      <div
        className="lg:hidden overflow-x-auto scrollbar-hide"
        style={{
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          WebkitOverflowScrolling: "touch",
        }}
      >
        <div className="flex flex-row gap-3 p-4 min-w-max">
          {/* Labels Filter */}
          <div className="relative min-w-[160px]">
            <select
              value={filters.label}
              onChange={(e) => handleFilterChange("label", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Tous les labels</option>
              {predefinedTerms
                .filter((term) => term.category === "labels")
                .map((term) => (
                  <option key={term.value} value={term.value}>
                    {term.label}
                  </option>
                ))}
            </select>
            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          {/* Type Filter */}
          <div className="relative min-w-[160px]">
            <select
              value={filters.type}
              onChange={(e) => handleFilterChange("type", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Type de cuisine</option>
              {predefinedTerms
                .filter((term) => term.category === "type de cuisine")
                .map((term) => (
                  <option key={term.value} value={term.value}>
                    {term.label}
                  </option>
                ))}
            </select>
            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          {/* Price Filter */}
          <div className="relative min-w-[120px]">
            <select
              value={filters.price}
              onChange={(e) => handleFilterChange("price", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Prix</option>
              <option value="1">€</option>
              <option value="2">€€</option>
              <option value="3">€€€</option>
              <option value="4">€€€€</option>
            </select>
            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          {/* Note Filter */}
          <div className="relative min-w-[120px]">
            <select
              value={filters.ecoNote}
              onChange={(e) => handleFilterChange("ecoNote", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Note</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          {/* Distance Filter */}
          <div className="relative min-w-[200px] h-12 bg-white border border-tertiary-300 rounded-8 px-3 py-2">
            <div className="flex justify-between text-primary-600 text-poppins-small">
              <span>Distance</span>
              <span>{filters.distance / 1000} km</span>
            </div>
            <input
              type="range"
              min="1000"
              max="20000"
              step="1000"
              value={filters.distance}
              onChange={(e) =>
                handleFilterChange("distance", Number(e.target.value))
              }
              className="w-full h-1 mt-1 bg-tertiary-300 rounded-lg appearance-none cursor-pointer accent-primary-600"
            />
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block p-4">
        <div className="grid grid-cols-5 gap-4">
          {/* Same filters as mobile but in grid layout */}
          <div className="relative">
            <select
              value={filters.label}
              onChange={(e) => handleFilterChange("label", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Tous les labels</option>
              {predefinedTerms
                .filter((term) => term.category === "labels")
                .map((term) => (
                  <option key={term.label} value={term.label}>
                    {term.name}
                  </option>
                ))}
            </select>

            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          <div className="relative">
            <select
              value={filters.type}
              onChange={(e) => handleFilterChange("type", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Type de cuisine</option>
              {predefinedTerms
                .filter((term) => term.category === "type de cuisine")
                .map((term) => (
                  <option key={term.value} value={term.value}>
                    {term.label}
                  </option>
                ))}
            </select>
            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          <div className="relative">
            <select
              value={filters.price}
              onChange={(e) => handleFilterChange("price", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Prix</option>
              <option value="1">€</option>
              <option value="2">€€</option>
              <option value="3">€€€</option>
              <option value="4">€€€€</option>
            </select>
            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          <div className="relative">
            <select
              value={filters.ecoNote}
              onChange={(e) => handleFilterChange("ecoNote", e.target.value)}
              className={commonSelectStyles}
            >
              <option value="">Note</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
            <ChevronDown
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-primary-600"
              size={16}
            />
          </div>

          <div className="relative h-12 bg-white border border-tertiary-300 rounded-8 px-3 py-2">
            <div className="flex justify-between text-primary-600 text-poppins-small">
              <span>Distance</span>
              <span>{filters.distance / 1000} km</span>
            </div>
            <input
              type="range"
              min="1000"
              max="20000"
              step="1000"
              value={filters.distance}
              onChange={(e) =>
                handleFilterChange("distance", Number(e.target.value))
              }
              className="w-full h-1 mt-1 bg-tertiary-300 rounded-lg appearance-none cursor-pointer accent-primary-600"
            />
          </div>
        </div>

        {/* Active Filters Count */}
        {activeFilters > 0 && (
          <div className="flex justify-between items-center mt-4 text-poppins-small text-primary-600">
            <span>
              {activeFilters} filtre{activeFilters > 1 ? "s" : ""} actif
              {activeFilters > 1 ? "s" : ""}
            </span>
            <button
              onClick={clearFilters}
              className="flex items-center gap-1 text-primary-600 hover:text-primary-700"
            >
              <X size={16} />
              Réinitialiser
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
