import React, { useState } from "react";
import Input from "../elements/Input/Input";
import Button from "../elements/Button/Button";

const ContactMail = () => {
  const [status, setStatus] = useState(""); // "SUCCESS", "ERROR", "LOADING" ou ""
  const [consent, setConsent] = useState(false); // Pour la case RGPD

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!consent) return; // Empêche l'envoi si la case n'est pas cochée

    setStatus("LOADING");
    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/mqakozbb", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setStatus("SUCCESS");
        form.reset();
        setConsent(false);
      } else {
        setStatus("ERROR");
      }
    } catch (error) {
      setStatus("ERROR");
    }
  };

  return (
    <div className="flex flex-col items-center bg-[#F4F1E9] py-8 md:py-12 px-4 md:px-6">
      {/* Section titre / sous-titre */}
      <div className="w-full max-w-6xl mb-8 md:mb-12 text-center md:text-left">
        <p className="text-roca-h3 mb-2">Vous voulez en savoir plus&nbsp;?</p>
        <h1 className="text-poppins-h6">
          Saisissez votre adresse e-mail pour recevoir plus d’informations sur
          LabelAdresse.
        </h1>
      </div>

      {/* Formulaire */}
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-6xl bg-white rounded-20 p-6 shadow-md flex flex-col gap-6"
      >
        {/* Champ e-mail */}
        <Input
          type="email"
          name="email"
          label="Adresse e-mail"
          placeholder="Entrez votre adresse e-mail"
          required
        />

        {/* Bouton d'envoi */}
        <div className="flex justify-center">
          <Button
            colorType="primary"
            type="submit"
            disabled={!consent || status === "LOADING"}
          >
            {status === "LOADING" ? "Envoi..." : "Recevoir l'info"}
          </Button>
        </div>

        {/* Case à cocher RGPD */}
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={consent}
            onChange={() => setConsent(!consent)}
            className="form-checkbox h-4 w-4 text-orange-500"
          />
          <span className="text-sm text-gray-700">
            J’accepte que mon adresse e-mail soit utilisée pour m’envoyer des
            informations sur LabelAdresse. Consultez notre{" "}
            <a
              href="/privacy-policy"
              rel="noopener noreferrer"
              className="underline text-orange-500"
            >
              Politique de Confidentialité
            </a>
            .
          </span>
        </label>
      </form>

      {/* Messages de succès / erreur */}
      {status === "SUCCESS" && (
        <p className="text-green-600 mt-4">
          Merci&nbsp;! Votre demande a bien été envoyée.
        </p>
      )}
      {status === "ERROR" && (
        <p className="text-red-600 mt-4">
          Une erreur est survenue. Veuillez réessayer plus tard.
        </p>
      )}
    </div>
  );
};

export default ContactMail;
