import React, { useEffect, useState } from "react";
import { getEstablishmentsByOwner } from "../../services/establishements.services";
import { useAuth } from "../Auth/Provider/AuthProvider";
import CardEstablishment from "../Owner/MyEstablishement/CardEstablishment";
import { getUserById } from "../../services/user.services";

const ModalOpenEstablishement = ({ isOpen, onClose, selectedLabel }) => {
  const [establishments, setEstablishments] = useState([]);
  const [dataUser, setDataUser] = useState({});

  const { user } = useAuth();

  useEffect(() => {
    if (!isOpen) return; // On récupère les établissements seulement si la modal est ouverte
    const fetchMyEstablishments = async () => {
      const res = await getEstablishmentsByOwner(user?.id);
      if (!res) return;
      const fetchedEstablishments = res.establishments || [];
      setEstablishments(fetchedEstablishments);
    };
    const getDetailsUser = async () => {
      const _user = await getUserById(user.id);
      setDataUser(_user);
    };
    getDetailsUser();
    fetchMyEstablishments();
  }, [user?.id, isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Fond semi-transparent */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      {/* Contenu de la modal */}
      <div className="bg-white rounded-lg shadow-lg z-10 p-6 max-h-[90vh] overflow-auto relative">
        {/* Bouton de fermeture */}
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &#x2715;
        </button>
        <h2 className="text-xl font-bold mb-4">
          Sélectionnez un établissement
        </h2>
        <div className="flex flex-col gap-5">
          {establishments?.length > 0 ? (
            establishments.map((establishment) => (
              <CardEstablishment
                disabled={
                  !dataUser.isVerifiedManualy ||
                  establishment.isWaitingForValidation
                }
                disabledMessage={
                  !dataUser.isVerifiedManualy
                    ? "Votre compte est en attente de validation"
                    : establishment.isWaitingForValidation
                    ? "En attente de validation"
                    : ""
                }
                key={establishment?._id}
                establishment={establishment}
                isSelect={true}
                selectedLabel={selectedLabel?.slugLabel} // on transmet le slug du label
              />
            ))
          ) : (
            <p>Aucun établissement trouvé.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalOpenEstablishement;
