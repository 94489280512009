import React from "react";
import CardProPart from "./CardProPart";
import { useNavigate } from "react-router-dom";
import registerImage from "../../../assets/images/Login/Login2.png";

const SelectRegister = () => {
  const navigate = useNavigate();

  const goToPro = () => {
    navigate("/register/pro");
  };

  const goToPart = () => {
    navigate("/register/part");
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row overflow-hidden">
      <div className="hidden md:block md:w-[684px] p-4 rounded-20">
        <img src={registerImage} alt="Inscription" className="w-full h-full" />
      </div>

      <div className="flex-1 flex items-center justify-center p-4">
        <div className="flex flex-col gap-5 bg-white rounded-20 w-full max-w-[540px] h-[346px] justify-center items-center">
          <h1 className="w-full text-center text-roca-h4 !text-secondary-600">
            Je suis
          </h1>
          <div className="flex gap-5">
            <CardProPart text="Particulier" onClick={goToPart} />
            <CardProPart text="Entreprise" onClick={goToPro} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectRegister;
