import React, { useState } from "react";
import { changeStatusRequest } from "../../../../services/labelRequest.services";

const StatusSelect = ({ currentStatus, requestId, onStatusChange }) => {
  const [status, setStatus] = useState(currentStatus);
  const [isLoading, setIsLoading] = useState(false);

  const statusOptions = [
    { value: "pending", label: "En attente" },
    { value: "in_review", label: "En cours de révision" },
    { value: "approved", label: "Approuvé" },
    { value: "rejected", label: "Rejeté" },
    { value: "needs_more_info", label: "Plus d'informations requises" },
  ];

  const getStatusColor = (status) => {
    const colors = {
      pending: "bg-yellow-100 text-yellow-800",
      in_review: "bg-blue-100 text-blue-800",
      approved: "bg-green-100 text-green-800",
      rejected: "bg-red-100 text-red-800",
      needs_more_info: "bg-orange-100 text-orange-800",
    };
    return colors[status] || "bg-gray-100 text-gray-800";
  };

  const handleStatusChange = async (e) => {
    e.stopPropagation(); // Empêcher la propagation du clic
    const newStatus = e.target.value;
    setIsLoading(true);
    try {
      await changeStatusRequest(requestId, { status: newStatus });
      setStatus(newStatus);
      if (onStatusChange) {
        onStatusChange(newStatus);
      }
    } catch (error) {
      console.error("Error changing status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectClick = (e) => {
    e.stopPropagation(); // Empêcher la propagation du clic sur le select
  };

  return (
    <div className="relative" onClick={handleSelectClick}>
      <select
        value={status}
        onChange={handleStatusChange}
        disabled={isLoading}
        className={`appearance-none w-full px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500 ${getStatusColor(
          status
        )}`}
      >
        {statusOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
        <svg
          className="h-4 w-4 text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
  );
};

export default StatusSelect;
