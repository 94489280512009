import React from "react";
import HeaderLabel from "./HeaderLabel";
import useLabels from "../../hooks/labels/useLabels";
import CardLabel from "./CardLabel";
import Button from "../elements/Button/Button";
import { navigate } from "@storybook/addon-links";

const Labels = () => {
  const { labels } = useLabels();

  return (
    <div className="px-4">
      <HeaderLabel />

      <div className="container mx-auto flex flex-col items-center justify-center mt-8">
        <div className="w-full max-w-6xl">
          <h1 className="text-roca-h4 mb-8 text-start">
            Label que nous défendons
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center justify-items-center">
            {labels.length > 0 ? (
              labels.map((label) => <CardLabel key={label._id} label={label} />)
            ) : (
              <p>Aucun label disponible</p>
            )}
          </div>
          {/* <div className="flex justify-center items-center mt-8">
            <Button AddtionnalClasses={"mt-8"} colorType="secondary" onClick={() => {
              navigate('/')
            }}>
              Obtenir un label
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Labels;
