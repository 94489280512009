import { useLocation } from "react-router-dom";
import NavBar from "../src/components/NavBar/NavBar";

const NavigationWrapper = ({ children }) => {
  const location = useLocation();
  const hideNavBarRoutes = [
    "/login",
    "/register",
    "/register/pro",
    "/register/part",
  ];

  return (
    <>
      {/* Only render NavBar if the current path is not /login or /register */}
      {!hideNavBarRoutes.includes(location.pathname) && <NavBar />}
      {children}
    </>
  );
};

export default NavigationWrapper;
