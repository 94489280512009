import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Card = ({
  isAddEstablishment,
  isTest,
  label,
  additionalClassname,
  subLine,
  subLineIcon,
  onClick,
  isDisabled,
}) => {
  const getCardStyles = () => {
    let baseStyles = "";

    if (isTest) {
      baseStyles =
        "bg-quinaire-600 text-poppins-small bg-opacity-10 border border-quinaire-600";
    } else if (isAddEstablishment) {
      baseStyles =
        "bg-secondary-600 text-poppins-small bg-opacity-10 border border-secondary-600";
    }

    if (isDisabled) {
      return `${baseStyles} opacity-50 cursor-not-allowed relative`;
    }

    return `${baseStyles} hover:shadow-md transition-all duration-200 cursor-pointer`;
  };

  const getLabel = () => {
    if (isTest) {
      return "+ Passer le test";
    } else if (isAddEstablishment) {
      return "+ Ajouter un restaurant";
    }
    return "";
  };

  const handleClick = (e) => {
    if (!isDisabled && onClick) {
      onClick(e);
    }
  };

  return (
    <div className="flex flex-col relative">
      <div
        onClick={handleClick}
        className={`flex flex-col items-center justify-center p-6 rounded-lg ${getCardStyles()} w-64 h-36 shadow-sm ${additionalClassname}`}
      >
        <div className="text-lg font-medium relative">
          {getLabel()}
          {isDisabled && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="absolute inset-0 bg-gray-900 bg-opacity-50 rounded-lg flex items-center justify-center">
                <FontAwesomeIcon icon={faLock} className="text-white text-xl" />
              </div>
            </div>
          )}
        </div>
      </div>
      <p className="flex flex-row gap-1 items-center justify-end text-sm text-gray-500 mt-4">
        {subLineIcon && <FontAwesomeIcon icon={subLineIcon} className="mr-2" />}
        {subLine}
        {isDisabled && (
          <span className="text-red-500 ml-2">(Fonctionnalité bloquée)</span>
        )}
      </p>
    </div>
  );
};
export default Card;
