import React from "react";
import mapFrance from "../../assets/images/Stats/mapFrance.svg";

const SectionMap = () => {
  return (
    <div className="flex flex-col items-center py-12 px-6 h-auto md:h-screen">
      <div className="w-full max-w-6xl mb-12">
        <p className="text-poppins-small !text-white">
          Un coup d'œil sur les statistiques
        </p>
        <h1 className="text-roca-h4-5 !text-white mt-4">
          Nous croyons à l'avenir des <br /> restaurants verts en France !
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl">
        {/* Colonne de gauche : image */}
        <div className="flex justify-center">
          <img
            src={mapFrance}
            alt="Illustration carte de France"
            className="w-full h-auto object-contain
                       max-w-[250px] sm:max-w-[300px] md:max-w-[400px] lg:max-w-[500px] xl:max-w-[600px]"
          />
        </div>

        {/* Colonne de droite : conteneur des cartes */}
        <div className="relative w-full h-auto md:h-full">
          {/* Affichage desktop : cartes positionnées */}
          <div className="hidden md:block">
            <div
              className="absolute left-1/2 top-1/2 bg-other-_bleu rounded-20 
                            flex flex-col justify-center items-center w-[180px] h-[180px] 
                            sm:w-[200px] sm:h-[200px] md:w-[220px] md:h-[220px] 
                            lg:w-[240px] lg:h-[240px] shadow-2xl"
            >
              <h1 className="text-roca-h5 !text-white">77%</h1>
              <p className="text-poppins-extra-small text-center px-6">
                des Français aimeraient trouver des produits alimentaires locaux
                et bio en restaurants.
              </p>
            </div>

            <div
              className="absolute left-1/2 top-1/2 transform 
                            -translate-x-[calc(100%-30px)] -translate-y-[calc(100%-30px)]
                            bg-white rounded-20 flex flex-col justify-center items-center 
                            w-[180px] h-[180px] sm:w-[200px] sm:h-[200px] md:w-[220px] md:h-[220px] 
                            lg:w-[240px] lg:h-[240px] shadow-2xl"
            >
              <h1 className="text-roca-h5 !text-primary-600">37%</h1>
              <p className="text-poppins-extra-small text-center !text-primary-600 px-6">
                Cependant, seulement 37 % des restaurants en proposent.
              </p>
            </div>
          </div>

          {/* Affichage mobile : cartes empilées */}
          <div className="flex flex-col items-center md:hidden mt-4">
            <div className="w-full flex justify-center">
              <div
                className="bg-other-_bleu rounded-20 flex flex-col justify-center items-center 
                              w-[180px] h-[180px] sm:w-[200px] sm:h-[200px] shadow-2xl"
              >
                <h1 className="text-roca-h5 !text-white">77%</h1>
                <p className="text-poppins-extra-small text-center px-6">
                  des Français aimeraient trouver des produits alimentaires
                  locaux et bio en restaurants.
                </p>
              </div>
            </div>

            <div className="w-full flex justify-center mt-4">
              <div
                className="bg-white rounded-20 flex flex-col justify-center items-center 
                              w-[180px] h-[180px] sm:w-[200px] sm:h-[200px] shadow-2xl"
              >
                <h1 className="text-roca-h5 !text-primary-600">37%</h1>
                <p className="text-poppins-extra-small text-center !text-primary-600 px-6">
                  Cependant, seulement 37 % des restaurants en proposent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionMap;
