import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFilePdf,
  faFileImage,
  faSpinner,
  faExclamationTriangle,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

const PreviewOfFile = ({ file, handleViewFile }) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!file) {
      setPreview(null);
      setLoading(false);
      return;
    }

    const generatePreview = async () => {
      setLoading(true);
      setError(null);
      try {
        if (file.fileUrl) {
          setPreview(file.fileUrl);
        } else if (file.mimeType?.startsWith("image/")) {
          setPreview("unsupported");
        } else if (file.mimeType === "application/pdf") {
          setPreview("pdf");
        } else {
          setPreview("unsupported");
        }
      } catch (err) {
        console.error("Erreur de prévisualisation:", err);
        setError("Erreur lors de la génération de l'aperçu");
      } finally {
        setLoading(false);
      }
    };

    generatePreview();
  }, [file]);

  if (!file) return null;

  if (loading) {
    return (
      <div className="flex items-center justify-center w-20 h-20 bg-gray-100 rounded">
        <FontAwesomeIcon
          icon={faSpinner}
          className="animate-spin text-gray-400"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center w-20 h-20 bg-red-50 rounded">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-red-500"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-2 w-full">
      {file.mimeType === "application/pdf" ? (
        <div className="w-20 h-20 rounded overflow-hidden bg-gray-50">
          <img
            onClick={() => handleViewFile(file.fileUrl)}
            src="https://cdn-icons-png.flaticon.com/512/337/337946.png"
            alt={file.filename}
            className="w-full h-full object-cover hover:opacity-80 cursor-pointer"
          />
        </div>
      ) : file.mimeType?.startsWith("image/") ? (
        <div className="w-20 h-20 rounded overflow-hidden bg-gray-50">
          <img
            onClick={() => handleViewFile(file.fileUrl)}
            src={preview}
            alt={file.filename}
            className="w-full h-full object-cover hover:opacity-80 cursor-pointer"
          />
        </div>
      ) : (
        <div className="flex items-center justify-center w-20 h-20 bg-gray-50 rounded">
          <FontAwesomeIcon icon={faFile} className="text-2xl text-gray-400" />
        </div>
      )}
    </div>
  );
};

export default PreviewOfFile;
