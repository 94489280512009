import {
  faStar,
  faStarHalfAlt,
  faStar as faStarEmpty,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getRatingInStars = (rating) => {
  const stars = [];

  // Ajout des étoiles pleines
  const fullStars = Math.floor(rating);
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <FontAwesomeIcon
        icon={faStar}
        key={`full-${i}`}
        className="text-secondary-500"
      />
    );
  }

  // Ajout de la demi-étoile si nécessaire
  if (rating % 1 >= 0.5) {
    stars.push(
      <FontAwesomeIcon
        icon={faStarHalfAlt}
        key="half"
        className="text-secondary-500"
      />
    );
  }

  // Ajout des étoiles vides pour compléter jusqu'à 5 étoiles
  const emptyStars = 5 - stars.length;
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <FontAwesomeIcon
        icon={faStarEmpty}
        key={`empty-${i}`}
        className="text-gray-300"
      />
    );
  }

  return stars;
};
