import React, { useState } from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { createReview } from "../../../services/review.services";

const ReviewModal = ({ isOpen, onClose, establishmentId, onReviewSubmit }) => {
  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRatingClick = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = async () => {
    if (rating === 0 || title.trim() === "" || comment.trim() === "") {
      Swal.fire({
        title: "Veuillez remplir tous les champs",
        icon: "error",
      });
      return;
    }

    const reviewData = {
      rating,
      title,
      comment,
    };

    try {
      setIsSubmitting(true);
      const newReview = await createReview(reviewData, establishmentId);
      Swal.fire({
        title: "Merci pour votre avis !",
        icon: "success",
      });
      onReviewSubmit(newReview.data); // Mise à jour de la liste d'avis
      onClose();
    } catch (error) {
      console.error("Erreur lors de la soumission de l'avis", error);
      Swal.fire({
        title: "Erreur lors de la soumission de l'avis",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3">
        <h2 className="text-roca-h6 mb-4">Laisser un avis</h2>

        <div className="flex mb-4">
          {[...Array(5)].map((_, index) => (
            <FontAwesomeIcon
              key={index}
              icon={faStar}
              className={`cursor-pointer ${
                index < rating ? "text-yellow-500" : "text-gray-300"
              }`}
              onClick={() => handleRatingClick(index)}
            />
          ))}
        </div>

        <input
          type="text"
          className="w-full p-2 border rounded-md mb-4"
          placeholder="Titre"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <textarea
          className="w-full p-2 border rounded-md mb-4"
          rows="4"
          placeholder="Votre commentaire"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded-md mr-2"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Annuler
          </button>
          <button
            className="px-4 py-2 bg-green-500 text-white rounded-md"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Envoi..." : "Soumettre"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
