export const initFacebookSDK = () => {
  return new Promise((resolve) => {
    // Si le SDK est déjà chargé
    if (window.FB) {
      resolve();
      return;
    }

    // Configuration
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1933079873861200", // Votre app ID
        cookie: true,
        xfbml: true,
        version: "v18.0",
      });

      resolve();
    };

    // Chargement du script
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/fr_FR/sdk.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";

    script.onload = () => {
    };

    document.head.appendChild(script);
  });
};
