import axios from "axios";
//
const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = process.env.REACT_APP_API_URL_LOCAL;

export const updateUserById = async (userId, user) => {
  try {
    const response = await axios.patch(`${API_URL}/users/${userId}`, user);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/users/${userId}`);
    return response.data.user;
  } catch (error) {
    console.error("Error getting user:", error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/users`);
    return response.data;
  } catch (error) {
    console.error("Error getting users:", error);
    throw error;
  }
};

export const deleteUserById = async (userId) => {
  try {
    const response = await axios.delete(`${API_URL}/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const getAllAuditors = async () => {
  try {
    const response = await axios.get(`${API_URL}/users`);
    const auditors = response.data.users.filter(
      (user) => user.role === "auditor"
    );
    return auditors;
  } catch (error) {
    console.error("Error getting auditors:", error);
    throw error;
  }
};

export const acceptOwnerRequest = async (userId) => {
  try {
    const response = await axios.patch(`${API_URL}/users/accept/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error accepting owner request:", error);
    throw error;
  }
};

export const rejectOwnerRequest = async (userId) => {
  try {
    const response = await axios.patch(`${API_URL}/users/reject/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error rejecting owner request:", error);
    throw error;
  }
};
