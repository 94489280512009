import React, { useState, useEffect } from "react";
import Input from "../elements/Input/Input";
import Button from "../elements/Button/Button";
import useUser from "../../hooks/useUser/useUser";

const InformationCard = () => {
  const { userInfo, actionUser, isLoading, error } = useUser();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  const [successMessage, setSuccessMessage] = useState(null);

  // Préremplir le formulaire avec les données utilisateur au chargement
  useEffect(() => {
    if (userInfo) {
      setFormData({
        firstname: userInfo.firstname || "",
        lastname: userInfo.lastname || "",
        email: userInfo.email || "",
        phone: userInfo.phone || "",
      });
    }
  }, [userInfo]);

  // Efface le message de succès après 2 secondes
  useEffect(() => {
    if (successMessage) {
      const timeout = setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(null);

    try {
      await actionUser.updateUser(formData);
      setSuccessMessage("Vos informations ont été mises à jour avec succès.");
    } catch (err) {
      console.error("Erreur lors de la mise à jour :", err);
    }
  };

  return (
    <div className="">
      <h1 className="text-poppins-h6 mb-5">Vos informations personnelles</h1>

      <form onSubmit={handleSubmit}>
        <div className="form-group mb-4">
          <Input
            label="Prénom"
            id="firstname"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            placeholder="Entrez votre prénom"
          />
        </div>
        <div className="form-group mb-4">
          <Input
            label="Nom"
            id="lastname"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            placeholder="Entrez votre nom"
          />
        </div>
        <div className="form-group mb-4">
          <Input
            label="Email"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Entrez votre email"
          />
        </div>
        <div className="form-group mb-4">
          <Input
            label="Téléphone"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Entrez votre numéro de téléphone"
          />
        </div>
        <div className="w-full flex justify-center flex-col items-center gap-5">
          <Button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-400"
          >
            {isLoading ? "Mise à jour en cours..." : "Mettre à jour"}
          </Button>

          {isLoading && <p className="text-blue-500">Chargement...</p>}
          {error && <p className="text-red-500">Erreur : {error}</p>}
          {successMessage && <p className="text-green-500">{successMessage}</p>}
        </div>
      </form>
    </div>
  );
};

export default InformationCard;
