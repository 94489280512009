import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faUpload,
  faSpinner,
  faCheck,
  faTimes,
  faInfoCircle,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

const FileVersions = ({
  file,
  versions,
  isUploading,
  onUploadVersion,
  showVersionHistory,
}) => {
  const [validationStatus, setValidationStatus] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isValidating, setIsValidating] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validation de taille (20MB max)
    const maxSize = 20 * 1024 * 1024;
    if (file.size > maxSize) {
      setValidationStatus("error");
      setValidationMessage("Le fichier doit être inférieur à 20MB");
      setSelectedFile(null);
      return;
    }

    // Validation du type de fichier
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!allowedTypes.includes(file.type)) {
      setValidationStatus("error");
      setValidationMessage(
        "Format de fichier non autorisé (PDF, JPEG, PNG uniquement)"
      );
      setSelectedFile(null);
      return;
    }

    setSelectedFile(file);
    setValidationStatus("info");
    setValidationMessage("Fichier prêt à être validé");
  };

  const handleValidateFile = async () => {
    if (!selectedFile) return;

    try {
      setIsValidating(true);
      await onUploadVersion(selectedFile);
      setValidationStatus("success");
      setValidationMessage("Document validé avec succès");
      setSelectedFile(null); // Réinitialiser après upload réussi
    } catch (error) {
      setValidationStatus("error");
      setValidationMessage("Erreur lors de la validation");
    } finally {
      setIsValidating(false);
    }
  };

  const showUploadButton =
    file.status === "rejected" || file.status === "needs_more_info";

  return (
    <div className="mt-2 flex justify-center items-center flex-col">
      {showUploadButton && (
        <div className="flex flex-col items-center gap-2 mt-2">
          <input
            type="file"
            id={`upload-version-${file._id}`}
            className="hidden"
            onChange={handleFileChange}
            disabled={isUploading}
            accept=".pdf,.jpg,.jpeg,.png"
          />
          <div className="flex gap-2">
            <label
              htmlFor={`upload-version-${file._id}`}
              className={`flex items-center gap-2 cursor-pointer px-4 py-2 rounded-lg transition-colors
                ${
                  isUploading
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-secondary-500 hover:bg-secondary-600 text-white"
                }`}
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faUpload} />
                Sélectionner un fichier
              </div>
            </label>

            {/* Bouton de validation - visible uniquement quand un fichier est sélectionné */}
            {selectedFile && (
              <button
                onClick={handleValidateFile}
                disabled={isValidating}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
                  ${
                    isValidating
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-green-500 hover:bg-green-600 text-white"
                  }`}
              >
                {isValidating ? (
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin"
                    />
                    Validation...
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faThumbsUp} />
                    Valider le fichier
                  </div>
                )}
              </button>
            )}
          </div>

          {/* Affichage du fichier sélectionné */}
          {selectedFile && (
            <div className="text-sm text-gray-600 mt-2">
              Fichier sélectionné : {selectedFile.name} (
              {Math.round(selectedFile.size / 1024)} KB)
            </div>
          )}

          {validationStatus && (
            <div
              className={`flex items-center gap-2 text-sm ${
                validationStatus === "error"
                  ? "text-red-500"
                  : validationStatus === "success"
                  ? "text-green-500"
                  : "text-blue-500"
              }`}
            >
              <FontAwesomeIcon
                icon={
                  validationStatus === "error"
                    ? faTimes
                    : validationStatus === "success"
                    ? faCheck
                    : faInfoCircle
                }
              />
              {validationMessage}
            </div>
          )}

          {versions?.length > 0 && (
            <button
              onClick={() => showVersionHistory(file._id)}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-800"
            >
              <FontAwesomeIcon icon={faHistory} />
              Voir l'historique ({versions?.length})
            </button>
          )}

          <div className="text-xs text-gray-500 mt-1">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            Formats acceptés: PDF, JPEG, PNG (max 20MB)
          </div>
        </div>
      )}
    </div>
  );
};

export default FileVersions;
