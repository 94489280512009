import { useEffect, useCallback, useState } from "react";
import { useAuth } from "../../components/Auth/Provider/AuthProvider";
import {
  getUserById,
  updateUserById,
  deleteUserById,
  getAllUsers,
} from "../../services/user.services";
import { getReviewsByUserId } from "../../services/review.services";

const useUser = () => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [users, setUsers] = useState([]);
  const [reviews, setReviews] = useState([]); // Stocker les avis
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch a single user
  const fetchUser = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      if (user?.id) {
        const userData = await getUserById(user.id);
        setUserInfo(userData);
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setError("Failed to fetch user data");
    } finally {
      setIsLoading(false);
    }
  }, [user?.id]);

  // Fetch all users
  const fetchAllUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const usersData = await getAllUsers();
      setUsers(usersData);
    } catch (error) {
      console.error("Failed to fetch all users:", error);
      setError("Failed to fetch all users");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Update a user
  const updateUser = useCallback(
    async (updatedData) => {
      try {
        setIsLoading(true);
        setError(null);
        if (user?.id) {
          await updateUserById(user.id, updatedData);
          await fetchUser();
        }
      } catch (error) {
        console.error("Failed to update user:", error);
        setError("Failed to update user");
      } finally {
        setIsLoading(false);
      }
    },
    [user?.id, fetchUser]
  );

  // Delete a user
  const deleteUser = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      if (user?.id) {
        await deleteUserById(user.id);
        setUserInfo(null);
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
      setError("Failed to delete user");
    } finally {
      setIsLoading(false);
    }
  }, [user?.id]);

  // Fetch reviews by user
  const getReviewsByUser = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      if (user?.id) {
        const reviewsData = await getReviewsByUserId(user.id);
        setReviews(reviewsData);
      }
    } catch (error) {
      console.error("Failed to fetch reviews:", error);
      setError("Failed to fetch reviews");
    } finally {
      setIsLoading(false);
    }
  }, [user?.id]);

  useEffect(() => {
    fetchUser();
    getReviewsByUser(); // Charger les avis au montage
  }, [fetchUser, getReviewsByUser]);

  return {
    userInfo,
    users,
    reviews, // Ajouter les avis à l'objet retourné
    isLoading,
    error,
    actionUser: {
      fetchUser,
      fetchAllUsers,
      updateUser,
      deleteUser,
      getReviewsByUser,
    },
  };
};

export default useUser;
