import { getUserById } from "../../../services/user.services";
import { setAuthToken } from "../../../services/auth.services";
import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode"; // Correction de l'import

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
  }, [token]);

  const decodeToken = (token) => {
    try {
      return jwtDecode(token); // Utilise jwt-decode pour décoder le token
    } catch (error) {
      console.error("Erreur lors du décodage du token :", error);
      return null;
    }
  };

  const handleLogin = (token, from) => {
    setToken(token);
    localStorage.setItem("token", token);
    setAuthToken(token);

    const redirectTo = from;
    navigate(redirectTo);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setAuthToken(null);
    navigate("/");

    window.location.reload();
  };

  useEffect(() => {
    if (!token) return;
  
    const user = decodeToken(token); 
    if (!user || !user.userId) {
      console.warn("Utilisateur non trouvé ou token invalide.");
      return;
    }
  
    const fetchUser = async () => {
      try {
        const _user = await getUserById(user.userId);
        if (!_user) return;
        setUserData(_user);
      } catch (error) {
        console.error("Erreur lors de la récupération des données utilisateur :", error);
      }
    };
    fetchUser();
  }, [token]);
  const isAdmin = token ? decodeToken(token)?.role === "admin" : false;
  const userId = token ? decodeToken(token)?.userId : null;
  const user = token ? decodeToken(token) : null;



  return (
    <GoogleOAuthProvider clientId="506572393905-4f7vj7ct46pbm66f5bs105a1lcap2j1t.apps.googleusercontent.com">
      <AuthContext.Provider
        value={{
          token,
          user,
          userData,
          userId,
          isAdmin,
          handleLogin,
          handleLogout,
        }}
      >
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
};

export const useAuth = () => useContext(AuthContext);
