// useRequest.js
import { useState, useEffect } from "react";
import {
  getLabelRequestsByAuditor,
  getLabelRequestsByEstablishment,
} from "../../services/labelRequest.services";
import {
  addFeedBackOnDocuments,
  changeStatusDocument,
} from "../../services/files.services";

const useRequest = ({ establishmentId, auditorId }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDocumentStatusChange = async (fileId, newStatus) => {
    try {
      await changeStatusDocument(fileId, {
        status: newStatus,
      });

      // Refresh data
      if (auditorId) {
        const response = await getLabelRequestsByAuditor(auditorId);
        setRequests(response.requests);
      } else if (establishmentId) {
        const response = await getLabelRequestsByEstablishment(establishmentId);
        setRequests(response.requests);
      }
    } catch (err) {
      console.error("Error updating document status:", err);
      throw err;
    }
  };

  const handleDocumentFeedback = async (fileId, feedback) => {
    try {
      await addFeedBackOnDocuments(fileId, {
        feedback: feedback,
        auditor: auditorId,
      });

      // Refresh data
      if (auditorId) {
        const response = await getLabelRequestsByAuditor(auditorId);
        setRequests(response.requests);
      } else if (establishmentId) {
        const response = await getLabelRequestsByEstablishment(establishmentId);
        setRequests(response.requests);
      }
    } catch (err) {
      console.error("Error adding feedback:", err);
      throw err;
    }
  };

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true);
        setError(null);

        if (auditorId) {
          const response = await getLabelRequestsByAuditor(auditorId);
          setRequests(response.requests);
        } else if (establishmentId) {
          const response = await getLabelRequestsByEstablishment(
            establishmentId
          );
          setRequests(response.requests);
        }
      } catch (err) {
        console.error("Error fetching requests:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [establishmentId, auditorId]);

  return {
    requests,
    loading,
    error,
    handleDocumentStatusChange,
    handleDocumentFeedback,
  };
};

export default useRequest;
