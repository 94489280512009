// useBackOffice.js
import { useState, useEffect } from "react";
import {
  getAllLabelGroups,
  createLabelGroup,
  updateLabelGroupById,
} from "../../services/labelGroup.services";
import { getAllAuditors } from "../../services/user.services";
import {
  getLabelRequests,
  updateLabelRequestById,
} from "../../services/labelRequest.services";

const useBackOffice = () => {
  const [groups, setGroups] = useState([]);
  const [auditors, setAuditors] = useState([]);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    loadGroups();
    loadAuditors();
    getRequest();
  }, []);

  const loadGroups = async () => {
    try {
      const response = await getAllLabelGroups();
      setGroups(response);
    } catch (error) {
      console.error("Erreur chargement groupes:", error);
    }
  };

  const loadAuditors = async () => {
    try {
      const response = await getAllAuditors();
      setAuditors(response);
    } catch (error) {
      console.error("Erreur chargement auditeurs:", error);
    }
  };

  const createGroup = async (groupData) => {
    try {
      await createLabelGroup(groupData);
      loadGroups();
    } catch (error) {
      console.error("Erreur création groupe:", error);
    }
  };

  const updateGroup = async (id, groupData) => {
    try {
      await updateLabelGroupById(id, groupData);
      loadGroups();
    } catch (error) {
      console.error("Erreur modification groupe:", error);
    }
  };

  const getRequest = async () => {
    try {
      const response = await getLabelRequests();
      setRequests(response);
    } catch (error) {
      console.error("Erreur chargement requêtes:", error);
    }
  };

  const assignGroupToRequest = async (requestId, groupId) => {
    try {
      await updateLabelRequestById(requestId, { group: groupId });
      await getRequest();
    } catch (error) {
      console.error("Erreur lors de l'assignation du groupe:", error);
    }
  };

  return {
    groups,
    auditors,
    requests,
    createGroup,
    updateGroup,
    assignGroupToRequest,
    getRequest,
  };
};

export default useBackOffice;
