import React, { useState, useEffect } from "react";
import Button from "../elements/Button/Button";

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consentValue = localStorage.getItem("cookieConsent");
    if (!consentValue) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "false");
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-0 left-0 w-full z-50 bg-primary-700 text-white px-4 py-4 flex flex-col md:flex-row items-center justify-between gap-2">
      {/* Message */}
      <p className="text-poppins-small !text-white">
        Ce site utilise des cookies pour améliorer votre expérience et analyser
        le trafic. En continuant à utiliser ce site, vous acceptez notre{" "}
        <a
          href="/cookies-policy"
          className="underline text-secondary-300 hover:text-secondary-400"
        >
          Politique de cookies
        </a>
        .
      </p>

      {/* Boutons d'action */}
      <div className="flex gap-2">
        <Button onClick={handleReject} colorType="white">
          Refuser
        </Button>
        <Button onClick={handleAccept} colorType="secondary">
          J'ai compris
        </Button>
      </div>
    </div>
  );
};

export default CookieConsent;
