import React, { useState, useEffect } from "react";
import { getAllCategoriesAndQuestions } from "../../services/faq.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import FaqShow from "./Faq/FaqShow";

const HomePageContact = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [expandedCategoryIndices, setExpandedCategoryIndices] = useState([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const data = await getAllCategoriesAndQuestions();
      setFaqs(data);
    } catch (error) {
      console.error("Failed to fetch FAQs", error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value === "") {
      setExpandedCategoryIndices([]); // Reset category expansion if search is cleared
      return;
    }

    // Find all categories containing the search term
    const indices = faqs.reduce((acc, category, index) => {
      if (
        category.category.toLowerCase().includes(value.toLowerCase()) ||
        category.questions.some((q) =>
          q.question.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        acc.push(index);
      }
      return acc;
    }, []);

    setExpandedCategoryIndices(indices);
  };

  const filteredFaqItems = faqs.filter(
    (category) =>
      category.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      category.questions.some((q) =>
        q.question.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const handleCategoryToggle = (index) => {
    if (expandedCategoryIndices.includes(index)) {
      setExpandedCategoryIndices(
        expandedCategoryIndices.filter((i) => i !== index)
      );
    } else {
      setExpandedCategoryIndices([...expandedCategoryIndices, index]);
    }
  };

  return (
    <div
      className="h-screen flex flex-col justify-start items-center pt-10 "
      id="contact"
    >
      <div className="w-full max-w-3xl text-center">
        <h1 className="text-4xl font-bold mb-8 text-roca-h4-5">
          Comment peut-on vous aider?
        </h1>

        <div className="relative mb-10 flex items-center justify-center">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <input
            type="search"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-4 pl-12 pr-10 rounded-full bg-white shadow-md"
          />
        </div>

        <FaqShow
          faqs={filteredFaqItems}
          expandedCategoryIndices={expandedCategoryIndices}
          onCategoryToggle={handleCategoryToggle}
        />

        {filteredFaqItems.length === 0 && (
          <div className="text-poppins-extra-small mt-6">
            Aucun résultat trouvé.
          </div>
        )}
        <div>
          <div className="text-poppins-extra-small mt-6">
            Vous ne trouvez pas votre réponse ?
          </div>
          <a href="/contact/new" className="underline text-poppins-extra-small">
            Contacter le support
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePageContact;
