import React from "react";
import CardAction from "./CardActions/CardAction";
import {
  faCheckCircle,
  faClock,
  faEye,
  faPaperPlane,
  faPen,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../MyEstablishement/Card";

const ActionsEstablishment = () => {
  const { establishmentId } = useParams();
  const navigate = useNavigate();

  const commonProps = {
    additionalClassname: "w-[348px] h-[181px]",
    establishmentId,
  };

  const handlePassTest = () => {
    navigate("/label-test");
  };

  const handleAddEstablishment = () => {
    navigate("/add-establishment");
  };

  return (
    <div className="container mx-auto flex flex-col items-center">
      <h1 className="text-roca-h4-5 mb-6">Mes Actions</h1>

      <div className="flex flex-row gap-8 justify-center">
        <div>
          <CardAction
            additionalClassname="h-full w-[394px]"
            label="Demander un label"
            icon={faTag}
            navToLink="request-label"
            establishmentId={establishmentId}
          />
        </div>

        <div className="grid grid-cols-2 gap-5 h-[394px]">
          <CardAction
            label="Modifier les informations de mon restaurant"
            icon={faPen}
            navToLink="edit-my-establishement"
            {...commonProps}
          />
          <CardAction
            label="Consulter les documents de mon restaurant"
            icon={faEye}
            navToLink="show-files"
            {...commonProps}
          />
          <CardAction
            label="Suivi de mes demandes"
            icon={faPaperPlane}
            navToLink="follow-my-requests"
            {...commonProps}
          />
          <CardAction
            label="Voir les avis sur mon restaurant"
            icon={faCheckCircle}
            navToLink="show-reviews-establishements"
            {...commonProps}
          />
        </div>
      </div>
      <div className="flex flex-row gap-5 mb-16 mt-16">
        <Card
          onClick={handlePassTest}
          isTest={true}
          isDisabled={false}
          additionalClassname={
            establishmentId
              ? "w-[500px] h-[100px] hover:cursor-pointer"
              : "w-[500px] h-[282px] hover:cursor-pointer"
          }
          subLine={"Cela vous prendra 20 minutes"}
          subLineIcon={faClock}
        />
        <Card
          onClick={handleAddEstablishment}
          isAddEstablishment={true}
          additionalClassname={
            establishmentId
              ? "w-[500px] h-[100px] hover:cursor-pointer"
              : "w-[500px] h-[282px] hover:cursor-pointer"
          }
          subLine={"Cela vous prendra 10 minutes"}
          subLineIcon={faClock}
        />
      </div>
    </div>
  );
};

export default ActionsEstablishment;
