import React from "react";
import useUser from "../../hooks/useUser/useUser";
import InformationCard from "./InformationCard";
import TypOf from "./TypOf";
import OtherInfo from "./OtherInfo";
import Button from "../elements/Button/Button";
import ReviewUser from "./ReviewUser";

const Profil = () => {
  const { userInfo } = useUser();

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="w-full flex justify-center items-center p-4">
        {!userInfo?.isVerifiedManualy && (
          <p className="text-red-500 text-2xl ">
            Votre compte est en attente de validation
          </p>
        )}
      </div>
      <div className="container mx-auto mt-6 flex flex-row items-center justify-between mb-16">
        {/* Titre */}

        <h1 className="text-poppins-h6 mb-6">
          Bonjour, {userInfo?.firstname || "Invité"} {userInfo?.lastname || ""}
          {userInfo?.role === "owner" ? " (Propriétaire)" : ""}
        </h1>

        {/* Boutons */}
        {userInfo?.role === "owner" && (
          <div className="flex flex-row gap-5">
            <Button
              link={"/add-establishment"}
              disabled={!userInfo.isVerifiedManualy}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Ajouter un établissement
            </Button>
            <Button
              link={"/my-establishements"}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Voir mes établissements
            </Button>
          </div>
        )}
      </div>

      {/* Cartes */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white shadow-md p-6 rounded-lg">
          <InformationCard />
        </div>

        {userInfo?.role !== "owner" && userInfo?.role !== "admin" && (
          <div className="bg-white shadow-md p-6 rounded-lg">
            <TypOf userRole={userInfo?.role} />
          </div>
        )}

        <div className="bg-white shadow-md p-6 rounded-lg">
          <OtherInfo />
        </div>

        <div className="bg-white shadow-md p-6 rounded-lg">
          <ReviewUser />
        </div>
      </div>
    </div>
  );
};

export default Profil;
