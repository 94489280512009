import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";

const PhotoEditor = ({
  formData,
  newPhotos,
  deletePhoto,
  handlePhotoChange,
  title,
  category,
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const closeModal = () => {
    setSelectedPhoto(null);
  };

  // Fermer la modal avec Échap
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  // Filtrer les photos en tenant compte d'un défaut pour la catégorie
  const filteredPhotos = formData.photos.filter(
    (photo) => photo.category === category
  );

  return (
    <div className="mt-6">
      <label className="block text-poppins-small text-gray-700 mb-2">
        {title}
      </label>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {filteredPhotos.map((photo) => {
          // Trouver l'index global de la photo
          const globalIndex = formData.photos.findIndex(
            (p) =>
              p.fileName === photo.fileName &&
              (p.category || "establishment") === category
          );
          return (
            <div key={globalIndex} className="relative">
              <img
                src={photo?.fileUrl || ""}
                alt={`Photo ${globalIndex + 1}`}
                className="h-32 w-32 object-cover rounded-md shadow cursor-pointer"
                onClick={() => setSelectedPhoto(photo?.fileUrl || "")}
              />
              <button
                type="button"
                className="absolute top-2 right-2 bg-red-500 h-8 w-8 text-white rounded-full p-1"
                onClick={() => deletePhoto(globalIndex)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          );
        })}
        <input
          type="file"
          multiple
          accept="image/*"
          id={`photo-upload-${category}`}
          className="hidden"
          onChange={(e) => handlePhotoChange(e, category)}
        />
        <label
          htmlFor={`photo-upload-${category}`}
          className="h-32 w-32 bg-gray-200 flex items-center justify-center rounded-md shadow border border-dashed cursor-pointer"
        >
          <span className="text-2xl text-secondary-600">
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </label>
      </div>

      {/* Modal d'aperçu */}
      {selectedPhoto && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedPhoto}
              alt="Large preview"
              className="w-full h-96 rounded-lg bg-white"
            />
            <button
              type="button"
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2"
              onClick={closeModal}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoEditor;
