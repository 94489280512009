import React from "react";
import CardSection from "./CardSection";
import { faMap, faMarker, faStarHalf } from "@fortawesome/free-solid-svg-icons";

const WhyLabelAdresse = () => {
  return (
    <div className="flex flex-col items-center bg-[#F4F1E9] py-12 px-6 h-full">
      {/* Section Title */}
      <div className="w-full max-w-6xl mb-12">
        <p className="text-poppins-extra-small mb-2">Découverte</p>
        <h1 className="text-roca-h4-5 ">Pourquoi Label Adresse ?</h1>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-6xl place-items-center h-full">
        <CardSection
          title={"Le resto qu'il vous faut !"}
          icon={faMap}
          text={"Dégustez dans un restaurant qui reflète toutes vos valeurs."}
        />
        <CardSection
          title={"Découvre ses labels"}
          icon={faMarker}
          text={
            "Découvrez les critères d'un restaurant labellisé éco-responsable."
          }
        />
        <CardSection
          title={"Un avis, un impact !"}
          icon={faStarHalf}
          text={
            "Partagez vos avis pour mettre en lumière votre resto préféré !"
          }
        />
      </div>
    </div>
  );
};

export default WhyLabelAdresse;
