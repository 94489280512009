import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../Auth/Provider/AuthProvider";
import { createSession } from "../../services/stripe.services";
import Button from "../elements/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";

const SubscriptionCard = ({ title, price, options = [], plan, isPrimary }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Créer la session Stripe ou rediriger vers /label-test
  const handleCheckOutSession = async () => {
    if (location.pathname === "/payement") {
      console.log("Redirection vers /label-test");
      navigate("/label-test");
      return;
    } else if (location.pathname === "/label-test/payement") {
      console.log("Création session Stripe pour:", plan);
      try {
        const session = await createSession(plan, user.id);
        if (session && session.data && session.data.url) {
          window.location.href = session.data.url;
        } else {
          console.error("Réponse de session invalide:", session);
        }
      } catch (error) {
        console.error("Échec de création de session de paiement:", error);
      }
    } else {
      console.log("Chemin inconnu, redirection par défaut vers /label-test");
      navigate("/label-test");
    }
  };

  return (
    <div
      className={`w-full sm:max-w-sm md:max-w-md bg-gray-100 rounded-lg shadow-md p-4 sm:p-6 flex flex-col items-start text-black ${
        isPrimary ? "border-2 border-secondary-500 scale-105" : ""
      }`}
    >
      <div className="w-full flex justify-center items-center mb-4">
        <h2
          className={`text-lg sm:text-xl md:text-2xl ${
            isPrimary ? "text-secondary-600" : ""
          } font-bold mb-2`}
        >
          {title}
        </h2>
      </div>
      <div className="w-full flex justify-center items-center mb-4">
        <p className="text-base sm:text-lg mb-1">{price}€</p>
      </div>
      <div className="w-full flex justify-center items-center mb-4">
        <p className="text-sm sm:text-base text-gray-600 mb-4">
          Paiement unique
        </p>
      </div>
      <div className="w-full flex justify-center items-center mb-5">
        <Button
          onClick={handleCheckOutSession}
          colorType="primary"
          className="mb-6 w-full sm:w-auto"
        >
          Choisir &rarr;
        </Button>
      </div>
      <div className="w-full flex justify-center items-center mb-4">
        <p className="text-base sm:text-lg">Inclus dans l'offre</p>
      </div>
      <ul className="space-y-2">
        {options.map((item, index) => (
          <li key={index} className="flex items-center">
            <FontAwesomeIcon icon={faCheck} className="text-gray-900 mr-2" />
            <span className="text-sm sm:text-base">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionCard;
