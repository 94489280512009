import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/Auth/Provider/AuthProvider";
import { login, socialLogin } from "../../services/auth.services";
import Swal from "sweetalert2";

const useSocialLogin = ({ from, onLoginSuccess, goTo }) => {
  const navigate = useNavigate();
  const { handleLogin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const showSuccessAlert = () =>
    Swal.fire({
      icon: "success",
      title: "Connexion réussie !",
      showConfirmButton: false,
      timer: 1500,
    });

  const showErrorAlert = (error) =>
    Swal.fire({
      icon: "error",
      title: "Échec de la connexion",
      text:
        error?.response?.data?.message ||
        "Une erreur est survenue lors de la connexion",
      confirmButtonText: "OK",
    });

  const handleAuthSuccess = async (token) => {
    handleLogin(token);
    await showSuccessAlert();

    if (onLoginSuccess) {
      onLoginSuccess();
    } else if (!goTo) {
      navigate("/");
    }
  };

  const handleAuthError = (error) => {
    console.error("Erreur de connexion:", error);
    showErrorAlert(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await login(formData);
      await handleAuthSuccess(response.data.token);
    } catch (error) {
      handleAuthError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialLogin = async (token, provider) => {
    setIsLoading(true);

    try {
      const response = await socialLogin({ token, provider });
      await handleAuthSuccess(response.data.token);
    } catch (error) {
      handleAuthError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async (response) => {
    if (response?.credential) {
      await handleSocialLogin(response.credential, "google");
    }
  };

  const handleFacebookLogin = async (authResponse) => {

    if (!authResponse?.accessToken) {
      showErrorAlert({ message: "Erreur lors de la connexion Facebook" });
      return;
    }

    setIsLoading(true);
    try {
      const response = await socialLogin({
        token: authResponse.accessToken,
        provider: "facebook",
      });

      if (response.data.token) {
        await handleAuthSuccess(response.data.token);
      } else {
        throw new Error("Token manquant dans la réponse");
      }
    } catch (error) {
      console.error("Facebook login error:", error);
      handleAuthError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAppleLogin = async (response) => {
    if (response?.authorization?.id_token) {
      await handleSocialLogin(response.authorization.id_token, "apple");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return {
    formData,
    isLoading,
    handleChange,
    handleSubmit,
    handleGoogleLogin,
    handleFacebookLogin,
    handleAppleLogin,
  };
};

export default useSocialLogin;
