import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-senary-600 px-4">
      {/* Titre principal (404) */}
      <h1 className="text-roca-h3 font-bold mb-2">404</h1>
      {/* Sous-titre */}
      <p className="text-poppins-h6 text-center mb-6">
        Oups&nbsp;! La page que vous recherchez n’existe pas.
      </p>
      {/* Bouton de retour à l'accueil */}
      <Link
        to="/"
        className="bg-primary-600 text-white px-6 py-3 rounded-20 hover:bg-primary-700 transition-colors"
      >
        Retour à l’accueil
      </Link>
    </div>
  );
};

export default Error;
