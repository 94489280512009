import React, { useEffect, useState } from "react";
import { useAuth } from "../../Auth/Provider/AuthProvider";
import { getGroupsOfAuditor } from "../../../services/labelGroup.services";
import {
  associateLabelRequestToAuditor,
  getLabelRequestsByGroup,
} from "../../../services/labelRequest.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const AllRequests = () => {
  const { user } = useAuth();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTakeRequest = async (request) => {
    try {
      const { value: confirmation } = await Swal.fire({
        title: "Prendre en charge la demande ?",
        text: `Vous êtes sur le point de prendre en charge la demande pour "${request.label}"`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Oui, je prends en charge",
        cancelButtonText: "Annuler",
      });

      if (confirmation) {
        await associateLabelRequestToAuditor(request._id, user.id);
        Swal.fire({
          title: "Demande prise en charge",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        await getRequests();
      }
    } catch (error) {
      console.error("Erreur:", error);
      Swal.fire({
        title: "Une erreur est survenue",
        text: "Impossible de prendre en charge la demande",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const getRequests = async () => {
    try {
      setLoading(true);
      const groupsOfAuditor = await getGroupsOfAuditor(user.id);
      const responses = await Promise.all(
        groupsOfAuditor.map(async (group) => {
          const { data } = await getLabelRequestsByGroup(group._id);
          return data.requests;
        })
      );
      const allRequests = responses.flat();
      allRequests.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setRequests(allRequests);
    } catch (error) {
      console.error("Erreur:", error);
      setError("Une erreur est survenue lors du chargement des données");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getRequests();
    }
  }, [user]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-poppins-small text-primary-600">Chargement...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-poppins-small text-secondary-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-poppins-h5 text-primary-600 mb-6">
        Toutes les demandes
      </h1>
      <div>
        {requests.length > 0 ? (
          requests.map((request) => (
            <RequestCard
              key={request._id}
              request={request}
              onTakeRequest={handleTakeRequest}
            />
          ))
        ) : (
          <p className="text-poppins-small text-primary-400">
            Aucune requête disponible.
          </p>
        )}
      </div>
    </div>
  );
};

const RequestCard = ({ request, onTakeRequest }) => {
  return (
    <div className="bg-white rounded-15 shadow-sm hover:shadow-md transition-all duration-200 p-6">
      <div className="space-y-3">
        <div className="flex items-center justify-between">
          <div className="text-poppins-h6">Information sur la demande</div>
          {request.auditor ? (
            <div className="px-4 py-2 bg-green-100 text-green-600 rounded-10 text-poppins-small">
              Prise en charge par{" "}
              <span className="font-bold">
                {request.auditor.firstname} {request.auditor.lastname}
              </span>
            </div>
          ) : (
            <button
              onClick={() => onTakeRequest(request)}
              className="px-4 py-2 bg-secondary-600 text-white rounded-10 hover:bg-secondary-700 transition-colors duration-200 text-poppins-small flex items-center gap-2"
            >
              <FontAwesomeIcon icon={faPen} />
              Prendre en charge
            </button>
          )}
        </div>
        <div className="flex items-center justify-between">
          <div>
            {request.requester ? (
              <div className="space-y-2">
                <div className="flex items-center flex-col gap-2">
                  <div className="flex gap-4 items-center w-full">
                    <span className="text-poppins-small text-tertiary-500">
                      Nom du restaurant : {request.requester.name}
                    </span>
                  </div>
                  <div className="w-full">
                    <span className="text-poppins-small text-tertiary-500">
                      Label demandé : {request.label}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-poppins-small text-tertiary-500">
                Informations de l'établissement non disponibles
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRequests;
