import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Créer un groupe de labels
export const createLabelGroup = async (labelGroupData) => {
  try {
    const response = await axios.post(
      `${API_URL}/label-groups`,
      labelGroupData
    );
    return response.data;
  } catch (error) {
    console.error("Error creating label group:", error);
    throw error;
  }
};

// Obtenir un groupe de labels par ID
export const getLabelGroupById = async (labelGroupId) => {
  try {
    const response = await axios.get(`${API_URL}/label-groups/${labelGroupId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting label group by ID:", error);
    throw error;
  }
};

// Mettre à jour un groupe de labels par ID
export const updateLabelGroupById = async (labelGroupId, labelGroupData) => {
  try {
    const response = await axios.patch(
      `${API_URL}/label-groups/${labelGroupId}`,
      labelGroupData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating label group by ID:", error);
    throw error;
  }
};

// Obtenir tous les groupes de labels
export const getAllLabelGroups = async () => {
  try {
    const response = await axios.get(`${API_URL}/label-groups`);
    return response.data.labelGroups;
  } catch (error) {
    console.error("Error getting all label groups:", error);
    throw error;
  }
};

// Supprimer un groupe de labels par ID
export const deleteLabelGroupById = async (labelGroupId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/label-groups/${labelGroupId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting label group by ID:", error);
    throw error;
  }
};


export const getGroupsOfAuditor = async (auditorId) => {
  try {
    const response = await axios.get(`${API_URL}/label-groups/auditor/${auditorId}`);
    return response.data.labelGroups;
  } catch (error) {
    console.error("Error getting label group by auditor:", error);
    throw error;
  }
}
