import React, { useState, useEffect } from "react";
import useFiles from "../../../../hooks/useFiles/useFiles";
import { useParams } from "react-router-dom";
import WhatIsTheAction from "../WhatIsTheAction";
import useEstablishment from "../../../../hooks/establishment/useEstablishment";
import FilterFiles from "./FilterFiles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { getCategorie } from "../../../../utils/Categories";

const ShowFiles = () => {
  const { establishmentId } = useParams();
  const { files, handleDownloadFile, handleViewFile } = useFiles({
    establishmentId: establishmentId,
  });

  const { establishment } = useEstablishment({
    establishmentId: establishmentId,
  });

  const [filteredFiles, setFilteredFiles] = useState([]);

  useEffect(() => {
    setFilteredFiles(files);
  }, [files]);

  const handleFilter = (updatedFiles) => {
    setFilteredFiles(updatedFiles);
  };

  // Grouper les fichiers par catégorie
  const groupFilesByCategory = (files) => {
    return files.reduce((acc, file) => {
      const category = file.category || "Sans catégorie";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(file);
      return acc;
    }, {});
  };

  const groupedFiles = groupFilesByCategory(filteredFiles);

  return (
    <>
      <WhatIsTheAction actionIs={"showFiles"} name={establishment?.name} />
      <div className="container mx-auto flex justify-center items-center flex-col bg-white rounded-20 shadow-xl pl-14 pr-14 pb-14 ">
        <div>
          <h1 className="text-roca-h4-5 !text-secondary-600 mt-5">
            Consulter les documents
          </h1>
        </div>
        <div className="w-full mt-5 mb-5">
          <FilterFiles files={files} onFilter={handleFilter} />
        </div>
        <div className="w-full flex gap-5 flex-col">
          {Object.entries(groupedFiles).map(([category, files]) => (
            <div key={category} className="w-full">
              <h2 className="text-lg font-bold text-secondary-600 mb-4">
                {getCategorie(category)?.name || "Autre"}
              </h2>
              {files.map((file) => (
                <div
                  className="bg-white h-14 shadow-md p-5 border border-primary-600 rounded-20 flex items-center justify-between mb-3"
                  key={file._id}
                >
                  <div className="flex flex-row items-center gap-5 text-poppins-small">
                    <p>{file.filename}</p>
                    <p className="italic">{(file.size / 1024).toFixed(2)} KB</p>
                    <p className="italic">
                      {new Date(file.createdAt).toLocaleDateString("fr-FR", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                  </div>

                  <div className="flex gap-3 ml-auto">
                    <button
                      onClick={() => handleDownloadFile(file._id)}
                      className="text-primary-600 rounded-20 p-2"
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                    <button
                      onClick={() => handleViewFile(file._id)}
                      className="text-primary-600 rounded-20 p-2"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ShowFiles;
