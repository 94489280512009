import React from "react";
import SearchComponent from "./Search/SearchComponent";
import WhyLabelAdresse from "./WhyLabelAdresse";
import SectionMap from "./SectionMap";
import Mission from "./Mission";
import Teams from "./Teams";
import DiscoverApp from "./DiscoverApp";
import ContactMail from "./ContactMail";

const HomePage = () => {
  return (
    <div className="flex flex-col gap-16">
      {/* Hero Section */}
      <div className="min-h-screen flex justify-center bg-hero bg-cover bg-center">
        <SearchComponent />
      </div>

      {/* Sections avec un bon espacement */}
      <section className="container mx-auto w-full min-h-screen py-20">
        <WhyLabelAdresse />
      </section>

      <div className="w-full bg-primary-600 relative overflow-hidden min-h-[110vh]">
        <div className="container mx-auto min-h-[110vh] py-20 !bg-primary-600 relative">
          <SectionMap />
        </div>
      </div>

      <section className="container mx-auto w-full min-h-screen py-20">
        <Mission />
      </section>

      <section className="container mx-auto w-full min-h-screen py-20">
        <Teams />
      </section>

      {/* <section className="container mx-auto w-full min-h-screen py-20">
        <DiscoverApp />
      </section> */}

      <section className="container mx-auto w-full min-h-screen py-20">
        <ContactMail />
      </section>
    </div>
  );
};

export default HomePage;
