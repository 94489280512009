import React, { useState } from "react";
import useReviews from "../../../../hooks/Reviews/useReviews";
import { useParams } from "react-router-dom";
import WhatIsTheAction from "../WhatIsTheAction";
import RatingSummary from "../../../SearchResult/Establishment/RatingSummary";
import AnswerModal from "./AnswerModal";
import useEstablishment from "../../../../hooks/establishment/useEstablishment";
import { getRatingInStars } from "../../../../utils/Establishment";
import Button from "../../../elements/Button/Button";

const ShowReviewsEstablishement = () => {
  const { establishmentId } = useParams();

  const { establishment } = useEstablishment({ establishmentId });
  const {
    reviews,
    refreshReviews,
    deleteAnswerOfReview,
    handleUpdateAnswer,
    handleUpadateReview,
  } = useReviews({ establishmentId });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isEditingAnswer, setIsEditingAnswer] = useState(false);
  const [existingAnswer, setExistingAnswer] = useState("");

  const openModal = (reviewId, isEdit = false, existingComment = "") => {
    setSelectedReview(reviewId);
    setIsEditingAnswer(isEdit);
    setExistingAnswer(existingComment); // Remplit le commentaire existant
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedReview(null);
    setIsEditingAnswer(false);
    setExistingAnswer("");
    setIsModalOpen(false);
  };

  const handleAnswerSubmit = async (comment) => {
    try {
      if (isEditingAnswer) {
        await handleUpdateAnswer(selectedReview, comment); // Mise à jour d'une réponse existante
      } else {
        await handleUpadateReview(selectedReview, comment); // Ajout d'une nouvelle réponse
      }
      closeModal();
      refreshReviews();
    } catch (error) {
      console.error("Erreur lors de la gestion de la réponse :", error);
    }
  };

  return (
    <>
      <WhatIsTheAction
        actionIs={"showReviewsEstablishement"}
        name={establishment?.name}
      />

      <div className="container mx-auto flex flex-col bg-white justify-center rounded-20 pl-14 pr-14 pb-10 shadow-lg">
        <h1 className="text-roca-h4-5 !text-secondary-600 text-center mt-5 mb-5">
          Avis des clients
        </h1>
        <div className="w-full items-center flex justify-center">
          <RatingSummary reviews={reviews} />
        </div>

        <div className="flex flex-col gap-6 mt-6">
          {reviews?.map((review, index) => (
            <div
              key={index}
              className="w-full bg-gray-100 p-4 border border-gray-300 rounded-lg flex flex-col gap-4"
            >
              <div className="flex justify-between">
                <div>
                  <h3 className="font-bold text-lg text-gray-800">
                    {review.user?.firstname} {review.user?.lastname}
                  </h3>
                  <p className="text-gray-600 mt-2">{review.comment}</p>
                </div>
                <div>
                  <p className="font-bold text-primary-600 flex-end">
                    {getRatingInStars(review.rating)}
                  </p>
                </div>
              </div>

              {review.answer && review.answer.length > 0 && (
                <div className="mt-4 p-3 bg-white border border-gray-200 rounded-lg">
                  <h2 className="text-sm font-bold text-gray-700 mb-2">
                    Réponses :
                  </h2>
                  <ul className="space-y-4">
                    {review.answer.map((ans, ansIndex) => (
                      <li
                        key={ansIndex}
                        className="text-gray-600 text-sm flex flex-col gap-2 border-b border-gray-300 pb-4"
                      >
                        <p>
                          <span className="font-semibold">Commentaire :</span>{" "}
                          {ans.comment}
                        </p>
                        <p className="text-xs text-gray-400">
                          <span className="font-semibold">Date :</span>{" "}
                          {new Date(ans.date).toLocaleDateString("fr-FR", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </p>
                        <div className="flex gap-3 justify-end">
                          <button
                            onClick={() =>
                              openModal(review._id, true, ans.comment)
                            }
                            className="bg-green-500 px-4 py-2 rounded-lg text-white hover:bg-green-600"
                          >
                            Modifier
                          </button>
                          <button
                            onClick={() => deleteAnswerOfReview(review._id)}
                            className="bg-red-500 px-4 py-2 rounded-lg text-white hover:bg-red-600"
                          >
                            Supprimer
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="w-full flex justify-center items-center flex-col gap-5">
                <Button
                  colorType="primary"
                  onClick={() => openModal(review._id)}
                >
                  Répondre
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <AnswerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleAnswerSubmit}
        existingComment={existingAnswer} // Passe le commentaire existant si modification
      />
    </>
  );
};

export default ShowReviewsEstablishement;
