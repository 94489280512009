import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleTabChange = (tabKey) => {
    if (activeTab !== tabKey) {
      setIsAnimating(true);
      setTimeout(() => {
        setActiveTab(tabKey);
        setIsAnimating(false);
      }, 200); // Durée de l'animation de sortie
    }
  };

  return (
    <div>
      <div className="flex justify-start mb-6">
        {Object.keys(tabs).map((tabKey) => (
          <button
            key={tabKey}
            className={`px-4 py-2 mx-2 font-semibold  ${
              activeTab === tabKey
                ? "border-b-2 border-primary-600 text-black"
                : "text-gray-500"
            }`}
            onClick={() => handleTabChange(tabKey)}
          >
            {tabKey}
          </button>
        ))}
      </div>

      <div
        className={`transition-opacity duration-100 ${
          isAnimating ? "opacity-0" : "opacity-100"
        }`}
      >
        {tabs[activeTab]}
      </div>
    </div>
  );
};

export default Tabs;
