import { useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";

const FooterWrapper = ({ children }) => {
  const location = useLocation();
  const hideNavBarRoutes = ["/login", "/register" , "/register/pro", "/register/part"];

  return (
    <div className="bottom-0">
      {/* Only render NavBar if the current path is not /login or /register */}
      {!hideNavBarRoutes.includes(location.pathname) && <Footer />}
      {children}
    </div>
  );
};

export default FooterWrapper;
