import React, { useEffect, useState } from "react";
import { getUserById } from "../../../../services/user.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faEdit,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const InformationRequest = ({ labelRequest }) => {
  const [auditor, setAuditor] = useState(null);

  useEffect(() => {
    const fetchAuditor = async () => {
      try {
        const auditorData = await getUserById(labelRequest.auditor);
        setAuditor(auditorData);
      } catch (error) {
        console.error("Error fetching auditor:", error);
      }
    };

    if (labelRequest.auditor) {
      fetchAuditor();
    }
  }, [labelRequest.auditor]);

  if (!auditor) {
    return null;
  }

  return (
    <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
      {/* En-tête */}
      <div className="mb-6">
        <h2 className="text-xl font-bold text-gray-800">
          Demande du label {labelRequest.label.toUpperCase()}
        </h2>
        <p className="text-gray-500 mt-1">Pour votre établissement</p>
      </div>

      {/* Informations sur l'auditeur */}
      <div className="bg-blue-50 rounded-lg p-4 mb-6">
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={faUser} className="text-blue-600 mr-2" />
          <h3 className="text-blue-600 font-medium">Auditeur en charge</h3>
        </div>
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center text-white font-medium">
            {auditor.firstname[0]}
            {auditor.lastname[0]}
          </div>
          <div>
            <p className="font-semibold text-gray-800">
              {auditor.firstname} {auditor.lastname}
            </p>
            <p className="text-gray-600 text-sm">{auditor.email}</p>
          </div>
        </div>
      </div>

      {/* Dates */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-50 rounded-lg p-4">
          <div className="flex items-center mb-2">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="text-gray-600 mr-2"
            />
            <h3 className="text-gray-800 font-medium">Date de la demande</h3>
          </div>
          <p className="text-gray-600">
            {new Date(labelRequest.created_at).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>

        <div className="bg-gray-50 rounded-lg p-4">
          <div className="flex items-center mb-2">
            <FontAwesomeIcon icon={faEdit} className="text-gray-600 mr-2" />
            <h3 className="text-gray-800 font-medium">Dernière modification</h3>
          </div>
          <p className="text-gray-600">
            {new Date(labelRequest.updated_at).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationRequest;
