import React, { useState } from "react";
import AddDocuments from "../AddDocuments";
import AddEtiquette from "../../AddEtiquette";
import { createGreenFood } from "../../../../../../services/Form/Form.services";
import { useNavigate, useParams } from "react-router-dom";
import FieldSet from "../FieldSet";
import Button from "../../../../../elements/Button/Button";
import Swal from "sweetalert2";

const FormGreenFood = () => {
  const [localProductsInvoices, setLocalProductsInvoices] = useState([]);
  const [selectiveSorting, setSelectiveSorting] = useState(false);
  const [ecoCleaningInvoices, setEcoCleaningInvoices] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [organicWasteExamples, setOrganicWasteExamples] = useState([]);
  const [freshProductsInvoices, setFreshProductsInvoices] = useState([]);
  const [waterEnergyInvoices, setWaterEnergyInvoices] = useState([]);
  const [doggyBag, setDoggyBag] = useState(false);
  const [vegetarianMenuFiles, setVegetarianMenuFiles] = useState([]);
  const [renewableEnergyDocs, setRenewableEnergyDocs] = useState([]);

  const navigate = useNavigate();

  const { establishmentId } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      localProductsInvoices,
      selectiveSorting,
      ecoCleaningInvoices,
      associations,
      organicWasteExamples,
      freshProductsInvoices,
      waterEnergyInvoices,
      doggyBag,
      vegetarianMenuFiles,
      renewableEnergyDocs,
      files: [
        ...localProductsInvoices,
        ...ecoCleaningInvoices,
        ...freshProductsInvoices,
        ...waterEnergyInvoices,
        ...vegetarianMenuFiles,
        ...renewableEnergyDocs,
      ],
    };

    try {
      await createGreenFood(formData, establishmentId);
      Swal.fire({
        icon: "success",
        title: "Demande envoyée",
        text: "Votre demande a bien été envoyée.",
      }).then(() => {
        navigate("/my-establishements");
      });
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire", error);
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur est survenue lors de la soumission du formulaire.",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Critère 1 */}

      <FieldSet
        legend="1. Produits locaux / Bio"
        description="Utilisation de produits locaux et/ou bio pour la conception des plats :"
        content={"document"}
        FieldDocumentData={[localProductsInvoices, setLocalProductsInvoices]}
        FieldDocumentText={{
          title: "Ajouter des factures d'achat de produits locaux ou bio",
          category: "green-food-invoices-locally",
        }}
      />

      {/* Critère 2 */}

      <FieldSet
        legend="2. Tri sélectif"
        description="Mise en place du tri sélectif dans votre établissement :"
        content={"checkbox"}
        checkBoxData={[selectiveSorting, setSelectiveSorting]}
      />

      {/* Critère 3 */}

      <FieldSet
        legend="3. Produits d'entretien"
        description="Utilisation de produits d'entretien naturel et/ou écologique (minimum 50%) :"
        content={"document"}
        FieldDocumentData={[ecoCleaningInvoices, setEcoCleaningInvoices]}
        FieldDocumentText={{
          title: "Ajouter des factures de produits d'entretien",
          category: "green-food-invoices-cleaning",
        }}
      />

      <FieldSet
        legend="4. Revalorisation d'invendus"
        description="Revaloriser ses invendus par le don aux associations ou la revente à moindre coût :"
        content={"etiquette"}
        EtiquetteData={[associations, setAssociations]}
        EtiqueteFields={[
          { name: "name", label: "Nom", type: "text", placeholder: "Nom" },
          {
            name: "example",
            label: "Exemple",
            type: "text",
            placeholder: "Exemple de revente ou action",
          },
        ]}
        EtiquetteText={{
          title: "Ajouter une association ou un exemple de revente",
        }}
      />

      <FieldSet
        legend="5. Revalorisation des déchets organiques"
        description="Compostage, lombricompostage, etc. :"
        content={"etiquette"}
        EtiquetteData={[organicWasteExamples, setOrganicWasteExamples]}
        EtiqueteFields={[
          {
            name: "example",
            label: "Exemple",
            type: "text",
            placeholder: "Exemple",
          },
        ]}
        EtiquetteText={{
          title: "Ajouter un exemple mis en place",
          category: "green-food-organic-waste",
        }}
      />

      <FieldSet
        legend="6. Flux tendus"
        description="Utilisation de produits frais par une gestion en flux tendus :"
        content={"document"}
        FieldDocumentData={[freshProductsInvoices, setFreshProductsInvoices]}
        FieldDocumentText={{
          title: "Ajouter des factures d'achat de produits frais",
          category: "green-food-invoices-fresh",
        }}
      />

      <FieldSet
        legend="7. Réduction des dépenses d'eau et d'énergies"
        description="Fournir des factures pour prouver les actions de réduction :"
        content={"document"}
        FieldDocumentData={[waterEnergyInvoices, setWaterEnergyInvoices]}
        FieldDocumentText={{
          title: "Ajouter des factures d'eau ou d'énergie",
          category: "green-food-invoices-water-energy",
        }}
      />

      <FieldSet
        legend="8. Doggy bag"
        description="Proposer un « doggy bag » pour le reste des repas :"
        content={"checkbox"}
        checkBoxData={[doggyBag, setDoggyBag]}
      />

      <FieldSet
        legend="9. Menu végétarien"
        description="Fournir au moins un menu végétarien :"
        content={"document"}
        FieldDocumentData={[vegetarianMenuFiles, setVegetarianMenuFiles]}
        FieldDocumentText={{
          title: "Ajouter le fichier du menu végétarien",
          category: "green-food-menu-vegetarian",
        }}
      />

      <FieldSet
        legend="10. Énergies renouvelables"
        description="S’approvisionner en énergie renouvelable (factures ou documents) :"
        content={"document"}
        FieldDocumentData={[renewableEnergyDocs, setRenewableEnergyDocs]}
        FieldDocumentText={{
          title:
            "Ajouter une facture ou document prouvant l'utilisation d'énergie renouvelable",
          category: "green-food-invoices-renewable-energy",
        }}
      />

      <div className="flex justify-end">
        <Button type="submit" colorType="primary">
          Soumettre
        </Button>
      </div>
    </form>
  );
};

export default FormGreenFood;
