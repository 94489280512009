export const DIETS = [
  "Végétarien",
  "Vegan",
  "Sans gluten",
  "Keto",
  "Pescétarien",
  "Flexitarien",
  "Paleo",
  "Halal",
  "Casher",
  "Diabétique",
  "Faible en glucides",
  "Hypocalorique",
  "Protéiné",
  "Sans lactose",
  "Crudivore",
  "Alimentation alcaline",
  "Sans sucre",
  "Omnivore",
  "Ayurvédique",
  "Macronutriments équilibrés",
  "Whole30",
  "Régime DASH",
  "Régime méditerranéen",
  "Sans soja",
  "Carnivore",
  "Frugivore",
  "Anti-inflammatoire",
  "Régime FODMAP faible",
];

export const CUISINES = [
  "Italienne",
  "Japonaise",
  "Chinoise",
  "Française",
  "Mexicaine",
  "Indienne",
  "Thaïlandaise",
  "Espagnole",
  "Grecque",
  "Méditerranéenne",
  "Coréenne",
  "Vietnamienne",
  "Portugaise",
  "Turque",
  "Libanaise",
  "Marocaine",
  "Argentine",
  "Brésilienne",
  "Russe",
  "Allemande",
  "Américaine",
  "Canadienne",
  "Cajun",
  "Caraïbéenne",
  "Africaines (Ghana, Éthiopienne, Sud-africaine)",
  "Anglaise",
  "Australienne",
  "Polonaise",
  "Hongroise",
  "Israélienne",
  "Suédoise",
  "Norvégienne",
  "Finlandaise",
  "Danoise",
  "Néerlandaise",
  "Indonésienne",
  "Malaisienne",
  "Singapourienne",
  "Filipino",
  "Persane (Iranienne)",
  "Pakistanaise",
  "Bengali",
  "Sri Lankaise",
  "Hawaïenne",
  "Centrale asiatique",
  "Créole",
];
