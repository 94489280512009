import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import Button from "../elements/Button/Button";
import { getImageOfTheLabelAndName } from "../../utils/Labels";

// Correction de l'icône par défaut de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
});

const MapFocus = ({ selectedEstablishment }) => {
  const map = useMap();
  useEffect(() => {
    if (selectedEstablishment) {
      const { lon, lat } = selectedEstablishment.coordinates;
      map.setView([lat, lon], 15, { animate: true });
    }
  }, [selectedEstablishment, map]);
  return null;
};

const MapEvents = ({ onSearchInArea }) => {
  const [isMapMoved, setIsMapMoved] = useState(false);
  const map = useMapEvent("moveend", () => {
    setIsMapMoved(true);
  });

  const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371;
    const lat1 = toRad(coords1.lat);
    const lon1 = toRad(coords1.lng);
    const lat2 = toRad(coords2.lat);
    const lon2 = toRad(coords2.lng);
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const handleSearchInArea = () => {
    const center = map.getCenter();
    const bounds = map.getBounds();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const distance = haversineDistance(northEast, southWest);
    const lat = center.lat;
    const lon = center.lng;
    onSearchInArea(lat, lon, distance * 1000);
  };

  return (
    isMapMoved && (
      <div
        className="absolute mt-5 w-full flex justify-center items-center"
        style={{ zIndex: 10000 }}
      >
        <Button onClick={handleSearchInArea}>Chercher dans cette zone</Button>
      </div>
    )
  );
};

const MapResults = ({
  establishments,
  selectedEstablishment,
  onSelectEstablishment,
  onSearchInArea,
}) => {
  const defaultPosition = [48.8566, 2.3522];
  const customIcon = L.divIcon({
    html: `<div style="
      background-color: #FF5733; 
      width: 32px; 
      height: 32px; 
      border-radius: 50%; 
      border: 4px solid white; 
      display: flex; 
      justify-content: center; 
      align-items: center; 
      color: white;
    "></div>`,
    className: "",
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });

  return (
    <div className="w-full flex justify-center md:w-[500px] h-64 md:h-screen sticky top-32 overflow-y-hidden">
      <MapContainer
        center={defaultPosition}
        zoom={13}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MapFocus selectedEstablishment={selectedEstablishment} />
        <MapEvents onSearchInArea={onSearchInArea} />
        {establishments?.map((establishment, index) => (
          <Marker
            key={index}
            position={[
              establishment.coordinates.lat,
              establishment.coordinates.lon,
            ]}
            icon={customIcon}
            eventHandlers={{
              click: () => {
                onSelectEstablishment(establishment);
              },
            }}
          >
            <Popup>
              <div>
                <h3 className="text-roca-paragraph font-bold text-gray-800 mb-2">
                  {establishment.name}
                </h3>
                <p className="!text-gray-600 text-poppins-extra-small mb-4">
                  {establishment.description}
                </p>
                <div className="lable-scrool flex gap-4 overflow-x-auto mb-4">
                  {establishment?.labels.map((label, index) => {
                    const labelInfo = getImageOfTheLabelAndName(label);
                    return labelInfo ? (
                      <div
                        key={index}
                        className="flex flex-col items-center text-center space-y-1 min-w-[80px]"
                      >
                        <img
                          src={labelInfo.image}
                          alt={labelInfo.name}
                          className="w-12 h-12 rounded-full"
                        />
                        <span className="text-poppins-extra-small font-medium text-gray-700">
                          {labelInfo.name}
                        </span>
                      </div>
                    ) : (
                      <span
                        key={index}
                        className="text-xs bg-gray-200 rounded-full px-3 py-1 min-w-[80px] text-center"
                      >
                        {label}
                      </span>
                    );
                  })}
                </div>
                <div className="mb-4">
                  <p className="text-sm text-gray-700">
                    <strong>Adresse:</strong> {establishment.address.street},{" "}
                    {establishment.address.city}
                  </p>
                </div>
                <div className="mb-4">
                  <p className="text-sm text-gray-700">
                    <strong>Téléphone:</strong> {establishment.phoneNumber}
                  </p>
                </div>
                <div className="mt-4 text-center">
                  <Button link={`/establishment/${establishment._id}`}>
                    En savoir plus
                  </Button>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapResults;
