import React, { useState } from "react";
import Input from "../../../../elements/Input/Input";
import Button from "../../../../elements/Button/Button";
import FileUploader from "../../../../elements/Input/FileUploader";
import Swal from "sweetalert2";
import { P } from "storybook/internal/components";

const AddDocuments = ({ onAdd, title, category = "" }) => {
  const [formData, setFormData] = useState({
    title: "",
    category: category || "",
  });
  const [file, setFile] = useState(null);
  const [isWarnig, setIsWarning] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target) setIsWarning(false);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (formData.title.trim() && formData.category.trim() && file) {
      onAdd({ ...formData, file });
      setFormData({ title: "", category: category || "" });
      setFile(null);
      const fileInput = document.querySelector(
        `input[data-field="${category}"]`
      );
      if (fileInput) {
        fileInput.value = "";
      }
    } else {
      setIsWarning(true);
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Veuillez remplir tous les champs et ajouter un fichier",
      });
    }
  };

  return (
    <div className="p-4 border rounded-20 w-full">
      <h3 className="text-poppins-paragraph">{title}</h3>

      <div className="mb-4 mt-4">
        <Input
          type="text"
          label="Titre du document"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Entrez le titre"
        />
        {isWarnig && (
          <p>
            <span className="text-red-500">Veuillez remplir ce champ</span>
          </p>
        )}
      </div>

      <FileUploader file={file} setFile={setFile} fieldIdentifier={category} />

      <div className="flex justify-end">
        <Button
          onClick={handleAdd}
          colorType="primary"
          AddtionnalClasses={"mt-4"}
        >
          Ajouter
        </Button>
      </div>
    </div>
  );
};

export default AddDocuments;
