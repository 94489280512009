import React from "react";

const Status = ({ status, label }) => {
  return (
    <div className="">
      <div className="flex items-center gap-2">
        {label && <span className="text-gray-600 text-sm">Status de la demande :</span>}
        <span
          className={`px-4 py-1.5 rounded-full text-sm font-medium ${
            status === "pending"
              ? "bg-yellow-100 text-yellow-800"
              : status === "in_review"
              ? "bg-blue-100 text-blue-800"
              : status === "approved"
              ? "bg-green-100 text-green-800"
              : status === "rejected"
              ? "bg-red-100 text-red-800"
              : status === "needs_more_info"
              ? "bg-orange-100 text-orange-800"
              : "bg-gray-100 text-gray-800"
          }`}
        >
          {status === "pending"
            ? "En attente"
            : status === "in_review"
            ? "En cours de revue"
            : status === "approved"
            ? "Approuvé"
            : status === "rejected"
            ? "Refusé"
            : status === "needs_more_info"
            ? "Informations supplémentaires requises"
            : status}
        </span>
      </div>
    </div>
  );
};

export default Status;
