import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className=" bg-senary-600 py-10 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-20 p-8 shadow-md">
        {/* Titre principal */}
        <h1 className="text-roca-h3 font-bold mb-2">
          Politique de Confidentialité
        </h1>
        <p className="text-poppins-small text-gray-600 mb-6">
          Dernière mise à jour&nbsp;: 01/03/2025
        </p>

        {/* 1. Responsable du traitement */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          1. Responsable du traitement
        </h2>
        <p className="text-poppins-paragraph mb-4">
          <strong>Nom de l’organisation&nbsp;:</strong> LabelAdresse
          <br />
          <strong>Contact&nbsp;:</strong>{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
          <br />
          <strong>Délégué à la Protection des Données (DPO)</strong> ou référent
          RGPD&nbsp;:{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
        </p>

        {/* 2. Données collectées */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          2. Données collectées
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous collectons les données personnelles suivantes&nbsp;:
        </p>
        <ul className="list-disc list-inside mb-4 text-poppins-paragraph">
          <li>
            Informations d’identification&nbsp;: nom, adresse e-mail, numéro de
            téléphone.
          </li>
          <li>
            Documents justificatifs&nbsp;: factures, rapports ou autres pièces
            fournies par les restaurateurs lors de leur demande de
            labellisation.
          </li>
        </ul>
        <p className="text-poppins-paragraph mb-4">
          Nous utilisons également des cookies afin de faciliter la navigation
          sur le site et de réaliser des statistiques d’audience.
        </p>

        {/* 3. Finalités du traitement */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          3. Finalités du traitement
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous utilisons les données personnelles collectées pour&nbsp;:
        </p>
        <ul className="list-disc list-inside mb-4 text-poppins-paragraph">
          <li>
            Valider les demandes de labellisation (vérification des informations
            et documents transmis).
          </li>
          <li>
            Gérer la relation utilisateur (répondre aux questions, assurer le
            suivi des demandes).
          </li>
          <li>
            Assurer le bon fonctionnement du site (cookies indispensables à la
            navigation et à la mesure d’audience).
          </li>
        </ul>

        {/* 4. Base légale */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">4. Base légale</h2>
        <p className="text-poppins-paragraph mb-4">
          Nous traitons vos données sur la base de l’exécution d’un contrat
          (instruire vos demandes de labellisation) et, pour les cookies non
          essentiels, sur la base de votre consentement.
        </p>

        {/* 5. Partage des données */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          5. Partage des données
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous ne partageons pas vos données personnelles avec des tiers
          extérieurs. Les données sont uniquement traitées en interne, par notre
          équipe, dans la limite de leurs attributions.
        </p>

        {/* 6. Durée de conservation */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          6. Durée de conservation
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Les données collectées sont conservées pendant 5 ans à compter de la
          fin de la relation ou de la dernière interaction, puis supprimées ou
          anonymisées.
        </p>

        {/* 7. Droits des utilisateurs */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          7. Droits des utilisateurs
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Conformément au Règlement (UE) 2016/679 (RGPD), vous disposez des
          droits suivants&nbsp;:
        </p>
        <ul className="list-disc list-inside mb-4 text-poppins-paragraph">
          <li>
            <strong>Droit d’accès&nbsp;:</strong> obtenir la confirmation que
            vos données sont ou ne sont pas traitées et, le cas échéant, y
            accéder.
          </li>
          <li>
            <strong>Droit de rectification&nbsp;:</strong> demander la
            modification de vos données lorsqu’elles sont inexactes ou
            incomplètes.
          </li>
          <li>
            <strong>Droit à l’effacement&nbsp;:</strong> demander la suppression
            de vos données, dans la limite des obligations légales.
          </li>
          <li>
            <strong>Droit d’opposition&nbsp;:</strong> vous opposer au
            traitement de vos données pour des motifs légitimes.
          </li>
          <li>
            <strong>Droit à la limitation&nbsp;:</strong> demander la suspension
            temporaire du traitement de certaines de vos données.
          </li>
          <li>
            <strong>Droit à la portabilité&nbsp;:</strong> recevoir vos données
            dans un format structuré, couramment utilisé et lisible par machine.
          </li>
        </ul>
        <p className="text-poppins-paragraph mb-4">
          Pour exercer l’un de ces droits, veuillez nous contacter à
          l’adresse&nbsp;:{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
          .
          <br />
          Vous avez également la possibilité d’introduire une réclamation auprès
          de l’autorité de contrôle de votre pays (ex. : la CNIL en France).
        </p>

        {/* 8. Transfert hors de l’UE */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          8. Transfert hors de l’Union européenne
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous ne transférons pas vos données en dehors de l’Espace économique
          européen (EEE).
        </p>

        {/* 9. Sécurité */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">9. Sécurité</h2>
        <p className="text-poppins-paragraph mb-4">
          Nous mettons en place des mesures de sécurité techniques et
          organisationnelles (connexion HTTPS, contrôle d’accès, etc.) pour
          protéger vos données contre tout accès non autorisé, divulgation,
          altération ou destruction.
        </p>

        {/* 10. Modification de la politique */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          10. Modification de la politique de confidentialité
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous pouvons être amenés à modifier la présente Politique de
          Confidentialité pour refléter les évolutions de nos pratiques ou pour
          nous conformer à la réglementation. Les modifications seront publiées
          sur cette page avec une date de mise à jour.
        </p>

        {/* 11. Contact */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">11. Nous contacter</h2>
        <p className="text-poppins-paragraph mb-4">
          Pour toute question concernant cette Politique de Confidentialité ou
          le traitement de vos données personnelles, vous pouvez nous contacter
          à l’adresse&nbsp;:{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
