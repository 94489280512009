import React from "react";
import Button from "../../elements/Button/Button";
import { useNavigate } from "react-router-dom";
import imageHands from "../../../assets/images/Logo/Icône.png";

const CardEstablishment = ({
  establishment,
  isSelect,
  selectedLabel,
  disabled,
  disabledMessage,
}) => {
  const navigate = useNavigate();
  const handleNavigateToDashBoard = () => {
    if (isSelect) {
      navigate(`/dashboard/${establishment._id}/action/request-label`, {
        state: { selectedLabel },
      });
    } else {
      navigate(`/dashboard/${establishment._id}`);
    }
  };

  return (
    <div className="relative">
      <div
        className={`flex flex-row justify-between items-center gap-5 p-5 bg-white w-full rounded-16 shadow-lg ${
          disabled
            ? "filter blur-sm pointer-events-none border border-red-500"
            : ""
        }`}
      >
        <div className="flex flex-row gap-64 items-center">
          <div>
            <img src={imageHands} className="w-24" alt="logo labeladresse" />
          </div>
          <div className="text-poppins-paragraph">{establishment.name}</div>
          <div className="flex flex-col items-center justify-center text-poppins-small">
            {establishment.price} €{" "}
            <span className="text-poppins-extra-small">Ticket moyen</span>
          </div>
        </div>

        <div>
          <Button
            disabled={disabled}
            colorType="secondary_border"
            onClick={handleNavigateToDashBoard}
          >
            VOIR LE RESTAURANT
          </Button>
        </div>
      </div>

      {disabled && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-red-500 text-white p-2 rounded-md">
            {disabledMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardEstablishment;
