import {
  faClock,
  faGlobe,
  faMapMarkerAlt,
  faPhone,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ImageCarrousel from "./ImageCarrousel";
import { getImageOfTheLabelAndName } from "../../../utils/Labels";
import { getImageOfTheInfo } from "../../../utils/Infos";

const HeroEstablisment = ({
  establishment,
  getStringAddress,
  openMapLink,
  openWebsite,
  openPhoneLink,
  getOpeningHours,
  showOpeningHoursModal,
}) => {
  return (
    <div className="container mx-auto px-4">
      <h2 className="text-roca-h6 pb-3">Adresse</h2>
      <div className="flex flex-col md:flex-row md:gap-16">
        {/* Colonne gauche : Informations et labels */}
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <div className="bg-white shadow-lg rounded-20 p-6 mb-6 border border-primary-600">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="text-primary-600"
                  />
                  <div>{getStringAddress()}</div>
                </div>
                <button onClick={openMapLink}>
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <FontAwesomeIcon
                    icon={faGlobe}
                    className="text-primary-600"
                  />
                  {establishment.website && (
                    <a href={establishment.website}>{establishment.website}</a>
                  )}
                </div>
                <button onClick={() => openWebsite(establishment.website)}>
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="text-primary-600"
                  />
                  <p>{establishment.phoneNumber}</p>
                </div>
                <button
                  onClick={() => openPhoneLink(establishment.phoneNumber)}
                >
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <FontAwesomeIcon
                    icon={faClock}
                    className="text-primary-600"
                  />
                  <p
                    className={`font-semibold ${
                      getOpeningHours() === "Ouvert"
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {getOpeningHours()}
                  </p>
                </div>
                <button onClick={showOpeningHoursModal}>
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </button>
              </div>
            </div>
          </div>
          {establishment.labels.length > 0 && (
            <>
              <h2 className="text-roca-h6 pb-3">Label(s)</h2>
              <div className="bg-quaternary-600 shadow-lg rounded-20 p-6 mb-6">
                <div className="flex flex-wrap gap-4">
                  {establishment.labels.map((label, index) => {
                    const labelInfo = getImageOfTheLabelAndName(label);
                    if (!labelInfo) return null;
                    return (
                      <div
                        key={index}
                        className="flex flex-col items-center mb-2"
                      >
                        <img
                          src={labelInfo.image}
                          alt={labelInfo.name}
                          className="w-16 h-16 rounded-full"
                        />
                        <span className="text-poppins-small rounded-full px-3 py-1">
                          {labelInfo.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}

          {establishment.infos.length > 0 && (
            <>
              <h2 className="text-roca-h6 pb-3">Services(s)</h2>
              <div className="bg-quaternary-600 shadow-lg rounded-20 p-6 mb-6">
                <div className="flex flex-wrap gap-4">
                  {establishment.infos.map((label, index) => {
                    const labelInfo = getImageOfTheInfo(label);
                    if (!labelInfo) return null;
                    return (
                      <div
                        key={index}
                        className="bg-white shadow-md rounded-10 p-1 m-2 flex items-center"
                      >
                        <FontAwesomeIcon
                          icon={labelInfo.icon}
                          className="text-primary-600 pr-3 pl-2"
                        />
                        <span className="text-poppins-small pr-2">
                          {labelInfo.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>

        {/* Colonne droite : Carrousel d'images */}
        <div className="w-full md:w-1/2">
          <ImageCarrousel images={establishment.photos} />
        </div>
      </div>
    </div>
  );
};

export default HeroEstablisment;
