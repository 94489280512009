import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../elements/Button/Button";

const CustomPlanCard = ({ title, priceRange, options, onClickHandler }) => {
  return (
    <div
      onClick={onClickHandler}
      className="w-full bg-gray-100 rounded-lg shadow-md p-4 sm:p-6 flex flex-col items-start text-black cursor-pointer "
    >
      <div className="w-full flex justify-center items-center mb-4">
        <h2 className="text-xl font-bold mb-2 text-center">{title}</h2>
      </div>
      <div className="w-full flex justify-center items-center mb-4">
        <p className="text-poppins-paragraph mb-1">{priceRange}</p>
      </div>
      <div className="w-full flex justify-center items-center mb-4">
        <p className="text-sm text-gray-600 mb-4">Paiement unique</p>
      </div>
      <div className="w-full flex justify-center items-center mb-5">
        <Button className="w-full sm:w-auto">Demander un devis</Button>
      </div>

      <div className="w-full flex justify-center items-center mb-4">
        <p className="text-poppins-paragraph">Inclus dans l'offre</p>
      </div>
      <ul className="space-y-2 w-full">
        {options.map((item, index) => (
          <li key={index} className="flex items-start">
            <FontAwesomeIcon
              icon={faCheck}
              className="text-gray-900 mr-2 mt-1 flex-shrink-0"
            />
            <span className="text-sm sm:text-base">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomPlanCard;
