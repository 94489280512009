import React from "react";
import SearchBar from "../../elements/Input/SearchBar";

const SearchComponent = () => {
  return (
    <div className="bg-primary-600 rounded-20 mx-4 sm:mx-auto mt-20 mb-20 w-full max-w-[844px] h-auto sm:h-[463px]">
      <div className="px-4 sm:px-[46px] pt-6 sm:pt-[93px] pb-6">
        <h1 className="text-roca-h4 !text-white text-left">
          Découvre ton propre Label adresse
        </h1>
        <h2 className="text-poppins-paragraph !text-white mt-7 text-left">
          Brasserie, saveurs asiatiques ou fait maison… Tout ce que vous aimez,
          bon pour l'environnement !
        </h2>
        <div className="mt-6">
          <SearchBar />
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
