import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import CardEstablishment from "./CardEstablishment";
import MapResults from "./MapResults";
import FilterDropdown from "../HomePage/Search/FilterDropdown";
import { searchEstablishments } from "../../services/establishements.services";

const Restaurants = () => {
  const location = useLocation();
  const currentCity = JSON.parse(localStorage.getItem("currentCity"));

  const [establishments, setEstablishments] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);

  // Références pour scroller vers l'établissement sélectionné
  const establishmentRefs = useRef([]);

  /**
   * Met à jour la liste des établissements selon les filtres
   */
  const handleFilteredEstablishments = (filteredResults) => {
    setEstablishments(filteredResults);
  };

  /**
   * Récupère la liste des établissements depuis l'API
   * @param {string} searchQuery - texte recherché
   * @param {number} lat - latitude
   * @param {number} lon - longitude
   * @param {number} distance - rayon de recherche (en mètres)
   */
  const fetchEstablishments = async (
    searchQuery,
    lat,
    lon,
    distance = 10000
  ) => {
    try {
      const results = await searchEstablishments(
        searchQuery,
        lat,
        lon,
        distance,
        {}
      );
      setEstablishments(results);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des établissements :",
        error
      );
    }
  };

  /**
   * Au montage, on vérifie si l'utilisateur arrive avec un state (ex: recherche déjà faite)
   * ou bien avec un paramètre `search` dans l'URL.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("search");

    const fetchData = async () => {
      if (location.state?.establishments) {
        // On récupère la liste directement depuis le state
        setEstablishments(location.state.establishments);
      } else if (searchQuery) {
        // Sinon on lance la recherche par API
        await fetchEstablishments(
          searchQuery,
          currentCity.lat,
          currentCity.lon
        );
      }
    };

    fetchData();
  }, [location.state, location.search, currentCity.lat, currentCity.lon]);

  /**
   * Recherche dans la zone affichée sur la carte (bounds).
   * Ici, on passe un lat/lon + distance pour recadrer la recherche.
   */
  const handleSearchInArea = (lat, lon, distance) => {
    fetchEstablishments("", lat, lon, distance);
  };

  /**
   * Quand l'utilisateur sélectionne un établissement sur la carte,
   * on met à jour l'état local et on scrolle vers la carte correspondante.
   */
  const handleSelectEstablishment = (establishment) => {
    setSelectedEstablishment(establishment);
  };

  /**
   * Au changement de l'établissement sélectionné, on scrolle
   * vers l'élément correspondant dans la liste (si trouvé).
   */
  useEffect(() => {
    if (selectedEstablishment) {
      const index = establishments.findIndex(
        (item) => item.name === selectedEstablishment.name
      );
      if (index !== -1 && establishmentRefs.current[index]) {
        establishmentRefs.current[index].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [selectedEstablishment, establishments]);

  return (
    <div className="container mx-auto px-4 py-6">
      {/* Zone de filtres */}
      <div className="mb-4">
        <FilterDropdown
          currentCity={currentCity}
          onResultsUpdate={handleFilteredEstablishments}
        />
      </div>

      {/* Titre */}
      <h2 className="text-roca-h4-5 mb-4">
        {establishments.length} restaurants trouvés
      </h2>

      {/* Disposition principale : carte + liste */}
      <div className="flex flex-col md:flex-row gap-4">
        {/* Carte : en premier sur mobile, à droite sur desktop */}
        <div className="order-1 md:order-2 md:w-1/3">
          <MapResults
            establishments={establishments}
            selectedEstablishment={selectedEstablishment}
            onSelectEstablishment={handleSelectEstablishment}
            onSearchInArea={handleSearchInArea}
          />
        </div>

        {/* Liste des établissements : en second sur mobile, à gauche sur desktop */}
        <div className="order-2 md:order-1 md:w-2/3 flex flex-col gap-4">
          {establishments.length > 0 ? (
            establishments.map((establishment, index) => (
              <div
                key={index}
                ref={(el) => (establishmentRefs.current[index] = el)}
                className={`cursor-pointer p-2 rounded transition-colors ${
                  selectedEstablishment?.name === establishment.name
                    ? "bg-gray-100 border-l-4 border-secondary-500"
                    : ""
                }`}
                onClick={() => handleSelectEstablishment(establishment)}
              >
                <CardEstablishment
                  establishmentId={establishment._id}
                  name={establishment.name}
                  labels={establishment.labels}
                  images={establishment.photos}
                  description={establishment.description}
                  price={establishment.price}
                  address={establishment.address}
                  rating={establishment.rating}
                />
              </div>
            ))
          ) : (
            <CardEstablishment isBlank />
          )}
        </div>
      </div>
    </div>
  );
};

export default Restaurants;
