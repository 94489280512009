import React, { useState, useEffect } from "react";
import {
  getAllCategoriesAndQuestions,
  createCategory,
  addQuestionInCategory,
  updateQuestion,
  deleteQuestion,
  updateCategory,
  deleteCategory,
} from "../../../services/faq.services";

import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/Provider/AuthProvider";
import WhatIsTheAction from "../../Owner/ActionsEstablishement/WhatIsTheAction";

const BackOfficeFaq = () => {
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newQuestions, setNewQuestions] = useState({});
  const [newAnswers, setNewAnswers] = useState({});
  const [expandedCategory, setExpandedCategory] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();
  const action = location.state?.action;

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  useEffect(() => {
    fetchCategoriesAndQuestions();
  }, []);

  const fetchCategoriesAndQuestions = async () => {
    try {
      const data = await getAllCategoriesAndQuestions();
      setCategories(data);
    } catch (error) {
      console.error("Failed to fetch categories and questions", error);
    }
  };

  const handleAddCategory = async () => {
    if (newCategory.trim() === "") return;

    try {
      await createCategory({ category: newCategory });
      setNewCategory("");
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to add category", error);
    }
  };

  const handleAddQuestion = async (categoryId) => {
    const question = newQuestions[categoryId] || "";
    const answer = newAnswers[categoryId] || "";

    if (question.trim() === "" || answer.trim() === "") return;

    try {
      await addQuestionInCategory(categoryId, {
        question,
        answer,
      });
      setNewQuestions({ ...newQuestions, [categoryId]: "" });
      setNewAnswers({ ...newAnswers, [categoryId]: "" });
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to add question", error);
    }
  };

  const handleQuestionInputChange = (categoryId, value) => {
    setNewQuestions({ ...newQuestions, [categoryId]: value });
  };

  const handleAnswerInputChange = (categoryId, value) => {
    setNewAnswers({ ...newAnswers, [categoryId]: value });
  };

  const handleUpdateQuestion = async (
    categoryId,
    questionId,
    question,
    answer
  ) => {
    try {
      await updateQuestion(questionId, { question, answer });
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to update question", error);
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      await deleteQuestion(questionId);
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to delete question", error);
    }
  };

  const handleUpdateCategory = async (categoryId, category) => {
    try {
      await updateCategory(categoryId, { category });
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to update category", error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to delete category", error);
    }
  };

  return (
    <div className="min-h-screen bg-background-600 p-8">
      <div>
        <WhatIsTheAction actionIs={action} />
      </div>
      {/* Header Section */}
      <div className="mb-10">
        <h1 className="text-poppins-h4 text-primary-600 mb-2">
          Gestion de la FAQ
        </h1>
        <p className="text-poppins-small text-tertiary-500">
          Gérez les catégories et questions de votre FAQ
        </p>
      </div>

      {/* Add Category Section */}
      <div className="bg-white rounded-15 shadow-sm p-6 mb-8">
        <div className="flex gap-4">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Nom de la nouvelle catégorie"
            className="flex-1 px-4 py-2 border border-tertiary-300 rounded-10 
                     text-poppins-small text-primary-600 placeholder-tertiary-400
                     focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400"
          />
          <button
            onClick={handleAddCategory}
            className="px-6 py-2 bg-secondary-600 text-white rounded-10 
                     hover:bg-secondary-700 transition-colors duration-200
                     text-poppins-small flex items-center gap-2"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
            Ajouter une catégorie
          </button>
        </div>
      </div>

      {/* Categories List */}
      <div className="space-y-6">
        {categories.map((category) => (
          <div
            key={category._id}
            className="bg-white rounded-15 shadow-sm overflow-hidden"
          >
            {/* Category Header */}
            <div className="p-6 border-b border-tertiary-300">
              <div className="flex items-center justify-between">
                <div className="flex-1 mr-4">
                  <input
                    type="text"
                    value={category.category}
                    onChange={(e) =>
                      handleUpdateCategory(category._id, e.target.value)
                    }
                    className="w-full px-4 py-2 border border-tertiary-300 rounded-10
                             text-poppins-small text-primary-600
                             focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400"
                  />
                </div>
                <div className="flex gap-3">
                  <button
                    onClick={() =>
                      setExpandedCategory(
                        expandedCategory === category._id ? null : category._id
                      )
                    }
                    className="p-2 text-tertiary-500 hover:text-secondary-600 transition-colors"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={
                          expandedCategory === category._id
                            ? "M5 15l7-7 7 7"
                            : "M19 9l-7 7-7-7"
                        }
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => handleDeleteCategory(category._id)}
                    className="p-2 text-tertiary-500 hover:text-secondary-600 transition-colors"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            {/* Questions Section */}
            {expandedCategory === category._id && (
              <div className="p-6 bg-tertiary-300 bg-opacity-10">
                {/* Existing Questions */}
                <div className="space-y-6">
                  {category.questions.map((question) => (
                    <div
                      key={question._id}
                      className="bg-white rounded-10 p-4 shadow-sm"
                    >
                      <div className="flex justify-between items-start gap-4 mb-3">
                        <input
                          type="text"
                          value={question.question}
                          onChange={(e) =>
                            handleUpdateQuestion(
                              category._id,
                              question._id,
                              e.target.value,
                              question.answer
                            )
                          }
                          className="flex-1 px-4 py-2 border border-tertiary-300 rounded-8
                                   text-poppins-small text-primary-600
                                   focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400"
                        />
                        <button
                          onClick={() => handleDeleteQuestion(question._id)}
                          className="p-2 text-tertiary-500 hover:text-secondary-600 transition-colors"
                        >
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      <textarea
                        value={question.answer}
                        onChange={(e) =>
                          handleUpdateQuestion(
                            category._id,
                            question._id,
                            question.question,
                            e.target.value
                          )
                        }
                        rows="3"
                        className="w-full px-4 py-2 border border-tertiary-300 rounded-8
                                 text-poppins-small text-primary-600
                                 focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400"
                      />
                    </div>
                  ))}
                </div>

                {/* Add New Question */}
                <div className="mt-6 p-4 bg-white rounded-10 shadow-sm">
                  <h3 className="text-poppins-small-semibold text-primary-600 mb-4">
                    Ajouter une question
                  </h3>
                  <div className="space-y-4">
                    <input
                      type="text"
                      value={newQuestions[category._id] || ""}
                      onChange={(e) =>
                        handleQuestionInputChange(category._id, e.target.value)
                      }
                      placeholder="Nouvelle question"
                      className="w-full px-4 py-2 border border-tertiary-300 rounded-8
                               text-poppins-small text-primary-600
                               focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400"
                    />
                    <textarea
                      value={newAnswers[category._id] || ""}
                      onChange={(e) =>
                        handleAnswerInputChange(category._id, e.target.value)
                      }
                      placeholder="Réponse"
                      rows="3"
                      className="w-full px-4 py-2 border border-tertiary-300 rounded-8
                               text-poppins-small text-primary-600
                               focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400"
                    />
                    <button
                      onClick={() => handleAddQuestion(category._id)}
                      className="w-full px-4 py-2 bg-secondary-600 text-white rounded-8
                               hover:bg-secondary-700 transition-colors duration-200
                               text-poppins-small"
                    >
                      Ajouter la question
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BackOfficeFaq;
