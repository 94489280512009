import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import OpenImageModal from "../../Modal/OpenImageModal";
import { useState } from "react";

const FileUploader = ({ file, setFile, fieldIdentifier }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uniqueId = `file-upload-${fieldIdentifier}`;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      // Réinitialiser les autres FileUploaders
      document.querySelectorAll('input[type="file"]').forEach((input) => {
        if (input.id !== uniqueId) {
          input.value = "";
        }
      });
    }
  };

  return (
    <>
      <div className="w-full p-4 border-[1px] border-primary-600 rounded-20 flex flex-col items-center justify-center">
        <p className="text-poppins-small">Choisir un fichier</p>

        <label
          htmlFor={uniqueId}
          className="cursor-pointer flex flex-col items-center justify-center w-full h-24"
          data-field={fieldIdentifier}
        >
          <FontAwesomeIcon
            icon={faDownload}
            className="text-secondary-600 text-2xl border-2 border-secondary-600 rounded-8 pl-8 pr-8 pt-2 pb-2 bg-secondary-300"
          />
        </label>

        <input
          id={uniqueId}
          type="file"
          onChange={handleFileChange}
          className="hidden"
          data-field={fieldIdentifier}
        />
      </div>

      {file && (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          onClick={() => setIsModalOpen(true)}
          className="w-32 h-32 object-cover rounded-lg self-start mb-2 mt-2"
        />
      )}

      <OpenImageModal
        image={file}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default FileUploader;
