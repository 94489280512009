import React, { useEffect, useState } from "react";
import useUser from "../../hooks/useUser/useUser";
import Button from "../elements/Button/Button";
import { CUISINES, DIETS } from "../../utils/TypOf";

const TypOf = () => {
  const { userInfo, actionUser, isLoading, error } = useUser();

  const [formData, setFormData] = useState({
    type_of_diet: [],
    type_of_cuisine: [],
  });

  const [successMessage, setSuccessMessage] = useState(null);
  const [showMoreDiet, setShowMoreDiet] = useState(false);
  const [showMoreCuisine, setShowMoreCuisine] = useState(false);

  // Limite initiale pour l'affichage
  const limit = 5;

  // Préremplir le formulaire avec les données utilisateur
  useEffect(() => {
    if (userInfo) {
      setFormData({
        type_of_diet: userInfo.type_of_diet || [],
        type_of_cuisine: userInfo.type_of_cuisine || [],
      });
    }
  }, [userInfo]);

  // Effacer le message de succès après 2 secondes
  useEffect(() => {
    if (successMessage) {
      const timeout = setTimeout(() => setSuccessMessage(null), 2000);
      return () => clearTimeout(timeout);
    }
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => {
      if (checked) {
        // Ajouter l'élément si coché
        return { ...prev, [name]: [...prev[name], value] };
      } else {
        // Supprimer l'élément si décoché
        return { ...prev, [name]: prev[name].filter((item) => item !== value) };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(null);

    try {
      await actionUser.updateUser(formData);
      setSuccessMessage("Vos préférences ont été mises à jour avec succès.");
    } catch (err) {
      console.error("Erreur lors de la mise à jour :", err);
    }
  };

  return (
    <div className="h-full">
      <h1 className="text-poppins-h6 mb-5">Vos préférences</h1>

      <form onSubmit={handleSubmit}>
        {/* Type of Diet */}
        <div className="mb-6">
          <h2 className="text-lg font-medium mb-3">Type de régime</h2>
          <div className="flex flex-wrap gap-4">
            {(showMoreDiet ? DIETS : DIETS.slice(0, limit)).map((diet) => (
              <label key={diet} className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="type_of_diet"
                  value={diet}
                  checked={formData.type_of_diet.includes(diet)}
                  onChange={handleChange}
                />
                {diet}
              </label>
            ))}
          </div>
          {DIETS.length > limit && (
            <p
              className="underline hover:cursor-pointer"
              onClick={() => setShowMoreDiet((prev) => !prev)}
            >
              {showMoreDiet ? "Voir moins" : "Voir plus"}
            </p>
          )}
        </div>

        {/* Type of Cuisine */}
        <div className="mb-6">
          <h2 className="text-lg font-medium mb-3">Type de cuisine</h2>
          <div className="flex flex-wrap gap-4">
            {(showMoreCuisine ? CUISINES : CUISINES.slice(0, limit)).map(
              (cuisine) => (
                <label key={cuisine} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="type_of_cuisine"
                    value={cuisine}
                    checked={formData.type_of_cuisine.includes(cuisine)}
                    onChange={handleChange}
                  />
                  {cuisine}
                </label>
              )
            )}
          </div>
          {CUISINES.length > limit && (
            <p
              className="underline hover:cursor-pointer"
              onClick={() => setShowMoreCuisine((prev) => !prev)}
            >
              {showMoreCuisine ? "Voir moins" : "Voir plus"}
            </p>
          )}
        </div>

        {/* Submit Button */}

        <div className="w-full flex justify-center flex-col items-center gap-5">
          <Button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-400"
          >
            {isLoading ? "Mise à jour..." : "Mettre à jour"}
          </Button>

          {/* Feedback Messages */}
          {isLoading && <p className="text-blue-500 mt-4">Chargement...</p>}
          {error && <p className="text-red-500 mt-4">Erreur : {error}</p>}
          {successMessage && (
            <p className="text-green-500 mt-4">{successMessage}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default TypOf;
