import { useCallback, useEffect, useState } from "react";
import {
  getFileById,
  getFilesByEstablishmentId,
  viewFile,
} from "../../services/files.services";

const useFiles = ({ establishmentId }) => {
  const [files, setFiles] = useState([]);

  const fetchFiles = useCallback(async () => {
    try {
      const res = await getFilesByEstablishmentId(establishmentId);
      setFiles(res.data.files || []);
    } catch (error) {
      console.error("Failed to fetch files", error);
    }
  }, [establishmentId]);

  const handleDownloadFile = async (fileUrl, filename) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors du téléchargement:", error);
    }
  };

  const handleViewFile = async (fileId) => {
    try {
      await viewFile(fileId);
    } catch (error) {
      console.error("Failed to view file", error);
    }
  };

  const getFile = useCallback(async (fileId) => {
    try {
      const res = await getFileById(fileId);
      return res.data.data.file;
    } catch (error) {
      console.error("Failed to fetch file:", error);
      return null;
    }
  }, []);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return {
    files,
    handleDownloadFile,
    handleViewFile,
    getFileById: getFile,
  };
};

export default useFiles;
