import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createGreenFood = async (formData, establishmentId) => {
  const data = new FormData();
  Object.keys(formData).forEach((key) => {
    if (Array.isArray(formData[key])) {
      formData[key].forEach((item) => data.append(key, JSON.stringify(item)));
    } else {
      data.append(key, formData[key]);
    }
  });

  if (formData.files) {
    formData.files.forEach((file) => data.append("files", file.file));
  }

  const response = await axios.post(`${API_URL}/form/green-food/${establishmentId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const getAllGreenFoods = async () => {
  const response = await axios.get(`${API_URL}/form/green-food`);
  return response.data;
};

export const getGreenFoodByEstablishmentId = async (establishmentId) => {
  const response = await axios.get(
    `${API_URL}/form/green-food/${establishmentId}`
  );
  return response.data;
};
