import React from "react";

const Cookies = () => {
  return (
    <div className=" bg-senary-600 py-10 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-20 p-8 shadow-md">
        {/* Titre principal */}
        <h1 className="text-roca-h3 font-bold mb-2">Politique de Cookies</h1>
        <p className="text-poppins-small text-gray-600 mb-6">
          Dernière mise à jour&nbsp;: 01/03/2025
        </p>

        {/* 1. Introduction */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">1. Introduction</h2>
        <p className="text-poppins-paragraph mb-4">
          La présente Politique de Cookies décrit la manière dont LabelAdresse
          (ci-après « nous », « notre organisation » ou « notre site ») utilise
          des cookies et technologies similaires pour collecter et stocker des
          informations lorsque vous visitez{" "}
          <a
            href="https://www.labeladresse.fr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-600 underline"
          >
            www.labeladresse.fr
          </a>
          . Elle vous informe également sur la façon de gérer vos préférences en
          matière de cookies.
        </p>

        {/* 2. Qu’est-ce qu’un cookie ? */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          2. Qu’est-ce qu’un cookie&nbsp;?
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Un cookie est un petit fichier texte déposé sur votre ordinateur ou
          appareil mobile par le serveur d’un site web lorsque vous le
          consultez. Il permet notamment de vous reconnaître d’une page à
          l’autre, d’enregistrer vos préférences ou d’analyser votre navigation.
        </p>

        {/* 3. Les cookies que nous utilisons */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          3. Les cookies que nous utilisons
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous utilisons plusieurs types de cookies, notamment&nbsp;:
        </p>
        <ul className="list-disc list-inside mb-4 text-poppins-paragraph">
          <li>
            <strong>Cookies essentiels&nbsp;:</strong> nécessaires au bon
            fonctionnement du site et à la navigation (ex. : mémorisation de la
            session, fonctionnement du panier ou du formulaire de paiement via
            Stripe). Sans ces cookies, le site pourrait ne pas fonctionner
            correctement.
          </li>
          <li>
            <strong>
              Cookies de mesure d’audience (Google Analytics)&nbsp;:
            </strong>{" "}
            nous aident à comprendre comment les visiteurs interagissent avec le
            site, afin d’améliorer son contenu et ses fonctionnalités. Google
            Analytics peut recueillir votre adresse IP, votre type de
            navigateur, la durée de vos visites, etc.
          </li>
          <li>
            <strong>Cookies tiers pour le paiement (Stripe)&nbsp;:</strong>{" "}
            lorsque vous effectuez un paiement, Stripe peut déposer des cookies
            pour faciliter la transaction et lutter contre la fraude. Pour plus
            d’informations, veuillez consulter la{" "}
            <a
              href="https://stripe.com/fr/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary-600 underline"
            >
              Politique de Confidentialité de Stripe
            </a>
            .
          </li>
        </ul>

        {/* 4. Gestion des cookies */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          4. Gestion des cookies
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Vous pouvez à tout moment accepter ou refuser l’installation de
          cookies (hors cookies essentiels) en configurant les paramètres de
          votre navigateur. La plupart des navigateurs vous permettent de gérer
          vos préférences en matière de cookies, de supprimer les cookies
          existants et d’être averti avant l’installation de nouveaux cookies.
        </p>
        <p className="text-poppins-paragraph mb-4">
          Toutefois, le refus de certains cookies peut affecter votre expérience
          de navigation sur le site (par exemple, vous empêcher de finaliser un
          paiement via Stripe ou de bénéficier pleinement des statistiques via
          Google Analytics).
        </p>

        {/* 5. Durée de conservation */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          5. Durée de conservation
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Les cookies ont une durée de vie limitée (généralement de quelques
          jours à quelques mois). Une fois cette durée expirée, ils sont
          automatiquement supprimés de votre appareil.
        </p>

        {/* 6. Sécurité et confidentialité */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          6. Sécurité et confidentialité
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Les informations collectées via les cookies sont traitées de manière
          confidentielle et protégées par des mesures de sécurité (connexion
          HTTPS, etc.) afin d’éviter tout accès non autorisé ou divulgation.
        </p>

        {/* 7. Vos droits */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">7. Vos droits</h2>
        <p className="text-poppins-paragraph mb-4">
          Conformément au Règlement (UE) 2016/679 (RGPD), vous disposez d’un
          droit d’accès, de rectification, d’effacement, de limitation et
          d’opposition au traitement de vos données personnelles. Pour exercer
          ces droits ou pour toute question relative à l’utilisation des
          cookies, vous pouvez nous contacter à&nbsp;:{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
          .
        </p>

        {/* 8. Modifications de la politique de cookies */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          8. Modifications de la Politique de Cookies
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous pouvons être amenés à mettre à jour la présente Politique de
          Cookies afin de refléter les évolutions légales ou techniques, ou pour
          améliorer nos pratiques. Les modifications seront publiées sur cette
          page avec une date de mise à jour.
        </p>

        {/* 9. Contact */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">9. Contact</h2>
        <p className="text-poppins-paragraph mb-4">
          Pour toute question relative à cette Politique de Cookies, vous pouvez
          nous écrire à&nbsp;:{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Cookies;
