import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Auth/Provider/AuthProvider";

// Importez vos assets (logos, icônes, etc.)
import LogoFooter from "../../assets/images/Logo/Logo_secondaire_vertical.png";
import instagram from "../../assets/images/Footer/instagram.svg";
// import facebook from "../../assets/images/Footer/facebook.svg";
// import twitter from "../../assets/images/Footer/twitter.svg";
// import youtube from "../../assets/images/Footer/youtube.svg";
// import AppleDownload from "../../assets/images/Footer/AppleDownload.svg";
// import GoogleDownload from "../../assets/images/Footer/GoogleDownload.png";

/** Composant simple pour un lien vers un réseau social */
const SocialLink = ({ logo, alt, link, text }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center gap-2 hover:opacity-80"
  >
    <img src={logo} alt={alt} className="w-5 h-5" />
    <span>{text}</span>
  </a>
);

const Footer = () => {
  const { user } = useAuth();
  const roleUser = user?.role;

  // Mapping des liens de pied de page selon le rôle
  const menuFooterLinks = {
    auditor: [
      { to: "/", label: "Accueil" },
      { to: "/my-requests", label: "Mes Demandes" },
      { to: "/all-requests", label: "Toutes les Demandes" },
    ],
    owner: [
      { to: "/", label: "Accueil" },
      { to: "/my-establishements", label: "Mes Restaurants" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts-pro", label: "Contacts" },
    ],
    admin: [{ to: "/backoffice", label: "BackOffice" }],
    user: [
      { to: "/", label: "Accueil" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts", label: "Contacts" },
    ],
    default: [
      { to: "/", label: "Accueil" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts", label: "Contacts" },
    ],
  };

  // Récupération des liens en fonction du rôle (ou default)
  const links = menuFooterLinks[roleUser] || menuFooterLinks.default;

  return (
    <footer className="bg-primary-600 text-white pt-10 pb-6 px-6 md:px-8 lg:px-20 mt-16">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
        {/* COLONNE 1 : À propos */}
        <div className="flex flex-col gap-4">
          <h3 className="font-semibold mb-2">À propos</h3>
          <img src={LogoFooter} alt="Logo LabelAdresse" className="w-[120px]" />
          <p className="text-sm leading-relaxed">
            LabelAdresse est un label de confiance pour les restaurants et
            établissements, basé sur des critères de qualité, de responsabilité
            et d’engagement.
          </p>
        </div>

        {/* COLONNE 2 : Navigation & Informations légales */}
        <div className="flex flex-col gap-4">
          <div>
            <h3 className="font-semibold mb-2">Navigation</h3>
            {links.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className="hover:opacity-80 block"
              >
                {link.label}
              </Link>
            ))}
          </div>

          <div>
            <h3 className="font-semibold mb-2 mt-4">Informations légales</h3>
            <Link to="/privacy-policy" className="hover:opacity-80 block">
              Politique de confidentialité
            </Link>
            <Link to="/terms-and-conditions" className="hover:opacity-80 block">
              Conditions générales
            </Link>
            <Link to="/cookies-policy" className="hover:opacity-80 block">
              Politique de cookies
            </Link>
          </div>
        </div>

        {/* COLONNE 3 : Suivez-nous */}
        <div className="flex flex-col gap-4">
          <h3 className="font-semibold mb-2">Suivez-nous</h3>
          <SocialLink
            logo={instagram}
            alt="Instagram"
            link="https://www.instagram.com/label.adresse/"
            text="Instagram"
          />
          {/* 
          <SocialLink
            logo={facebook}
            alt="Facebook"
            link="https://facebook.com"
            text="Facebook"
          />
          <SocialLink
            logo={twitter}
            alt="Twitter"
            link="https://twitter.com"
            text="Twitter"
          />
          <SocialLink
            logo={youtube}
            alt="YouTube"
            link="https://youtube.com"
            text="YouTube"
          />
          
          <h3 className="font-semibold mb-2">Télécharger l’application</h3>
          <div className="flex flex-col items-start gap-2">
            <a
              href="https://app-store.com"
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80"
            >
              <img
                src={AppleDownload}
                alt="Download on the App Store"
                className="w-[140px]"
              />
            </a>
            <a
              href="https://play.google.com"
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80"
            >
              <img
                src={GoogleDownload}
                alt="Get it on Google Play"
                className="w-[140px]"
              />
            </a>
          </div>
          */}
        </div>
      </div>

      {/* BAS DE PAGE */}
      <div className="mt-8 text-center text-sm text-gray-200">
        © 2025 LabelAdresse. Tous droits réservés.
      </div>
    </footer>
  );
};

export default Footer;
