import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLocationDot,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const DropDownSelect = ({
  cities,
  selectedCity,
  handleCityChange,
  handleGeolocation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectCity = (city) => {
    handleCityChange(city);
    setIsOpen(false);
  };

  const handleLocationRequest = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
            );
            const { address } = response.data;
            const cityName =
              address.city || address.town || address.village || "";
            const cityData = {
              label: cityName,
              lat: latitude,
              lon: longitude,
              value: cityName.toLowerCase(),
            };
            handleGeolocation(cityData); // Met à jour la ville récupérée
            setIsOpen(false); // Ferme le menu
          } catch (error) {
            console.error(
              "Erreur lors de la récupération des données de localisation",
              error
            );
          }
        },
        (error) => {
          console.error("Erreur lors de l'obtention de la localisation", error);
        }
      );
    } else {
      console.error("Géolocalisation non supportée");
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative">
        <div
          ref={buttonRef}
          onClick={toggleDropdown}
          className="bg-white w-[106px]  p-3 rounded-lg text-gray-900 text-left cursor-pointer focus:outline-none flex justify-between items-center"
        >
          {selectedCity.label}
          <FontAwesomeIcon
            icon={faChevronRight}
            className={`ml-2 transition-transform duration-300 ease-in-out ${
              isOpen ? "rotate-90" : "rotate-0"
            }`}
          />
        </div>
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute z-10 bg-white w-64 h-hauto overflow-auto mt-1 rounded-8 shadow-lg gap-1"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div
            onClick={handleLocationRequest}
            className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-8 h-12"
          >
            <FontAwesomeIcon icon={faMapPin} className="mr-2 text-teal-500" />
            Utiliser ma localisation
          </div>
          <div className="p-2 text-poppins-small">Suggestions</div>
          {cities.map((city) => (
            <div
              key={city.value}
              onClick={() => selectCity(city)}
              className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-8 h-12"
            >
              <FontAwesomeIcon
                icon={faLocationDot}
                className="mr-2 text-teal-500"
              />
              {city.label}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DropDownSelect;
