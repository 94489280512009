import React from "react";
import Input from "../../elements/Input/Input";
import Button from "../../elements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useRegister from "../../../hooks/useRegister";

const MultiStepRegistrationForm = () => {
  const {
    handleSubmit,
    handleChange,
    handleNext,
    handlePrevious,
    step,
    formData,
    errors,
  } = useRegister();

  const renderStep1 = () => (
    <div className="space-y-4">
      <div className="flex flex-col gap-4">
        <div>
          <Input
            placeholder="Nom"
            label="Nom"
            type="text"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
          />
          {errors.lastname && (
            <p className="text-red-500 text-sm -mt-4">{errors.lastname}</p>
          )}
        </div>
        <div>
          <Input
            placeholder="Prénom"
            label="Prénom"
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
          />
          {errors.firstname && (
            <p className="text-red-500 text-sm -mt-4">{errors.firstname}</p>
          )}
        </div>
        <div>
          <Input
            type="email"
            label="Email"
            name="email"
            placeholder="Exemple@domaine.fr"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <p className="text-red-500 text-sm -mt-4">{errors.email}</p>
          )}
        </div>
        <div>
          <Input
            type="password"
            label="Mot de passe"
            name="password"
            placeholder="Mot de passe"
            isShowPassword
            value={formData.password}
            onChange={handleChange}
          />
          {errors.password && (
            <p className="text-red-500 text-sm -mt-4">{errors.password}</p>
          )}
        </div>
        <div>
          <Input
            type="password"
            label="Confirmer le mot de passe"
            name="confirmPassword"
            placeholder="Confirmer le mot de passe"
            isShowPassword
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {errors.confirmPassword && (
            <p className="text-red-500 text-sm -mt-4">
              {errors.confirmPassword}
            </p>
          )}
        </div>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            name="accepted_cgu"
            checked={formData.accepted_cgu}
            onChange={handleChange}
            className="h-4 w-4 text-orange-500"
          />
          <label className="text-sm px-4 py-2">
            J'accepte{" "}
            <a href="/cgu" className="text-orange-500 underline">
              les conditions d'utilisation
            </a>
          </label>
        </div>
        {errors.accepted_cgu && (
          <p className="text-red-500 text-sm">{errors.accepted_cgu}</p>
        )}
        <div className="flex flex-row justify-between text-poppins-extra-small">
          <p>Vous avez déjà un compte ?</p>
          <a href="/login" className="underline">
            Connectez-vous
          </a>
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-4">
      <p className="text-poppins-paragraph">
        Vous venez de recevoir un courriel de confirmation. Prenez quelques
        instants pour vérifier votre e-mail. Vous pourrez ensuite plonger dans
        l'application et l'explorer !
      </p>
    </div>
  );

  return (
    <div className="flex items-center flex-col px-4">
      <div className="w-full md:w-[530px] mx-auto p-6 bg-white rounded-lg shadow-lg">
        {step === 2 && (
          <div className="mb-4">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="hover:cursor-pointer"
              onClick={handlePrevious}
            />
          </div>
        )}
        <div className="mb-8 flex justify-center items-center gap-8">
          <h2 className="text-roca-h4-5 !text-secondary-600 text-center">
            {step === 1 ? "Commençons par là" : "Dernière étape"}
          </h2>
        </div>
        <div className="mb-8">{step === 1 ? renderStep1() : renderStep2()}</div>
        <div className="flex justify-center">
          {step === 1 ? (
            <Button onClick={handleNext} colorType="secondary">
              Continuer
            </Button>
          ) : (
            <Button onClick={handleSubmit} colorType="secondary">
              Valider Email
            </Button>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="flex gap-2">
          <div
            className={`w-3 h-3 rounded-full ${
              step === 1 ? "bg-orange-500" : "bg-gray-300"
            }`}
          />
          <div
            className={`w-3 h-3 rounded-full ${
              step === 2 ? "bg-orange-500" : "bg-gray-300"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiStepRegistrationForm;
