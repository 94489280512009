import React, { useState } from "react";
import Input from "../../../elements/Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import FileUploader from "../../../elements/Input/FileUploader";
import Button from "../../../elements/Button/Button";

const AddEtiquette = ({ fields, onAdd, title }) => {
  const [formData, setFormData] = useState(() =>
    fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const [file, setFile] = useState(null); // Gestion du fichier
  const [enableFileUpload, setEnableFileUpload] = useState(false); // Activation upload fichier

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const isValid = fields.every((field) => formData[field.name].trim() !== "");
    if (isValid) {
      onAdd({ ...formData, file }); // Ajout du fichier dans les données
      setFormData(
        fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
      ); // Reset les champs
      setFile(null); // Reset file
      setEnableFileUpload(false); // Désactiver l'upload après ajout
    }
  };

  return (
    <div className="space-y-4 border border-gray-300 p-4 rounded-lg shadow-md bg-white">
      <h3 className="font-bold text-gray-800">{title}</h3>

      {/* Champs texte */}
      {fields.map((field) => (
        <div key={field.name}>
          <Input
            label={field.label}
            type={field.type}
            name={field.name}
            value={formData[field.name]}
            onChange={handleChange}
            placeholder={field.placeholder}
            className="border p-2 w-full rounded"
          />
        </div>
      ))}

      {/* Activer/Désactiver l'upload de fichier */}
      {/* <div className="text-poppins-small">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={enableFileUpload}
            onChange={() => setEnableFileUpload((prev) => !prev)}
          />
          <span>Ajouter un fichier</span>
        </label>
      </div> */}

      {/* Composant FileUploader */}
      {enableFileUpload && <FileUploader file={file} setFile={setFile} />}

      {/* Affichage du fichier sélectionné */}
      {file && (
        <div className="flex items-center justify-between border border-gray-300 p-3 rounded-lg bg-gray-50 shadow-sm mt-4">
          <div className="flex items-center gap-4">
            <FontAwesomeIcon
              icon={faFilePdf}
              className="text-primary-600 text-2xl"
            />
            <div>
              <p className="text-gray-700 font-medium">{file.name}</p>
              <p className="text-sm text-gray-500">
                {(file.size / 1024).toFixed(2)} KB
              </p>
            </div>
          </div>

          <button
            onClick={() => setFile(null)}
            className="text-red-500 hover:text-red-700"
          >
            <FontAwesomeIcon icon={faTimes} className="text-xl" />
          </button>
        </div>
      )}

      <div className="flex justify-end">
        <Button
          onClick={handleAdd}
          colorType="secondary"
          AddtionnalClasses={"mt-4"}
        >
          Ajouter
        </Button>
      </div>
    </div>
  );
};

export default AddEtiquette;
