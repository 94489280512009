import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import {
  getEstablishments,
  validateEstablishment,
  rejectEstablishment,
} from "../../services/establishements.services";
import {
  getAllUsers,
  acceptOwnerRequest,
  rejectOwnerRequest,
  getUserById,
} from "../../services/user.services";

const BackOfficeBecomeOwner = () => {
  const [establishments, setEstablishments] = useState([]);
  const [users, setUsers] = useState([]);
  const [owners, setOwners] = useState({});
  const [expandedOwners, setExpandedOwners] = useState({});

  const fetchData = useCallback(async () => {
    try {
      // Récupération des établissements
      const establishmentsResponse = await getEstablishments();
      const establishmentsArray = establishmentsResponse?.data?.establishments;
      let waitingEstablishments = [];
      if (Array.isArray(establishmentsArray)) {
        waitingEstablishments = establishmentsArray.filter(
          (est) => est.isWaitingForValidation
        );
        setEstablishments(waitingEstablishments);
      } else {
        console.error(
          "La réponse des établissements n'est pas au format attendu.",
          establishmentsResponse
        );
      }

      // Récupération des informations des propriétaires pour chaque établissement
      const uniqueOwnerIds = [
        ...new Set(waitingEstablishments.map((est) => est.ownerUserId)),
      ];
      const ownerPromises = uniqueOwnerIds.map((ownerId) =>
        getUserById(ownerId)
      );
      const ownersResponses = await Promise.all(ownerPromises);
      const ownersMap = {};
      ownersResponses.forEach((owner) => {
        if (owner && owner._id) {
          ownersMap[owner._id] = owner;
        }
      });
      setOwners(ownersMap);

      // Récupération des utilisateurs demandant à devenir propriétaire
      const usersResponse = await getAllUsers();
      const usersArray = usersResponse?.users;
      if (Array.isArray(usersArray)) {
        const ownerRequests = usersArray.filter(
          (user) => user.requestToBeOwner
        );
        setUsers(ownerRequests);
      } else {
        console.error(
          "La réponse des utilisateurs n'est pas au format attendu.",
          usersResponse
        );
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleOwnerDetails = (estId) => {
    setExpandedOwners((prev) => ({
      ...prev,
      [estId]: !prev[estId],
    }));
  };

  const handleValidateEstablishment = async (establishmentId) => {
    const res = await validateEstablishment(establishmentId);
    if (res && res.status === "success") {
      Swal.fire({
        title: "Succès",
        text: "Restaurant validé avec succès",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        fetchData();
      });
    }
  };

  const handleRejectEstablishment = async (establishmentId) => {
    const res = await rejectEstablishment(establishmentId);
    if (res && res.success) {
      Swal.fire({
        title: "Succès",
        text: "Restaurant refusé avec succès",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        fetchData();
      });
    }
  };

  const handleAcceptUser = async (userId) => {
    const res = await acceptOwnerRequest(userId);
    if (res && res.success) {
      Swal.fire({
        title: "Succès",
        text: "Demande acceptée",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        fetchData();
      });
    }
  };

  const handleRejectUser = async (userId) => {
    const res = await rejectOwnerRequest(userId);
    if (res && res.success) {
      Swal.fire({
        title: "Succès",
        text: "Demande refusée",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        fetchData();
      });
    }
  };

  return (
    <div className="container p-6 max-w-7xl mx-auto bg-background-600">
      <h1 className="text-primary-600 text-poppins-h4-5 text-center mb-8">
        BackOffice - Devenir Propriétaire
      </h1>

      {/* Section Utilisateurs */}
      <div className="mb-8 bg-white p-6 rounded-15 shadow-sm">
        <h2 className="text-primary-600 text-poppins-h5 mb-4">
          Utilisateurs demandant à devenir propriétaire
        </h2>
        {users.length === 0 ? (
          <p className="text-center text-tertiary-500 italic">
            Aucune demande.
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {users.map((user) => (
              <div key={user._id} className="bg-white shadow-xl rounded-15 p-6">
                <div className="mb-4 border-b pb-2">
                  <h3 className="text-primary-600 text-poppins-h6">
                    {user.firstname} {user.lastname}
                  </h3>
                  <p className="text-tertiary-500 text-poppins-small">
                    {user.email}
                  </p>
                </div>
                <div className="mb-4">
                  <p className="text-poppins-small">
                    <span className="font-semibold">SIRET:</span>{" "}
                    {user.company_siret || "Non renseigné"}
                  </p>
                  <p className="text-poppins-small">
                    <span className="font-semibold">Adresse:</span>{" "}
                    {user.company_address || "Non renseignée"}
                  </p>
                  <p className="text-poppins-small">
                    <span className="font-semibold">Nom:</span>{" "}
                    {user.company_name || "Non renseignée"}
                  </p>
                </div>
                <div className="flex justify-between">
                  <button
                    onClick={() => handleAcceptUser(user._id)}
                    className="flex items-center gap-2 bg-secondary-600 hover:bg-secondary-700 text-white rounded-10 px-4 py-2"
                  >
                    Accepter
                  </button>
                  <button
                    onClick={() => handleRejectUser(user._id)}
                    className="flex items-center gap-2 bg-secondary-600 hover:bg-secondary-700 text-white rounded-10 px-4 py-2"
                  >
                    Rejeter
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Section Restaurants */}
      <div className="bg-white p-6 rounded-15 shadow-sm">
        <h2 className="text-primary-600 text-poppins-h5 mb-4">
          Restaurants en attente de validation
        </h2>
        {establishments.length === 0 ? (
          <p className="text-center text-tertiary-500 italic">
            Aucun restaurant en attente.
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {establishments.map((est) => (
              <div
                key={est._id}
                className="bg-white rounded-15 shadow-sm p-6 flex flex-col"
              >
                <div className="mb-4 border-b pb-2">
                  <h3 className="text-primary-600 text-poppins-h6">
                    {est.name}
                  </h3>
                  <p className="text-tertiary-500 text-poppins-small">
                    {est.address.city}
                  </p>
                  {owners[est.ownerUserId] ? (
                    <div>
                      <p className="text-poppins-small text-tertiary-600">
                        Propriétaire: {owners[est.ownerUserId].firstname}{" "}
                        {owners[est.ownerUserId].lastname}
                      </p>
                      <button
                        onClick={() => toggleOwnerDetails(est._id)}
                        className="text-secondary-600 underline text-poppins-small"
                      >
                        {expandedOwners[est._id] ? "Masquer" : "Afficher plus"}
                      </button>
                      {expandedOwners[est._id] && (
                        <div className="mt-2 p-4 border rounded bg-background-600">
                          <p className="text-poppins-small">
                            Email: {owners[est.ownerUserId].email}
                          </p>
                          <p className="text-poppins-small">
                            Société:{" "}
                            {owners[est.ownerUserId].company_name ||
                              "Non renseignée"}
                          </p>
                          <p className="text-poppins-small">
                            Adresse société:{" "}
                            {owners[est.ownerUserId].company_address ||
                              "Non renseignée"}
                          </p>
                          <p className="text-poppins-small">
                            SIRET:{" "}
                            {owners[est.ownerUserId].company_siret ||
                              "Non renseigné"}
                          </p>
                          <p className="text-poppins-small">
                            Role: {owners[est.ownerUserId].role}
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p className="text-poppins-small text-tertiary-500">
                      Chargement du propriétaire...
                    </p>
                  )}
                </div>
                <div className="mt-auto flex gap-2">
                  <button
                    onClick={() => handleValidateEstablishment(est._id)}
                    className="w-1/2 flex items-center justify-center bg-secondary-600 hover:bg-secondary-700 text-white rounded-10 px-4 py-2"
                  >
                    Valider
                  </button>
                  <button
                    onClick={() => handleRejectEstablishment(est._id)}
                    className="w-1/2 flex items-center justify-center bg-red-600 hover:bg-red-700 text-white rounded-10 px-4 py-2"
                  >
                    Refuser
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BackOfficeBecomeOwner;
