import React, { useState } from "react";
import useUser from "../../hooks/useUser/useUser";

const ReviewUser = () => {
  const { reviews, isLoading, error } = useUser();
  const [showAllReviews, setShowAllReviews] = useState(false);


  if (isLoading) return <p className="text-blue-500">Chargement des avis...</p>;
  if (error) return <p className="text-red-500">Erreur : {error}</p>;

  if (!Array.isArray(reviews)) {
    return (
      <p className="text-red-500">
        Les avis n'ont pas pu être chargés correctement.
      </p>
    );
  }

  const visibleReviews = showAllReviews ? reviews : reviews.slice(0, 3);

  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-6">Mes Avis</h2>
      {reviews.length === 0 ? (
        <p className="text-gray-500">Aucun avis disponible pour le moment.</p>
      ) : (
        <ul className="space-y-6">
          {visibleReviews.map((review) => (
            <li
              key={review._id}
              className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200"
            >
              <h3 className="text-lg font-bold text-gray-800">
                {review.establishmentId?.name || "Nom du restaurant inconnu"}
              </h3>
              <p className="text-md font-medium text-gray-700 mt-2">
                {review.title}
              </p>
              <p className="text-sm text-gray-600 mt-1">{review.comment}</p>
              <div className="text-xs text-gray-500 mt-3">
                Posté le : {new Date(review.createdAt).toLocaleDateString()}
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Bouton Voir plus / Voir moins */}
      {reviews.length > 5 && (
        <div className="mt-6 text-center">
          <button
            onClick={() => setShowAllReviews((prev) => !prev)}
            className="text-blue-500 hover:text-blue-700 font-medium"
          >
            {showAllReviews ? "Voir moins" : "Voir plus"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReviewUser;
