import { useEffect, useState } from "react";

const OpenImageModal = ({ image, isOpen, onClose }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (image) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);

      return () => URL.revokeObjectURL(url); // Nettoyage mémoire
    }
  }, [image]); // Dépend uniquement de `image`

  if (!isOpen || !imageUrl) return null; // Évite d'afficher un état non valide

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
        {/* Bouton de fermeture */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full hover:bg-red-700 transition"
        >
          ✕
        </button>

        {/* Affichage de l'image */}
        <img
          src={imageUrl}
          alt={image.name}
          className="w-auto max-h-[80vh] object-contain rounded-lg"
        />
      </div>
    </div>
  );
};

export default OpenImageModal;
