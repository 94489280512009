import React, { act, useState } from "react";
import useBackOffice from "../../hooks/BackOffice/useBackOffice";
import { updateLabelRequestById } from "../../services/labelRequest.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin, faPen } from "@fortawesome/free-solid-svg-icons";
import WhatIsTheAction from "../Owner/ActionsEstablishement/WhatIsTheAction";

const BackOfficeRequests = () => {
  const { requests, groups, getRequest } = useBackOffice();
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [modalMode, setModalMode] = useState(null);

  const handleOpenModal = (request, mode) => {
    setSelectedRequest(request);
    setModalMode(mode);
    if (mode === "edit") {
      setSelectedGroup(request.group);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedRequest(null);
    setSelectedGroup(null);
    setModalMode(null);
    setShowModal(false);
    getRequest();
  };

  const openInGoogleMaps = (establishment) => {
    if (establishment?.coordinates?.lat && establishment?.coordinates?.lon) {
      window.open(
        `https://www.google.com/maps?q=${establishment.coordinates.lat},${establishment.coordinates.lon}`,
        "_blank"
      );
      return;
    }

    if (establishment?.address) {
      const { street, city, postalCode, country } = establishment.address;
      const fullAddress = encodeURIComponent(
        `${street}, ${postalCode} ${city}, ${country}`
      );
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${fullAddress}`,
        "_blank"
      );
    }
  };

  const handleAssignGroup = async () => {
    if (selectedRequest?._id && selectedGroup?._id) {
      try {
        await updateLabelRequestById(selectedRequest._id, {
          group: selectedGroup._id,
        });
        handleCloseModal();
      } catch (error) {
        console.error("Erreur lors de l'assignation du groupe:", error);
      }
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes < 1024) return bytes + " B";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
    else return (bytes / 1048576).toFixed(1) + " MB";
  };

  return (
    <div className="p-6 max-w-7xl mx-auto bg-background-600">
      <div>
        <WhatIsTheAction  actionIs="label" />
      </div>
      {/* Header */}
      <div className="mb-8">
        <h1 className="text-poppins-h4 text-primary-600 mb-2">
          Gestion des Demandes
        </h1>
        <p className="text-poppins-small text-tertiary-500">
          Gérez et assignez les demandes de label
        </p>
      </div>

      {/* Liste des demandes */}
      <div className="grid gap-6">
        {requests?.length > 0 ? (
          requests.map((request) => (
            <div
              key={request._id}
              className="bg-white rounded-15 shadow-sm hover:shadow-md transition-all duration-200 p-6"
            >
              {/* En-tête de la demande */}
              <div className="flex justify-between items-start">
                <div className="space-y-3">
                  <div className="flex items-center gap-3">
                    <div className="text-poppins-h6">
                      <h1>Information sur la demande</h1>
                    </div>
                    <div className="flex items-center gap-3">
                      <span
                        className={`inline-block px-3 py-1 rounded-10 text-poppins-extra-small
                      ${
                        request.status === "pending"
                          ? "bg-yellow-100 text-yellow-600"
                          : request.status === "approved"
                          ? "bg-green-100 text-green-600"
                          : "bg-red-100 text-red-600"
                      }`}
                      >
                        {request.status}
                      </span>
                    </div>
                  </div>
                  <div className="mt-6 bg-tertiary-100 rounded-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {request.requester ? (
                        <div className="space-y-2">
                          <div className="flex items-center flex-col gap-2">
                            <div className="flex  gap-4 items-center w-full">
                              <span className="text-poppins-small text-tertiary-500">
                                Nom du restaurant : {request.requester.name}
                              </span>
                              <div>
                                <FontAwesomeIcon
                                  icon={faMapPin}
                                  className="h-4 w-4 hover:cursor-pointer p-2 bg-gray-200 rounded-8"
                                  onClick={() =>
                                    openInGoogleMaps(request.requester)
                                  }
                                  title="Voir sur la carte"
                                />
                              </div>
                            </div>
                            <div className="w-full">
                              <span className="text-poppins-small text-tertiary-500">
                                Label demandé : {request.label}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="text-poppins-small text-tertiary-500">
                          Informations de l'établissement non disponibles
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {!request.group ? (
                  <button
                    onClick={() => handleOpenModal(request, "assign")}
                    className="px-4 py-2 bg-secondary-600 text-white rounded-10 
              hover:bg-secondary-700 transition-colors duration-200
              text-poppins-small flex items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                    Assigner un groupe
                  </button>
                ) : (
                  <div className="flex items-center gap-4">
                    <div className="px-4 py-2 bg-tertiary-100 rounded-10 text-primary-600 text-poppins-small">
                      Groupe assigné : {request.group?.name}
                    </div>
                    <button
                      onClick={() => handleOpenModal(request, "edit")}
                      className="p-2 text-tertiary-500 hover:text-secondary-600 transition-colors rounded-full hover:bg-tertiary-100"
                    >
                      <FontAwesomeIcon icon={faPen} className="h-4 w-4" />
                    </button>
                  </div>
                )}
              </div>

              {/* Informations de l'établissement */}

              {/* Documents */}
              {request.documents?.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-poppins-small-semibold text-primary-600 mb-4">
                    Documents soumis
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {request.documents.map((doc) => (
                      <div
                        key={doc._id}
                        className="flex items-start space-x-4 p-4 bg-tertiary-100 rounded-10 hover:bg-tertiary-200 transition-colors duration-200"
                      >
                        <div className="flex-shrink-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 text-tertiary-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>

                        <div className="flex-1 min-w-0">
                          <p className="text-poppins-small text-primary-600 truncate">
                            {doc.filename}
                          </p>
                          <div className="mt-1 flex items-center text-poppins-extra-small text-tertiary-500 space-x-4">
                            <span>{doc.mimeType}</span>
                            <span>{formatFileSize(doc.size)}</span>
                            <span
                              className={`px-2 py-0.5 rounded-full 
                              ${
                                doc.status === "pending"
                                  ? "bg-yellow-100 text-yellow-600"
                                  : doc.status === "approved"
                                  ? "bg-green-100 text-green-600"
                                  : "bg-red-100 text-red-600"
                              }`}
                            >
                              {doc.status}
                            </span>
                          </div>
                        </div>

                        <a
                          href={doc.fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex-shrink-0 text-tertiary-600 hover:text-secondary-600 transition-colors duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-center py-8 text-tertiary-500 text-poppins-small">
            Aucune requête disponible.
          </div>
        )}
      </div>

      {/* Modal d'assignation de groupe */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-20 shadow-xl w-full max-w-lg">
            <div className="px-6 py-4 border-b border-tertiary-300">
              <h2 className="text-poppins-h6 text-primary-600">
                {modalMode === "edit"
                  ? "Modifier le groupe"
                  : "Assigner un groupe"}
              </h2>
            </div>

            <div className="p-6">
              {/* Info de la demande sélectionnée */}
              <div className="mb-6 p-4 bg-tertiary-100 rounded-10">
                <h3 className="text-poppins-small-semibold text-primary-600 mb-2">
                  Détails de la demande
                </h3>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <span className="text-poppins-small text-tertiary-500">
                      Label :
                    </span>
                    <span className="text-poppins-small text-primary-600">
                      {selectedRequest?.label}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-poppins-small text-tertiary-500">
                      Établissement :
                    </span>
                    <span className="text-poppins-small text-primary-600">
                      {selectedRequest?.requester?.name || "Non spécifié"}
                    </span>
                  </div>
                </div>
              </div>

              {/* Liste des groupes */}
              <div className="space-y-4">
                <h3 className="text-poppins-small-semibold text-primary-600">
                  Sélectionner un groupe
                </h3>
                <div className="max-h-60 overflow-y-auto space-y-2">
                  {groups?.map((group) => (
                    <button
                      key={group._id}
                      onClick={() => setSelectedGroup(group)}
                      className={`w-full p-3 rounded-10 text-left transition-colors duration-200
                        ${
                          selectedGroup?._id === group._id
                            ? "bg-secondary-600 text-white"
                            : "bg-tertiary-100 text-primary-600 hover:bg-tertiary-200"
                        }`}
                    >
                      <div className="text-poppins-small font-medium">
                        {group.name}
                      </div>
                      <div
                        className={`text-poppins-extra-small ${
                          selectedGroup?._id === group._id
                            ? "text-white"
                            : "text-tertiary-500"
                        }`}
                      >
                        {group.label}
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              {/* Actions */}
              <div className="flex justify-end gap-3 mt-8">
                <button
                  onClick={handleCloseModal}
                  className="px-4 py-2 border border-tertiary-300 rounded-10 
                           text-tertiary-600 hover:bg-tertiary-100
                           text-poppins-small transition-colors duration-200"
                >
                  Annuler
                </button>
                <button
                  onClick={handleAssignGroup}
                  disabled={!selectedGroup}
                  className={`px-4 py-2 rounded-10 text-poppins-small
    ${
      selectedGroup
        ? "bg-secondary-600 text-white hover:bg-secondary-700"
        : "bg-tertiary-300 text-tertiary-500 cursor-not-allowed"
    } transition-colors duration-200`}
                >
                  {modalMode === "edit" ? "Modifier" : "Assigner"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BackOfficeRequests;
