import React, { useEffect, useState } from "react";
import Input from "../elements/Input/Input";
import Button from "../elements/Button/Button";
import useUser from "../../hooks/useUser/useUser";

const OtherInfo = () => {
  const { userInfo, actionUser, isLoading, error } = useUser();

  const [formData, setFormData] = useState({
    birthday: "",
    biographie: "",
    city: "",
  });

  const [successMessage, setSuccessMessage] = useState(null);

  // Fonction pour formater la date
  const formatDate = (dateString) => {
    if (!dateString) return "";
    return new Date(dateString).toISOString().split("T")[0]; // Formate la date en YYYY-MM-DD
  };

  // Préremplir le formulaire avec les données utilisateur
  useEffect(() => {
    if (userInfo) {
      setFormData({
        birthday: formatDate(userInfo.birthday),
        biographie: userInfo.biographie || "",
        city: userInfo.city || "",
      });
    }
  }, [userInfo]);

  // Effacer le message de succès après 2 secondes
  useEffect(() => {
    if (successMessage) {
      const timeout = setTimeout(() => setSuccessMessage(null), 2000);
      return () => clearTimeout(timeout);
    }
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(null);

    try {
      await actionUser.updateUser(formData);
      setSuccessMessage("Vos informations ont été mises à jour avec succès.");
    } catch (err) {
      console.error("Erreur lors de la mise à jour :", err);
    }
  };

  return (
    <div className="">
      <h1 className="text-poppins-h6 mb-5">Autres informations</h1>

      <form onSubmit={handleSubmit}>
        {/* Birthday */}
        <div className="mb-6">
          <h2 className="text-lg font-medium mb-3">Date de naissance</h2>
          <Input
            type="date"
            name="birthday"
            value={formData.birthday}
            onChange={handleChange}
            className="w-full"
          />
        </div>

        {/* Biographie */}
        <div className="mb-6">
          <h2 className="text-lg font-medium mb-3">Biographie</h2>
          <Input
            textArea
            name="biographie"
            value={formData.biographie}
            onChange={handleChange}
            placeholder="Écrivez une brève biographie..."
            className="w-full"
          />
        </div>

        {/* City */}
        <div className="mb-6">
          <h2 className="text-lg font-medium mb-3">Ville</h2>
          <Input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Entrez votre ville"
            className="w-full"
          />
        </div>

        {/* Submit Button */}
        <div className="w-full flex justify-center flex-col items-center gap-5">
          <Button
            type="submit"
            disabled={isLoading}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-400"
          >
            {isLoading ? "Mise à jour..." : "Mettre à jour"}
          </Button>

          {/* Feedback Messages */}
          {isLoading && <p className="text-blue-500 mt-4">Chargement...</p>}
          {error && <p className="text-red-500 mt-4">Erreur : {error}</p>}
          {successMessage && (
            <p className="text-green-500 mt-4">{successMessage}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default OtherInfo;
