import React from "react";
import Button from "../elements/Button/Button";
import CardExplainLabel from "./CardExplainLabel";
import globe from "../../assets/images/Icons/globe.svg";
import tagLabel from "../../assets/images/Icons/tagLabel.svg";
import checkGrid from "../../assets/images/Icons/checkGrid.svg";
import imageLabel from "../../assets/images/labels/ImageLabel.png";
import imageLabelConcept from "../../assets/images/Mission/LabelConcept.png";

const HeaderLabel = () => {
  const handleDownloadLabelGuide = () => {
    const link = document.createElement("a");
    link.href = "/guide-des-labels.pdf";
    link.download = "guide-des-labels.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col mt-6 mb-10">
      {/* Titre centré dans un container */}
      <div className="container mx-auto mb-16">
        <h1 className="text-roca-h4 text-left">
          Découvrez les labels les plus fiables et les plus actuels, reconnus
          dans le monde entier !
        </h1>
      </div>

      {/* Image principale */}
      <div className="w-full">
        <img
          src={imageLabel}
          alt="image_label_restaurant"
          className="w-full h-auto"
        />
      </div>

      {/* Cartes d'explication */}
      <div className="container mx-auto flex flex-col items-center mt-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-6xl place-items-center">
          <CardExplainLabel
            title={"Manger durable"}
            image={globe}
            text={
              "Découvrez un monde d'expériences gastronomiques durables avec Label Adresse - où chaque bouchée soutient des pratiques respectueuses de l'environnement et une planète plus saine."
            }
          />
          <CardExplainLabel
            title={"Certifié Éco"}
            image={tagLabel}
            text={
              "Rejoignez notre communauté de gourmets soucieux de l'environnement et découvrez des restaurants certifiés par des labels écologiques prestigieux, pour que chaque repas soit en accord avec vos valeurs."
            }
          />
          <CardExplainLabel
            title={"Labels fiables"}
            image={checkGrid}
            text={
              "Découvrez l'harmonie des saveurs et du développement durable - plongez dans notre sélection de restaurants éco-responsables, vérifiés par des écolabels de confiance, pour des expériences culinaires sans culpabilité auxquelles vous pouvez vous fier."
            }
          />
        </div>

        {/* Section Concept */}
        <div className="flex flex-col items-center bg-[#F4F1E9] py-12 px-6 mt-12">
          <div className="w-full max-w-6xl mb-12 text-center">
            <p className="text-poppins-small mb-2">Les labels</p>
            <h1 className="text-roca-h3">
              Explication du Concept de Label dans la Restauration
            </h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl">
            <div>
              <img
                src={imageLabelConcept}
                alt="Concept de Label"
                className="w-full h-auto"
              />
            </div>
            <div className="flex flex-col items-center bg-white rounded-20 shadow-xl p-6">
              <p className="text-poppins-small mb-5">
                Les labels dans la restauration jouent un rôle essentiel pour
                aider les consommateurs à identifier des établissements qui
                répondent à des critères spécifiques en termes de qualité, de
                responsabilité environnementale et de valeurs sociales. Ces
                labels sont attribués par des organismes de certification
                indépendants ou des institutions reconnues, et ils garantissent
                une transparence sur les pratiques des restaurants.
              </p>
              <p className="text-poppins-small mb-5">
                Avec <span className="font-semibold">LabelAdresse</span>, plus
                besoin de perdre du temps à chercher un restaurant qui partage
                vos valeurs. Nous vous permettons de découvrir des
                établissements qui :
              </p>
              <p className="text-poppins-small">
                Promeuvent des produits locaux et biologiques, favorisant une
                alimentation saine et responsable. S’engagent dans des pratiques
                durables, comme la réduction des déchets et l’utilisation
                d’énergies renouvelables. Offrent une expérience culinaire qui
                respecte à la fois l’environnement et les attentes des clients.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Section Commentée (peut être dé-commentée si nécessaire) */}
      {/*
      <div className="mt-5">
        <h2 className="text-roca-h6">C'est quoi un label ?</h2>
        <p className="text-poppins-paragraph">
          Un label est un signe d'identification, d'authentification ou de certification apposé sur un produit ou un service. Il est souvent utilisé pour garantir des caractéristiques spécifiques du produit ou du service, comme son origine, sa qualité ou sa durabilité.
        </p>
        <div className="w-full flex justify-center items-center mt-6 mb-6">
          <Button onClick={handleDownloadLabelGuide}>
            Télécharger notre guide des labels
          </Button>
        </div>
      </div>
      */}
    </div>
  );
};

export default HeaderLabel;
