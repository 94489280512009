import React, { useState } from "react";
import { motion } from "framer-motion";
import { sendFormContact } from "../../services/contact.services";
import Button from "../elements/Button/Button";

const FormContact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the error for the current field
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        await sendFormContact(formData);
        setIsSubmitted(true);
        setFormData({ name: "", email: "", message: "" });
      } catch (error) {
        console.error("Failed to send message:", error);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <motion.div
      className="container mx-auto flex justify-center items-center h-full mt-8"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div
        className="w-full max-w-lg bg-white   p-8 rounded-20 shadow-md   text-black
      "
      >
        <h2 className="text-roca-h5 font-semibold mb-6 text-center ">
          Contactez-nous
        </h2>
        {isSubmitted && (
          <motion.div
            className="text-poppins-small !text-green-600 text-center mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            Votre message a été envoyé avec succès !
          </motion.div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-poppins-small font-medium mb-2 "
            >
              Nom
            </label>
            <motion.input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`w-full p-3 rounded-lg border ${
                formErrors.name ? "border-red-500" : "border-gray-300"
              }  placeholder:text-poppins-extra-small placeholder:!text-gray-400`}
              placeholder="Votre nom"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            />
            {formErrors.name && (
              <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-poppins-small font-medium mb-2"
            >
              Email
            </label>
            <motion.input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full p-3 rounded-lg border ${
                formErrors.email ? "border-red-500" : "border-gray-300"
              }  placeholder:text-poppins-extra-small placeholder:!text-gray-400`}
              placeholder="Votre email"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            />
            {formErrors.email && (
              <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-poppins-small font-medium mb-2"
            >
              Message
            </label>
            <motion.textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className={`w-full p-3 rounded-lg border ${
                formErrors.message ? "border-red-500" : "border-gray-300"
              } placeholder:text-poppins-extra-small placeholder:!text-gray-400`}
              placeholder="Votre Message"
              rows="6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            ></motion.textarea>
            {formErrors.message && (
              <p className="text-red-500 text-sm mt-1">{formErrors.message}</p>
            )}
          </div>
          <div className="flex justify-center items-center">
            <Button type="submit">Envoyer le message</Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default FormContact;
