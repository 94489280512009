import { useNavigate } from "react-router-dom";

export const useNavigationService = () => {
  const navigate = useNavigate();

  const navigationToHome = () => {
    navigate("/");
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  const navigateToRegister = () => {
    navigate("/register");
  };

  const navigationToPage = (path) => {
    navigate(path);
  };

  return {
    navigationToHome,
    navigationToPage,
    navigateToLogin,
    navigateToRegister,
  };
};
