import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createLabelRequest = async (
  labelId,
  establishmentId,
  category
) => {
  const res = axios.post(
    `${API_URL}/label-requests/${labelId}/${establishmentId}`,
    category
  );
  if (!res) return;
  return res;
};

export const getLabelRequestById = async (labelRequestId) => {
  const res = axios.get(`${API_URL}/label-requests/${labelRequestId}`);
  if (!res) return;
  return res;
};

export const getLabelRequestsByEstablishment = async (establishmentId) => {
  const res = axios.get(
    `${API_URL}/label-requests/establishment/${establishmentId}`
  );
  if (!res) return;
  return (await res).data.data;
};

export const getLabelRequests = async () => {
  const res = await axios.get(`${API_URL}/label-requests`);
  if (!res) return;
  return res.data.labelRequests.labelRequests;
};

export const updateLabelRequestById = async (
  labelRequestId,
  labelRequestData
) => {
  const res = axios.patch(
    `${API_URL}/label-requests/${labelRequestId}`,
    labelRequestData
  );
  if (!res) return;
  return res;
};

export const deleteLabelRequestById = async (labelRequestId) => {
  const res = axios.delete(`${API_URL}/label-requests/${labelRequestId}`);
  if (!res) return;
  return res;
};

export const getLabelRequestsByGroup = async (groupId) => {
  const res = axios.get(`${API_URL}/label-requests/audit/${groupId}/requests`);
  if (!res) return;
  return res;
};

//auditor services actions

export const associateLabelRequestToAuditor = async (requestId, auditorId) => {
  const res = axios.post(
    `${API_URL}/label-requests/actions/${requestId}/associate-auditor`,
    { auditorId }
  );
  if (!res) return;
  return res;
};

export const addFeedBackToLabelRequest = async (requestId, feedBack) => {
  const res = axios.post(
    `${API_URL}/label-requests/actions/${requestId}/feedback`,
    feedBack
  );
  if (!res) return;
  return res;
};

export const addFeedBackOnDocuments = async (
  requestId,
  feedBack,
  documentId
) => {
  const res = axios.post(
    `${API_URL}/label-requests/actions/${requestId}/feedback/${documentId}`,
    feedBack
  );
  if (!res) return;
  return res;
};

export const changeStatusRequest = async (requestId, status) => {
  const res = axios.patch(
    `${API_URL}/label-requests/actions/${requestId}/status`,
    status
  );
  if (!res) return;
  return res;
};

// export const changeStatusDocument = async (documentId, status) => {
//   const res = axios.post(
//     `${API_URL}/label-requests/actions/document/${documentId}/status`,
//     status
//   );
//   if (!res) return;
//   return res;
// };

export const getLabelRequestsByAuditor = async (auditorId) => {
  const res = axios.get(
    `${API_URL}/label-requests/audit/requests/${auditorId}`
  );
  if (!res) return;
  return (await res).data.data;
};

export const getFormResponseOFRequest = async (labelDataId, label) => {
  const res = await axios.post(
    `${API_URL}/label-requests/audit/form-response/${labelDataId}`,
    {
      label: label,
    }
  );

  if (!res) return;
  return res;
};
