import React, { useState } from "react";
import useBackOffice from "../../hooks/BackOffice/useBackOffice";
import Button from "../../components/elements/Button/Button";
import { listeOfLabels } from "../../utils/Labels";
import { useLocation } from "react-router-dom";
import WhatIsTheAction from "../Owner/ActionsEstablishement/WhatIsTheAction";

const BackOfficeGroups = () => {
  const { groups, auditors, createGroup, updateGroup } = useBackOffice();
  const [showModal, setShowModal] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    label: "",
    auditors: [],
  });

  const location = useLocation();
  const action = location.state?.action;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: formData.name,
      label: formData.label,
      auditors: formData.auditors.map((a) => a._id),
    };

    if (editingGroup) {
      await updateGroup(editingGroup._id, data);
    } else {
      await createGroup(data);
    }
    handleCloseModal();
  };

  const handleOpenModal = (group = null) => {
    if (group) {
      setFormData({
        name: group.name,
        label: group.label,
        auditors: group.auditors || [],
      });
      setEditingGroup(group);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setFormData({ name: "", label: "", auditors: [] });
    setEditingGroup(null);
    setShowModal(false);
  };

  const toggleAuditor = (auditor) => {
    setFormData((prev) => ({
      ...prev,
      auditors: prev.auditors.some((a) => a._id === auditor._id)
        ? prev.auditors.filter((a) => a._id !== auditor._id)
        : [...prev.auditors, auditor],
    }));
  };


  return (
    <div className="p-6 max-w-7xl mx-auto bg-background-600">
      <div>
        <WhatIsTheAction actionIs={action} />
      </div>
      {/* Header */}
      <div className="flex justify-between items-center mb-8 bg-white p-6 rounded-15 shadow-sm">
        <div>
          <h1 className="text-primary-600 text-poppins-h4-5">
            Gestion des Groupes
          </h1>
          <p className="text-tertiary-500 text-poppins-small mt-2">
            Gérez vos groupes et assignez des auditeurs
          </p>
        </div>
        <Button
          onClick={() => handleOpenModal()}
          AddtionnalClasses="flex items-center gap-2 bg-secondary-600 hover:bg-secondary-700 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Nouveau groupe
        </Button>
      </div>

      {/* Grille de groupes */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {groups.map((group) => (
          <div
            key={group._id}
            className="bg-white rounded-15 shadow-sm hover:shadow-md transition-shadow duration-200"
          >
            <div className="p-6">
              <div className="flex justify-between items-start w-full ">
                <div>
                  <h2 className="text-primary-600 text-poppins-h6 mb-2">
                    {group.name}
                  </h2>
                  <h3 className="text-tertiary-500 text-poppins-small-semibold mb-3">
                    Label assigné
                  </h3>
                  <span className="inline-block px-3 py-1 bg-tertiary-200 text-tertiary-600 rounded-10 text-poppins-extra-small">
                    {group.label}
                  </span>
                </div>
                <button
                  onClick={() => handleOpenModal(group)}
                  className="text-tertiary-400 hover:text-secondary-600 transition-colors p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              </div>

              <div className="mt-6">
                <h3 className="text-tertiary-500 text-poppins-small-semibold mb-3">
                  Auditeurs assignés
                </h3>
                <div className="flex flex-wrap gap-2">
                  {group.auditors?.map((auditor) => (
                    <span
                      key={auditor._id}
                      className="inline-flex items-center px-3 py-1.5 rounded-10 text-poppins-extra-small
                               bg-primary-300 text-primary-600"
                    >
                      {auditor.firstname} {auditor.lastname}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-primary-900 bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-20 shadow-xl w-full max-w-md">
            <div className="px-6 py-4 border-b border-tertiary-300">
              <h2 className="text-primary-600 text-poppins-h6">
                {editingGroup ? "Modifier le groupe" : "Nouveau groupe"}
              </h2>
            </div>

            <form onSubmit={handleSubmit} className="p-6">
              <div className="space-y-6">
                <div>
                  <label className="block text-poppins-small text-primary-600 mb-2">
                    Nom du groupe
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    className="w-full px-4 py-2 border border-tertiary-300 rounded-10 
                             focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400
                             text-poppins-small text-primary-600"
                    placeholder="Entrez le nom du groupe"
                  />
                </div>

                <div>
                  <label className="block text-poppins-small text-primary-600 mb-2">
                    Label
                  </label>
                  <select
                    value={formData.label}
                    onChange={(e) =>
                      setFormData({ ...formData, label: e.target.value })
                    }
                    className="w-full px-4 py-2 border border-tertiary-300 rounded-10
                             focus:ring-2 focus:ring-secondary-400 focus:border-secondary-400
                             text-poppins-small text-primary-600"
                  >
                    <option value="">Sélectionner un label</option>
                    {listeOfLabels.map((label) => (
                      <option key={label} value={label}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-poppins-small text-primary-600 mb-2">
                    Auditeurs
                  </label>
                  <div className="p-4 border border-tertiary-300 rounded-10 bg-background-600 max-h-48 overflow-y-auto">
                    <div className="flex flex-wrap gap-2">
                      {auditors.map((auditor) => (
                        <button
                          type="button"
                          key={auditor._id}
                          onClick={() => toggleAuditor(auditor)}
                          className={`px-3 py-1.5 rounded-10 text-poppins-extra-small transition-all duration-200
                            ${
                              formData.auditors.some(
                                (a) => a._id === auditor._id
                              )
                                ? "bg-secondary-600 text-white hover:bg-secondary-700"
                                : "bg-tertiary-300 text-tertiary-600 hover:bg-tertiary-400"
                            }`}
                        >
                          {auditor.firstname} {auditor.lastname}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-3 mt-8">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="px-4 py-2 border border-tertiary-300 rounded-10 text-tertiary-600
                           hover:bg-tertiary-300 transition-all duration-200 text-poppins-small"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-secondary-600 text-white rounded-10 
                           hover:bg-secondary-700 transition-all duration-200 text-poppins-small"
                >
                  {editingGroup ? "Modifier" : "Créer"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BackOfficeGroups;
