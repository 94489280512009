import React, { useEffect, useState } from "react";
import Card from "./Card";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/Provider/AuthProvider";
import { getEstablishmentsByOwner } from "../../../services/establishements.services";
import CardEstablishment from "./CardEstablishment";
import { getUserById } from "../../../services/user.services";

const MyEstablishement = () => {
  const [establishments, setEstablishments] = useState([]);
  const [dataUser, setDataUser] = useState({});
  const navigate = useNavigate();

 

  const handlePassTest = () => {
    navigate("/label-test");
  };

  const { user } = useAuth();

  const handleAddEstablishment = () => {
    navigate("/add-establishment");
  };

  useEffect(() => {
    const fetchMyEstablishments = async () => {
      const res = await getEstablishmentsByOwner(user.id);
      if (!res) return;
      const fetchedEstablishments = res.establishments || [];
      setEstablishments(fetchedEstablishments);
    };
    const getDetailsUser = async () => {
      const _user = await getUserById(user.id);
      setDataUser(_user);
    };
    getDetailsUser();
    fetchMyEstablishments();
  }, [user.id, navigate]);

  return (
    <div className="container mx-auto flex flex-col justify-center gap-5 items-center bg-white mt-16 rounded-20 shadow-xl">
      <h1 className="text-roca-h4-5 !text-secondary-600 mt-8">
        Mes Restaurants
      </h1>
      <div>
        {!dataUser.isVerifiedManualy && (
          <div className="bg-red-500 text-white p-2 rounded-md">
            Votre compte est en attente de validation
          </div>
        )}
      </div>
      <div className="flex flex-col w-full gap-5 p-8">
        {establishments.map((establishment) => (
          <CardEstablishment
            disabled={
              !dataUser.isVerifiedManualy ||
              establishment.isWaitingForValidation
            }
            disabledMessage={
              !dataUser.isVerifiedManualy
                ? "Votre compte est en attente de validation"
                : establishment.isWaitingForValidation
                ? "En attente de validation"
                : ""
            }
            key={establishment._id}
            establishment={establishment}
          />
        ))}
        <div className="columns-2 w-full mt-8 ">
          <Card
            onClick={handlePassTest}
            isTest={true}
            isDisabled={false}
            additionalClassname={"w-full"}
          />
          <Card
            isDisabled={!dataUser.isVerifiedManualy ? true : false}
            onClick={handleAddEstablishment}
            isAddEstablishment={true}
            additionalClassname={"w-full"}
          />
        </div>
      </div>
    </div>
  );
};

export default MyEstablishement;
