import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faPaperPlane,
  faSpinner,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import {
  getFileVersions,
  createNewVersion,
  getLatestVersion,
} from "../../../../services/files.services";
import useFiles from "../../../../hooks/useFiles/useFiles";
import FileVersions from "../FollowRequest/FileVersions";
import VersionHistoryModal from "../FollowRequest/VersionHistoryModal";
import Status from "../FollowRequest/Status";
import PreviewOfFile from "../FollowRequest/PreviewOfFile";

const ShowSelectedFiles = ({
  fileIds = [],
  title = [],
  isAuditor = false,
  onStatusChange,
  onFeedbackSubmit,
}) => {
  const { establishmentId } = useParams();
  const { getFileById, handleDownloadFile, handleViewFile } = useFiles({
    establishmentId,
  });

  // États locaux
  const [filesWithTitles, setFilesWithTitles] = useState([]);
  const [feedbacks, setFeedbacks] = useState({});
  const [isSubmitting, setIsSubmitting] = useState({});
  const [showVersionsModal, setShowVersionsModal] = useState(false);
  const [selectedFileVersions, setSelectedFileVersions] = useState([]);
  const [uploadingVersion, setUploadingVersion] = useState(null);

  const statusOptions = [
    { value: "pending", label: "En attente" },
    { value: "in_review", label: "En cours de révision" },
    { value: "approved", label: "Approuvé" },
    { value: "rejected", label: "Rejeté" },
    { value: "needs_more_info", label: "Plus d'informations requises" },
  ];

  const getStatusColor = (status) => {
    const colors = {
      pending: "bg-yellow-100 text-yellow-800",
      in_review: "bg-blue-100 text-blue-800",
      approved: "bg-green-100 text-green-800",
      rejected: "bg-red-100 text-red-800",
      needs_more_info: "bg-orange-100 text-orange-800",
    };
    return colors[status] || "bg-gray-100 text-gray-800";
  };

  // Récupération des fichiers
  const fetchFiles = useCallback(async () => {
    if (!fileIds || fileIds.length === 0) return;

    try {
      // On s'assure que fileIds et title sont des tableaux
      const idsArray = Array.isArray(fileIds) ? fileIds : [fileIds];
      const titleArray = Array.isArray(title) ? title : [title];

      const fetchedFiles = await Promise.all(
        idsArray.map(async (id, index) => {
          const fileData = await getFileById(id);
          // Si ce n'est pas la dernière version, récupérer la version la plus récente
          if (!fileData.isLatestVersion) {
            const latestVersion = await getLatestVersion(id);
            return {
              ...latestVersion,
              customTitle: titleArray[index] || "Document",
            };
          }
          return {
            ...fileData,
            customTitle: titleArray[index] || "Document",
          };
        })
      );
      setFilesWithTitles(fetchedFiles.filter(Boolean));
    } catch (error) {
      console.error("Erreur lors de la récupération des fichiers :", error);
    }
  }, [fileIds, title, getFileById]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  // Gestion de l'upload d'une nouvelle version
  const handleVersionUpload = async (fileId, file) => {
    try {
      setUploadingVersion(fileId);
      const formData = new FormData();
      formData.append("file", file);

      const newVersion = await createNewVersion(fileId, formData);

      setFilesWithTitles((prevFiles) =>
        prevFiles.map((f) =>
          f._id === fileId ? { ...newVersion, customTitle: f.customTitle } : f
        )
      );

      // Optionnel : rafraîchir la liste complète
      await fetchFiles();
    } catch (error) {
      console.error("Error uploading new version:", error);
    } finally {
      setUploadingVersion(null);
    }
  };

  // Affichage de l'historique des versions
  const handleShowVersionHistory = async (fileId) => {
    try {
      const versions = await getFileVersions(fileId);
      setSelectedFileVersions(versions);
      setShowVersionsModal(true);
    } catch (error) {
      console.error("Error fetching versions:", error);
    }
  };

  // Gestion du feedback
  const handleSubmitFeedback = async (fileId) => {
    try {
      setIsSubmitting((prev) => ({ ...prev, [fileId]: true }));
      await onFeedbackSubmit(fileId, feedbacks[fileId]);
      setFeedbacks((prev) => ({ ...prev, [fileId]: "" }));
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setIsSubmitting((prev) => ({ ...prev, [fileId]: false }));
    }
  };

  // Actions sur chaque fichier (téléchargement, visualisation, historique, etc.)
  const renderFileActions = (file) => (
    <div className="flex items-center gap-3">
      <button
        onClick={() => handleDownloadFile(file.fileUrl, file.filename)}
        className="text-gray-600 hover:text-gray-900 rounded-full p-2 hover:bg-gray-100 transition-colors"
        title="Télécharger"
      >
        <FontAwesomeIcon icon={faDownload} />
      </button>
      <button
        onClick={() => handleViewFile(file._id)}
        className="text-gray-600 hover:text-gray-900 rounded-full p-2 hover:bg-gray-100 transition-colors"
        title="Visualiser"
      >
        <FontAwesomeIcon icon={faEye} />
      </button>
      <button
        onClick={() => handleShowVersionHistory(file._id)}
        className="text-gray-600 hover:text-gray-900 rounded-full p-2 hover:bg-gray-100 transition-colors"
        title="Historique des versions"
      >
        <FontAwesomeIcon icon={faHistory} />
      </button>
      <Status status={file.status} label={false} />
    </div>
  );

  return (
    <div className="w-full space-y-4 pt-4">
      {filesWithTitles.length > 0 ? (
        filesWithTitles.map((file) => (
          <div key={file._id} className="space-y-2">
            <h3 className="text-poppins-small !text-secondary-600">
              {file.customTitle}
            </h3>
            <div className="bg-white shadow-sm border border-gray-200 rounded-lg p-4">
              <div className="flex items-start gap-6">
                <div className="flex-shrink-0">
                  <PreviewOfFile file={file}  />
                </div>
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <div className="flex flex-row items-center gap-4 text-sm">
                      <p className="text-gray-500">
                        {(file.size / 1024).toFixed(2)} KB
                      </p>
                      <p className="text-gray-500">
                        {new Date(file.createdAt).toLocaleDateString("fr-FR")}
                      </p>
                      {file.versionNumber > 1 && (
                        <span className="text-blue-600 text-sm">
                          Version {file.versionNumber}
                        </span>
                      )}
                    </div>
                    {renderFileActions(file)}
                  </div>
                  <p className="font-medium">{file.filename}</p>
                </div>
              </div>

              {isAuditor && (
                <div className="mt-4 space-y-4">
                  <div className="flex gap-4 items-start">
                    <select
                      value={file.status}
                      onChange={(e) => onStatusChange(file._id, e.target.value)}
                      className={`px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500 ${getStatusColor(
                        file.status
                      )}`}
                    >
                      {statusOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="flex-1">
                      <textarea
                        value={feedbacks[file._id] || ""}
                        onChange={(e) =>
                          setFeedbacks((prev) => ({
                            ...prev,
                            [file._id]: e.target.value,
                          }))
                        }
                        placeholder="Ajouter un commentaire..."
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        rows="3"
                      />
                      {feedbacks[file._id] && (
                        <button
                          onClick={() => handleSubmitFeedback(file._id)}
                          disabled={isSubmitting[file._id]}
                          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center gap-2"
                        >
                          {isSubmitting[file._id] ? (
                            <>
                              <FontAwesomeIcon
                                icon={faSpinner}
                                className="animate-spin"
                              />
                              Envoi...
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faPaperPlane} />
                              Envoyer le feedback
                            </>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {!isAuditor &&
                (file.status === "rejected" ||
                  file.status === "needs_more_info") && (
                  <FileVersions
                    file={file}
                    onUploadVersion={(selectedFile) =>
                      handleVersionUpload(file._id, selectedFile)
                    }
                    onShowHistory={() => handleShowVersionHistory(file._id)}
                    isUploading={uploadingVersion === file._id}
                  />
                )}

              {file.auditorFeedback && file.auditorFeedback.length > 0 && (
                <div className="mt-4 p-3 bg-gray-50 rounded-lg">
                  <h4 className="font-medium mb-2">Historique des retours :</h4>
                  {file.auditorFeedback.map((feedback, index) => (
                    <div key={index} className="text-sm mb-2">
                      <div className="text-gray-600">
                        {new Date(feedback.date).toLocaleDateString("fr-FR")}
                      </div>
                      <div className="mt-1">{feedback.feedback}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-500 italic">Aucun fichier à afficher.</p>
      )}

      <VersionHistoryModal
        isOpen={showVersionsModal}
        onClose={() => setShowVersionsModal(false)}
        versions={selectedFileVersions}
        onDownload={handleDownloadFile}
        onView={handleViewFile}
      />
    </div>
  );
};

export default ShowSelectedFiles;
