import React from "react";
import ProfessionalRegistrationForm from "./ProfessionalRegistrationForm";
import loginImage from "../../../assets/images/Login/login3.png";

const RegisterPro = () => {
  return (
    <div className="w-full h-screen flex flex-col md:flex-row overflow-hidden">
      {/* Conteneur de l'image : masqué sur mobile, visible à partir de md */}
      <div className="hidden md:block md:w-[684px] p-4">
        <img
          src={loginImage}
          alt="Illustration de connexion"
          className="h-full w-[684px]"
        />
      </div>

      {/* Conteneur du formulaire */}
      <div className="flex-1 flex items-center justify-center p-4">
        <ProfessionalRegistrationForm />
      </div>
    </div>
  );
};

export default RegisterPro;
