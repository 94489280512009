import React from "react";
import Button from "../elements/Button/Button";

export default function QuestionCard({
  q,
  currentIndex,
  questionsData,
  userAnswer,
  handleAnswerChange,
  handleNext,
}) {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-roca-h4-5 mb-6 text-center">Questionnaire</h1>

      {/* 
        Carte "fixe" : min-h-[300px] pour une hauteur minimale,
        flex et flex-col pour séparer le contenu (questions) du bouton en bas.
      */}
      <div className="bg-white rounded-lg shadow p-6 min-h-[300px] flex flex-col justify-between">
        <div>
          <h2 className="text-xl font-semibold mb-4 text-roca-h6 !text-secondary-600">
            {q.category}
          </h2>
          <p className="font-medium mb-2 text-poppins-small">{q.question}</p>

          <div className="space-y-2 text-poppins-small">
            {q.answers.map((ans, ansIndex) => (
              <label key={ansIndex} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name={`q-${q.id}`}
                  className="form-radio"
                  checked={userAnswer === ansIndex}
                  onChange={() => handleAnswerChange(currentIndex, ansIndex)}
                />
                <span>{ans}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Bouton Suivant / Soumettre placé en bas */}
        <div className="mt-6 text-right">
          <Button onClick={handleNext} colorType="primary">
            {currentIndex === questionsData.length - 1 ? "Soumettre" : "Suivant"}
          </Button>
        </div>
      </div>

      {/* Progression */}
      <p className="text-poppins-extra-small text-sm !text-gray-500 mt-4">
        Question {currentIndex + 1} / {questionsData.length}
      </p>
    </div>
  );
}
