import React from "react";
import CardBackOffice from "./CardBackOffice";

const BackOffice = () => {
  return (
    <div className="flex justify-center items-center gap-8 mt-8 ">
      <CardBackOffice
        label="Gestion FAQ"
        navToLink="faq"
        icon="faTags"
        action={"faq"}
      />
      <CardBackOffice
        label="Gestion Groupe"
        navToLink="groups"
        icon="faTags"
        action={"group"}
      />
      <CardBackOffice
        label="Gestion des auditeurs"
        navToLink="auditors"
        icon="faTags"
        action={"auditor"}
      />
      <CardBackOffice
        label="Gestion des demandes de labels"
        navToLink="requests"
        icon="faTags"
        action={"label"}
      />
      <CardBackOffice
        label={"Gestion des become owner"}
        navToLink="manage-become-owner"
        icon="faTags"
        action={"become-owner"}
      ></CardBackOffice>
      <CardBackOffice
        label="Associer un user a un restaurant"
        navToLink="add-user-restaurant"
        icon="faTags"
        action={"label"}
      />
    </div>
  );
};

export default BackOffice;
