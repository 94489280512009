import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
const Input = ({
  required = false,
  type = "text",
  placeholder = "",
  isShowPassword = false,
  label = "",
  value,
  onChange,
  onBlur,
  name,
  hasError = false,
  success = false,
  disabled = false,
  icon = null,
  textArea = false, // Ajout de la prop textArea
  isFiles = false, // Ajout de la prop isFiles
  onFilesChange, // Fonction pour gérer les fichiers sélectionnés
  min = null,
  max = null,
  additionalClasses = "", // Ajout de la prop additionalClasses
  pattern = null,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [files, setFiles] = useState([]); // État pour les fichiers
  const [selectedFile, setSelectedFile] = useState(null); // État pour l'image en grand format

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*", // Accepter uniquement les fichiers image
    onDrop: (acceptedFiles) => {
      const filePreviews = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file), // Crée une URL pour l'aperçu
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...filePreviews]); // Ajoute les nouvelles images sans supprimer les anciennes
      if (onFilesChange) {
        onFilesChange([...files, ...filePreviews]);
      }
    },
  });

  const baseClasses =
    "w-full px-4 py-2 border focus:outline-none text-primary-600 ";
  const stateClasses = hasError
    ? "border-red-500 text-red-500"
    : success
    ? "border-green-500 text-green-500"
    : "border-gray-300 text-gray-900";
  const disabledClasses = disabled
    ? "bg-gray-200 cursor-not-allowed"
    : "bg-white";

  return (
    <div
      className={`flex flex-col items-start w-full mb-4 ${additionalClasses}`}
    >
      {label && (
        <label
          htmlFor={name}
          className="block text-gray-700 mb-2 text-poppins-small"
        >
          {label}
        </label>
      )}

      {isFiles ? (
        <>
          <div
            {...getRootProps()}
            className={`w-full p-6 border-dashed border-2 border-primary-600 rounded-lg cursor-pointer flex justify-center flex-col gap-5 items-center ${stateClasses} ${disabledClasses} ${additionalClasses}`}
          >
            <input {...getInputProps()} />
            <p className="text-poppins-small text-center text-gray-500">
              Choisissez des images de votre restaurant
            </p>
            <button className="bg-secondary-600 text-poppins-small bg-opacity-10 border border-secondary-600 w-16 h-9 rounded-8">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {files.length > 0 && (
            <div
              className={`mt-4 h-32 w-full flex gap-3 p-3 flex-row overflow-x-auto bg-gray-200 rounded-lg ${additionalClasses}`}
            >
              {files.map((file, index) => (
                <div key={index} className="relative min-w-[96px]">
                  <img
                    src={file.preview}
                    alt="Aperçu"
                    className={`w-24 h-24 object-cover rounded-lg cursor-pointer bg-white ${additionalClasses}`}
                    onClick={() => setSelectedFile(file)}
                  />
                  <button
                    onClick={() =>
                      setFiles(files.filter((_, i) => i !== index))
                    }
                    className="absolute w-6 h-6 bg-secondary-600 text-white rounded-full flex items-center justify-center top-1 right-1"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </>
      ) : textArea ? (
        <textarea
          required={required}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          className={`w-full h-24 p-2.5 border placeholder:opacity-65 border-primary-600 outline-none ${stateClasses} ${disabledClasses} rounded-lg placeholder:text-poppins-extra-small ${additionalClasses}`}
        />
      ) : (
        <div
          className={`w-full flex items-center border border-primary-600 rounded-full px-3 h-[40px] ${disabledClasses} ${additionalClasses}`}
        >
          {icon && <FontAwesomeIcon icon={icon} className="text-black mr-2" />}
          <input
            pattern={pattern}
            min={min}
            max={max}
            required={required}
            type={isShowPassword ? (showPassword ? "text" : "password") : type}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            className={`w-full h-full p-2.5 outline-none placeholder:opacity-65 ${stateClasses} ${disabledClasses} rounded-full placeholder:text-poppins-extra-small ${additionalClasses}`}
          />
          {isShowPassword && (
            <button
              type="button"
              className="ml-3 p-2"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
