// Payement.jsx
import React, { useState } from "react";
import SubscriptionCard from "./SubscriptionCard";
import CustomPlanCard from "./CustomPlanCard";
import Button from "../elements/Button/Button";
import { useForm, ValidationError } from "@formspree/react";
import { listeOfLabels } from "../../utils/Labels";

const Payement = () => {
  // Récupération des prix depuis les variables d'environnement
  const priceCall = parseInt(process.env.REACT_APP_PRICE_CALL) || 70;
  const pricePack = parseInt(process.env.REACT_APP_PRICE_PACK) || 1570;

  // État pour la gestion de la modal et du formulaire
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customName, setCustomName] = useState("");
  const [customEmail, setCustomEmail] = useState("");
  const [customLabel, setCustomLabel] = useState("");

  // Hook Formspree avec votre ID de formulaire (exemple "xvgkwbzq")
  const [state, handleSubmit] = useForm("xvgkwbzq");

  const handleCustomCardClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Si le formulaire est soumis avec succès, on peut afficher un message de remerciement.
  const renderFormContent = () => {
    if (state.succeeded) {
      return (
        <p className="text-center text-green-600">Merci pour votre demande !</p>
      );
    }
    return (
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="customName" className="block font-medium">
            Nom
          </label>
          <input
            id="customName"
            type="text"
            name="customName"
            value={customName}
            onChange={(e) => setCustomName(e.target.value)}
            className="mt-1 p-2 border rounded w-full"
            required
          />
          <ValidationError
            prefix="Nom"
            field="customName"
            errors={state.errors}
          />
        </div>
        <div>
          <label htmlFor="customEmail" className="block font-medium">
            Email
          </label>
          <input
            id="customEmail"
            type="email"
            name="customEmail"
            value={customEmail}
            onChange={(e) => setCustomEmail(e.target.value)}
            className="mt-1 p-2 border rounded w-full"
            required
          />
          <ValidationError
            prefix="Email"
            field="customEmail"
            errors={state.errors}
          />
        </div>
        <div>
          <label htmlFor="customLabel" className="block font-medium">
            Label
          </label>
          <select
            id="customLabel"
            name="customLabel"
            value={customLabel}
            onChange={(e) => setCustomLabel(e.target.value)}
            className="mt-1 p-2 border rounded w-full"
            required
          >
            <option value="">Sélectionnez un label</option>
            {listeOfLabels.map((label) => (
              <option key={label} value={label}>
                {label.charAt(0).toUpperCase() +
                  label.slice(1).replace(/_/g, " ")}
              </option>
            ))}
          </select>
          <ValidationError
            prefix="Label"
            field="customLabel"
            errors={state.errors}
          />
        </div>
        <div className="flex flex-col sm:flex-row justify-end mt-6 space-y-2 sm:space-y-0 sm:space-x-3">
          <Button
            type="button"
            onClick={closeModal}
            className="w-full sm:w-auto"
          >
            Annuler
          </Button>
          <Button
            type="submit"
            disabled={state.submitting}
            className="w-full sm:w-auto"
          >
            Envoyer
          </Button>
        </div>
      </form>
    );
  };

  const optionsCall = [
    "Bilan complet de votre situation actuelle",
    "Un échange avec un auditeur-conseil",
    "Conseils pratiques adaptés à votre contexte",
  ];

  const optionsPack = [
    "Bilan complet",
    "Entretien avec un auditeur-conseil",
    "Audit d'un label de votre choix",
  ];

  const optionsCustom = [
    "Bilan complet",
    "Entretien avec un auditeur-conseil",
    "Audit d'un label de votre choix",
    "Accompagnement jusqu'à certification",
  ];

  return (
    <div className="flex flex-col  items-center justify-center  p-4 space-y-6">
      <div className="text-center mb-4 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">
          Nos solutions d'accompagnement
        </h1>
        <p className="text-gray-600 mt-2">
          Choisissez l'offre adaptée à vos besoins
        </p>
      </div>
      <div className="flex flex-col lg:flex-row gap-6 w-full max-w-6xl">
        {/* Carte "Bilan & Appel" */}
        <div className="w-full lg:w-1/3 flex">
          <SubscriptionCard
            title="Bilan & Appel"
            price={priceCall}
            options={optionsCall}
            plan="price_call"
          />
        </div>

        {/* Carte "Pack Évaluation & Audit" */}
        <div className="w-full lg:w-1/3 lg:scale-105 z-10 flex">
          <SubscriptionCard
            title="Pack Évaluation & Audit"
            price={pricePack}
            options={optionsPack}
            isPrimary={true}
            plan="price_pack_audit"
          />
        </div>

        {/* Carte "Programme Complet de Certification" */}
        <div className="w-full lg:w-1/3 flex">
          <CustomPlanCard
            title="Programme Complet de Certification"
            priceRange="De 1620€ à 3000€"
            options={optionsCustom}
            onClickHandler={handleCustomCardClick}
          />
        </div>
      </div>

      {/* Modal pour le formulaire via Formspree */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
          <div className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                Demande de devis personnalisé
              </h2>
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            {renderFormContent()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Payement;
