    import React from "react";
    import Qrcode from "../../assets/images/Qrcode/qrcode.png";
    import appImage from "../../assets/images/MobilePres/ImageApp.png";
    import Button from "../elements/Button/Button";

    const DownloadApp = () => {
    return (
        <div className="flex flex-col items-center bg-[#F4F1E9] py-12 px-6 ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl place-items-center h-full">
            {/* Colonne de texte */}
            <div className="flex flex-col items-center text-center md:items-start md:text-left">
            <h1 className="text-roca-h4-5">
                Téléchargez notre application mobile
            </h1>
            <p className="text-poppins-paragraph mt-4">
                Scannez le code QR pour télécharger l'application et plongez dans le
                monde de la restauration vraiment responsable !
            </p>
            <div className="hidden md:block mt-6  justify-center items-center">
                <img src={Qrcode} alt="QR Code" className="w-1/2 mx-auto md:mx-0" />
            </div>
            <div className="block md:hidden mt-6  justify-center items-center">
                <Button type={"primary"} className="mt-6">
                Télécharger
                </Button>
            </div>
            </div>

            {/* Colonne de l'image de l'application */}
            <div className="w-full flex items-center justify-center">
            <img
                src={appImage}
                alt="Aperçu de l'application"
                className="max-w-full h-auto"
            />
            </div>
        </div>
        </div>
    );
    };

    export default DownloadApp;
