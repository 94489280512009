import React from "react";

const TermsAndConditions = () => {
  return (
    <div className=" bg-senary-600 py-10 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-20 p-8 shadow-md">
        {/* Titre principal */}
        <h1 className="text-roca-h3 font-bold mb-2">Conditions Générales</h1>
        <p className="text-poppins-small text-gray-600 mb-6">
          Dernière mise à jour&nbsp;: 01/03/2025
        </p>

        {/* 1. Objet */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">1. Objet</h2>
        <p className="text-poppins-paragraph mb-4">
          Les présentes Conditions Générales (ci-après « CG ») ont pour objet de
          définir les modalités et conditions d’utilisation et, le cas échéant,
          de vente, du site et des services proposés par LabelAdresse (ci-après
          « nous » ou « notre organisation »). Toute utilisation du site
          implique l’acceptation pleine et entière des présentes CG.
        </p>

        {/* 2. Mentions légales */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          2. Mentions légales
        </h2>
        <p className="text-poppins-paragraph mb-4">
          <strong>Nom de l’organisation&nbsp;:</strong> LabelAdresse
          <br />
          <strong>Contact&nbsp;:</strong>{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
          <br />
          Le site est accessible à l’adresse&nbsp;:{" "}
          <a
            href="https://www.labeladresse.fr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-600 underline"
          >
            www.labeladresse.fr
          </a>
        </p>

        {/* 3. Services proposés */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          3. Services proposés
        </h2>
        <p className="text-poppins-paragraph mb-4">
          LabelAdresse propose une plateforme de labellisation pour les
          restaurateurs et établissements. Les utilisateurs (restaurateurs,
          auditeurs, etc.) peuvent créer un compte, fournir des documents
          justificatifs (factures, rapports, etc.) et déposer une demande de
          label. Certains services peuvent être gratuits ou payants, selon la
          formule choisie.
        </p>

        {/* 4. Inscription et compte utilisateur */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          4. Inscription et compte utilisateur
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Pour accéder à certaines fonctionnalités, l’utilisateur doit créer un
          compte en fournissant des informations exactes et à jour. LabelAdresse
          se réserve le droit de suspendre ou de supprimer tout compte dont les
          informations seraient inexactes, incomplètes ou contraires aux
          présentes CG.
        </p>

        {/* 5. Obligations de l'utilisateur */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          5. Obligations de l’utilisateur
        </h2>
        <p className="text-poppins-paragraph mb-4">
          L’utilisateur s’engage à :
        </p>
        <ul className="list-disc list-inside mb-4 text-poppins-paragraph">
          <li>
            Fournir des informations véridiques lors de l’inscription et de la
            demande de label.
          </li>
          <li>
            Respecter la législation en vigueur et les droits des tiers
            (notamment en matière de propriété intellectuelle).
          </li>
          <li>
            Ne pas perturber le fonctionnement du site ou tenter d’y accéder de
            manière non autorisée.
          </li>
        </ul>

        {/* 6. Tarifs et paiements (si applicable) */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          6. Tarifs et paiements (le cas échéant)
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Certains services de LabelAdresse (par exemple, les formules
          d’abonnement pour la labellisation) peuvent être payants. Les tarifs
          sont indiqués sur le site ou lors de la souscription. Le paiement se
          fait via des prestataires sécurisés (ex. : Stripe). Toute réclamation
          relative à la facturation doit être adressée à l’adresse e-mail de
          contact.
        </p>

        {/* 7. Limitation de responsabilité */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          7. Limitation de responsabilité
        </h2>
        <p className="text-poppins-paragraph mb-4">
          LabelAdresse met en œuvre les moyens raisonnables pour assurer le bon
          fonctionnement du site et la fiabilité des informations qui y sont
          publiées. Toutefois, nous ne saurions être tenus responsables de
          dommages directs ou indirects résultant de l’utilisation du site,
          notamment en cas de :
        </p>
        <ul className="list-disc list-inside mb-4 text-poppins-paragraph">
          <li>
            Défaillance technique, indisponibilité ou interruption du site ;
          </li>
          <li>Perte de données, intrusions non autorisées ou virus ;</li>
          <li>
            Contenu, exactitude ou fiabilité des informations fournies par les
            utilisateurs.
          </li>
        </ul>

        {/* 8. Propriété intellectuelle */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          8. Propriété intellectuelle
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Les marques, logos, graphismes, photographies, animations, vidéos et
          textes contenus sur le site sont la propriété intellectuelle de
          LabelAdresse ou de ses partenaires et ne peuvent être reproduits,
          utilisés ou représentés sans autorisation expresse.
        </p>

        {/* 9. Résiliation */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">9. Résiliation</h2>
        <p className="text-poppins-paragraph mb-4">
          L’utilisateur peut résilier son compte à tout moment en nous
          contactant. LabelAdresse se réserve le droit de résilier ou de
          suspendre un compte en cas de non-respect des présentes CG, sans
          préavis ni indemnité.
        </p>

        {/* 10. Loi applicable et juridiction compétente */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          10. Loi applicable et juridiction compétente
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Les présentes CG sont régies par le droit français. En cas de litige,
          les tribunaux français sont seuls compétents, sauf disposition légale
          contraire.
        </p>

        {/* 11. Modification des CG */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">
          11. Modification des Conditions Générales
        </h2>
        <p className="text-poppins-paragraph mb-4">
          Nous nous réservons le droit de modifier à tout moment les présentes
          CG afin de les adapter aux évolutions légales, techniques ou
          commerciales. La version en vigueur est celle publiée sur le site,
          avec une date de mise à jour.
        </p>

        {/* 12. Contact */}
        <h2 className="text-roca-h5 font-bold mt-6 mb-2">12. Contact</h2>
        <p className="text-poppins-paragraph mb-4">
          Pour toute question relative aux présentes Conditions Générales, vous
          pouvez nous écrire à l’adresse&nbsp;:{" "}
          <a
            href="mailto:contact.labeladresse@gmail.com"
            className="text-primary-600 underline"
          >
            contact.labeladresse@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
