import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../elements/Button/Button";
import NavBarLogo from "../../assets/images/Logo/Logo_secondaire_horizontal.png";
import { useNavigationService } from "../../services/navigation/navigation.services";
import { useAuth } from "../Auth/Provider/AuthProvider";
import Avatar from "./Avatar";
import { getUserById } from "../../services/user.services";
import { ResendCode, VerifyCodeRegister } from "../../services/auth.services";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userInfos, setUserInfos] = useState({});
  const { navigateToLogin } = useNavigationService();
  const { user } = useAuth();
  const roleUser = user?.role;
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigateToBecomeOwner = () => {
    navigate("/become-owner");
  };

  const goToDownlaodApp = () => {
    navigate("/download-app");
  };

  const goToHome = () => {
    navigate("/");
  };

  // Récupération des infos utilisateur
  useEffect(() => {
    if (user?.id) {
      getUserById(user.id).then((_user) => {
        setUserInfos(_user);
      });
    }
  }, [user]);

  // Fonction qui lance la vérification du compte avec popup SweetAlert
  const handleVerifyAccount = async () => {
    // Envoi du code via l'API, afin de s'assurer qu'un email de vérification est envoyé
    await ResendCode(userInfos.email);

    const { value: code } = await Swal.fire({
      title: "Vérification du compte, un code vous a été envoyé par email",
      input: "text",
      inputLabel: "Entrez le code de vérification",
      inputPlaceholder: "Code",
      showCancelButton: true,
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
    });

    if (code) {
      try {
        const response = await VerifyCodeRegister(userInfos.email, code);
        const isSuccess = response?.data.isVerified;

        if (isSuccess) {
          Swal.fire({
            title: "Succès",
            text: "Votre compte est vérifié.",
            icon: "success",
            confirmButtonText: "OK",
          });
          setUserInfos({ ...userInfos, isVerified: true });
        } else {
          Swal.fire({
            title: "Erreur",
            text:
              response?.message ||
              response?.data?.message ||
              "Le code est incorrect",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Erreur",
          text: error.message || "Une erreur est survenue",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const menuLinks = {
    auditor: [
      { to: "/", label: "Accueil" },
      { to: "/my-requests", label: "Mes Demandes" },
      { to: "/all-requests", label: "Toutes les Demandes" },
    ],
    owner: [
      { to: "/", label: "Accueil" },
      { to: "/my-establishements", label: "Mes Restaurants" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts-pro", label: "Contacts" },
      { to: "/payement", label: "Tarifs" },
    ],
    admin: [{ to: "/backoffice", label: "BackOffice" }],
    user: [
      { to: "/", label: "Accueil" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts", label: "Contacts" },
      { to: "/payement", label: "Tarifs" },
    ],
    default: [
      { to: "/", label: "Accueil" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts", label: "Contacts" },
    ],
  };

  const renderMenuLinks = () => {
    const links = menuLinks[roleUser] || menuLinks.default;
    return links.map((link) => (
      <Link key={link.to} to={link.to} className="hover:text-primary-700">
        {link.label}
      </Link>
    ));
  };

  const renderAvatar = () => <Avatar />;

  const renderButtons = () => {
    if (roleUser) {
      return roleUser === "user" ? (
        <div className="flex space-x-4 items-center">
          <Button colorType="white_border" onClick={goToDownlaodApp}>
            TÉLÉCHARGER L’APPLICATION
          </Button>
          {renderAvatar()}
        </div>
      ) : (
        renderAvatar()
      );
    } else {
      return (
        <div className="flex space-x-4">
          <Button colorType="white_border" onClick={goToDownlaodApp}>
            TÉLÉCHARGER L’APPLICATION
          </Button>
          <Button colorType="primary" onClick={navigateToLogin}>
            CONNEXION
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      {/* Bande rouge pour compte non vérifié */}
      {user && userInfos?.isVerified === false && (
        <div className="bg-red-500 text-white fixed w-full text-center py-2 text-sm md:text-base z-50">
          Votre compte n'est pas vérifié.{" "}
          <span
            className="hover:cursor-pointer underline"
            onClick={handleVerifyAccount}
          >
            Vérifier maintenant
          </span>
        </div>
      )}
      <div className="bg-white shadow-md w-full">
        {roleUser !== "owner" && (
          <div className="w-full flex justify-end pr-6 pt-1">
            <p
              className="underline hover:cursor-pointer text-poppins-small"
              onClick={navigateToBecomeOwner}
            >
              Je suis restaurateur
            </p>
          </div>
        )}

        <div className="flex justify-between items-center px-4 py-2 h-[125.25px]">
          {/* Logo */}
          <div className="flex items-center w-[200px] h-[60px] ml-5">
            <img
              src={NavBarLogo}
              onClick={goToHome}
              alt="Label Adresse"
              className="h-full w-full hover:cursor-pointer"
            />
          </div>

          {/* Menu Burger Icon for Mobile */}
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="outline-none mobile-menu-button"
            >
              <svg
                className="w-6 h-6 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                ></path>
              </svg>
            </button>
          </div>

          {/* Navigation Links - Hidden on Mobile */}
          <div className="hidden md:flex gap-24 text-primary-600 font-medium">
            {renderMenuLinks()}
          </div>

          {/* Buttons or Avatar */}
          <div className="hidden md:flex">{renderButtons()}</div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden">
            <div className="flex flex-col gap-4 text-primary-600 font-medium p-4">
              {renderMenuLinks()}
              {renderButtons()}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBar;
