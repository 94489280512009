import React, { useState } from "react";
import { useAuth } from "../../Auth/Provider/AuthProvider";

import useRequest from "../../../hooks/establishment/useRequest";
import WhatIsTheAction from "../../Owner/ActionsEstablishement/WhatIsTheAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Status from "../../Owner/ActionsEstablishement/FollowRequest/Status";
import GreenFoodComponent from "../../Owner/ActionsEstablishement/FollowRequest/GreenFoodComponent";
import StatusSelect from "../../Owner/ActionsEstablishement/FollowRequest/StatusSelect";

const RequestManagement = () => {
  const { user } = useAuth();


  const { requests, handleDocumentStatusChange, handleDocumentFeedback } =
    useRequest({
      auditorId: user.id,
    });

  const [openRequestId, setOpenRequestId] = useState(null);

  const toggleAccordion = (requestId) => {
    setOpenRequestId(openRequestId === requestId ? null : requestId);
  };

  return (
    <div>
      <WhatIsTheAction actionIs={"requests"} />

      <div className="container mx-auto mt-8 ">
        {requests?.map((request) => (
          <div
            key={request.labelRequest._id}
            className="border rounded-lg p-4 mb-4 hover:bg-gray-50 bg-white"
          >
            <div
              className="flex justify-between items-center w-full cursor-pointer"
              onClick={() => toggleAccordion(request.labelRequest._id)}
            >
              <div className="flex items-center gap-3">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`transform transition-transform duration-200 w-4 ${
                    openRequestId === request.labelRequest._id
                      ? "rotate-180"
                      : ""
                  }`}
                />
                <h3 className="text-roca-paragraph font-semibold">
                  Demande pour le label{" "}
                  {request.labelRequest.label.toUpperCase()}
                </h3>
              </div>
              <div className="flex items-center">
                <StatusSelect
                  currentStatus={request.labelRequest.status}
                  requestId={request.labelRequest._id}
                  onStatusChange={(newStatus) => {
                    console.log("Status changed to:", newStatus);
                  }}
                />
              </div>
            </div>

            <div
              className={`transition-all duration-200 overflow-hidden ${
                openRequestId === request.labelRequest._id
                  ? "max-h-[5000px] opacity-100 mt-6"
                  : "max-h-0 opacity-0"
              }`}
            >
              {request.labelRequest.label === "green-food" && (
                <GreenFoodComponent
                  isAuditor={true}
                  labelData={request.labelData}
                  labelRequest={request.labelRequest}
                  onDocumentStatusChange={handleDocumentStatusChange}
                  onDocumentFeedback={handleDocumentFeedback}
                />
              )}
              {request.labelRequest.label === "la-clef-verte" && (
                <div>Composant pour La Clef Verte (non défini ici)</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RequestManagement;
