import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  register,
  ResendCode,
  VerifyCodeRegister,
} from "../services/auth.services";

const useRegisterPro = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    company_name: "",
    company_siret: "",
    company_address: "",
    accepted_cgu: false,
    accepted_notification: false,
    role: "owner",
    requestToBeOwner : true,
    isAdmin: false,
    isVerified: false,
  });

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const validateSiret = (siret) => {
    const cleanSiret = siret.replace(/\s/g, "");
    return /^\d{14}$/.test(cleanSiret);
  };

  const handleVerificationCode = async (registrationData) => {
    const showCodePrompt = async () => {
      const { value: code, isDenied } = await Swal.fire({
        title: "Code de vérification",
        input: "text",
        inputLabel: "Entrez le code reçu par email",
        showCancelButton: false,
        showDenyButton: true,
        denyButtonText: "Renvoyer le code",
        confirmButtonText: "Vérifier",
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputValidator: (value) => {
          if (!value) {
            return "Vous devez entrer le code";
          }
        },
      });

      if (isDenied) {
        await ResendCode(registrationData.email);
        Swal.update({
          title: "Code de vérification",
          text: "Un nouveau code vous a été envoyé par email",
          input: "text",
          icon: undefined,
          showDenyButton: true,
        });
        return showCodePrompt();
      }

      if (code) {
        try {
          const response = await VerifyCodeRegister(
            registrationData.email,
            code
          );

          if (!response.data.isVerified) {
            await Swal.fire({
              icon: "error",
              title: "Code invalide",
              text: "Le code entré est incorrect",
              confirmButtonText: "Réessayer",
              showDenyButton: true,
              denyButtonText: "Renvoyer le code",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              if (result.isDenied) {
                return showCodePrompt();
              } else {
                return showCodePrompt();
              }
            });
            return;
          }

          await Swal.fire({
            icon: "success",
            title: "Compte vérifié avec succès!",
            showConfirmButton: false,
            timer: 1500,
          });

          navigate("/login");
        } catch (error) {
          await Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Une erreur est survenue",
            showDenyButton: true,
            denyButtonText: "Renvoyer le code",
            confirmButtonText: "Réessayer",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            return showCodePrompt();
          });
        }
      }
    };

    await showCodePrompt();
  };

  const validateStep1 = () => {
    const newErrors = {};

    if (!formData.firstname) newErrors.firstname = "Le prénom est requis";
    if (!formData.lastname) newErrors.lastname = "Le nom est requis";
    if (!formData.email) {
      newErrors.email = "L'email est requis";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Format d'email invalide";
    }
    if (!formData.password) {
      newErrors.password = "Le mot de passe est requis";
    } else if (!validatePassword(formData.password)) {
      newErrors.password =
        "Le mot de passe doit contenir au moins 8 caractères";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Les mots de passe ne correspondent pas";
    }
    if (!formData.accepted_cgu) {
      newErrors.accepted_cgu = "Vous devez accepter les CGU";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep2 = () => {
    const newErrors = {};

    if (!formData.company_name) {
      newErrors.company_name = "Le nom de l'entreprise est requis";
    }
    if (!formData.company_siret) {
      newErrors.company_siret = "Le numéro SIRET est requis";
    } else if (!validateSiret(formData.company_siret)) {
      newErrors.company_siret = "Le numéro SIRET doit contenir 14 chiffres";
    }
    if (!formData.company_address) {
      newErrors.company_address = "L'adresse est requise";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "company_siret") {
      const cleanValue = value.replace(/\D/g, "").slice(0, 14);
      const formattedValue = cleanValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{5})/,
        "$1 $2 $3 $4"
      );

      setFormData((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await register(formData);
      if (res.status === 201) {
        handleVerificationCode(formData);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de la création du compte",
      });
    }
  };

  const handleNext = () => {
    if (step === 1 && validateStep1()) {
      setStep(2);
    } else if (step === 2 && validateStep2()) {
      // Au lieu d'appeler handleSubmit ici, on passe à la step 3
      setStep(3);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  return {
    formData,
    errors,
    step,
    handleChange,
    handleNext,
    handlePrevious,
    handleSubmit, // On expose handleSubmit pour l'utiliser dans le bouton "Valider Email"
  };
};

export default useRegisterPro;
