import { useEffect, useState, useCallback } from "react";
import { getEstablishmentById } from "../../services/establishements.services";
import { getFilesByEstablishmentIdAndCategory } from "../../services/files.services";
import Swal from "sweetalert2";
import {
  getReviewsByEstablishmentId,
  likeReview,
  reportReview,
} from "../../services/review.services";

const useEstablishment = ({ establishmentId, token }) => {
  const [establishment, setEstablishment] = useState(null);
  const [menu, setMenu] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [filter, setFilter] = useState("mostRecent");
  const [visibleReviews, setVisibleReviews] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchEstablishment = useCallback(async () => {
    try {
      const res = await getEstablishmentById(establishmentId);
      if (res) setEstablishment(res.establishment);
    } catch (error) {
      console.error("Failed to fetch establishment", error);
    }
  }, [establishmentId, setEstablishment]);

  const getMenuOfEstablishment = useCallback(async () => {
    try {
      if (establishmentId) {
        const res = await getFilesByEstablishmentIdAndCategory(
          establishmentId,
          "menu"
        );
        setMenu(res || []);
      }
    } catch (error) {
      console.error("Failed to fetch menu", error);
    }
  }, [establishmentId]);

  const getReviewsOfEstablishment = useCallback(async () => {
    try {
      if (establishmentId) {
        const res = await getReviewsByEstablishmentId(establishmentId);
        setReviews(res?.reviews || []);
      }
    } catch (error) {
      console.error("Failed to fetch reviews", error);
    }
  }, [establishmentId]);

  useEffect(() => {
    fetchEstablishment();
    getMenuOfEstablishment();
    getReviewsOfEstablishment();
  }, [fetchEstablishment, getMenuOfEstablishment, getReviewsOfEstablishment]);

  const getStringAddress = () => {
    const { address } = establishment || {};
    if (!address) return "";
    const { street, city, postalCode, country } = address;
    return `${street}, ${postalCode} ${city}, ${country}`;
  };

  const showOpeningHoursModal = () => {
    const { openingTime, closingTime } = establishment.schedule || {};
    Swal.fire({
      title: "Horaires d'ouverture",
      text: `Ouverture: ${openingTime || "N/A"}h - Fermeture: ${
        closingTime || "N/A"
      }h`,
    });
  };

  const openMapLink = () => {
    const address = getStringAddress();
    if (address) {
      window.open(
        `https://maps.google.com/?q=${encodeURIComponent(address)}`,
        "_blank"
      );
    }
  };

  const openWebsite = (url) => {
    if (url) window.open(url, "_blank");
  };

  const openPhoneLink = (phoneNumber) => {
    if (phoneNumber) window.open(`tel:${phoneNumber}`, "_self");
  };

  const getOpeningHours = () => {
    const now = new Date();
    const hourNow = now.getHours();
    const minuteNow = now.getMinutes();

    const { openingTime, closingTime } = establishment.schedule || {};

    if (!openingTime || !closingTime) return "Heures non disponibles";

    const [openingHour, openingMinute] = openingTime.split(":").map(Number);
    const [closingHour, closingMinute] = closingTime.split(":").map(Number);

    const isOpen =
      (hourNow > openingHour ||
        (hourNow === openingHour && minuteNow >= openingMinute)) &&
      (hourNow < closingHour ||
        (hourNow === closingHour && minuteNow <= closingMinute));

    if (establishment.schedule.weAreClosed) {
      return "Fermé";
    }

    return isOpen ? "Ouvert" : "Fermé";
  };

  const handleFilterChange = (newFilter) => setFilter(newFilter);

  const handleShowMore = () => setVisibleReviews((prev) => prev + 5);

  useEffect(() => {
    const applyFilter = () => {
      if (!Array.isArray(reviews)) return;

      let sortedReviews = [...reviews];
      switch (filter) {
        case "bestToWorst":
          sortedReviews.sort((a, b) => b.rating - a.rating);
          break;
        case "worstToBest":
          sortedReviews.sort((a, b) => a.rating - b.rating);
          break;
        case "mostRecent":
          sortedReviews.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          break;
        case "mostLiked":
          sortedReviews.sort((a, b) => b.likes_nomber - a.likes_nomber);
          break;
        default:
          break;
      }
      setFilteredReviews(sortedReviews);
    };
    applyFilter();
  }, [reviews, filter]);

  const handleLike = async (reviewId) => {
    try {
      const res = await likeReview(reviewId);
      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review._id === reviewId
            ? { ...review, likes_nomber: res.data.review.likes_nomber }
            : review
        )
      );
    } catch (error) {
      Swal.fire(
        "Erreur",
        "Une erreur est survenue lors de la gestion du like.",
        "error"
      );
    }
  };

  const handleReport = async (reviewId) => {
    try {
      const res = await reportReview(reviewId);
      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review._id === reviewId
            ? { ...review, isReport: res.data.review.isReport }
            : review
        )
      );
      Swal.fire(
        res.data.review.isReport ? "Avis signalé" : "Signalement retiré",
        "",
        "success"
      );
    } catch (error) {
      Swal.fire(
        "Erreur",
        "Une erreur est survenue lors du signalement.",
        "error"
      );
    }
  };

  const handleAddReview = () => {
    if (!token) {
      Swal.fire({
        title: "Vous devez être connecté pour laisser un avis",
        icon: "error",
      });
      return;
    }
    setIsModalOpen(true);
  };

  const handleReviewSubmit = (newReview) => {
    setReviews((prevReviews) => [newReview, ...prevReviews]); // Ajoute le nouvel avis en début de liste
    setIsModalOpen(false);
    getReviewsOfEstablishment(establishmentId);
  };

  return {
    getStringAddress,
    showOpeningHoursModal,
    openMapLink,
    openWebsite,
    openPhoneLink,
    getOpeningHours,
    establishment,
    menu,
    reviews,
    filteredReviews,
    visibleReviews,
    handleFilterChange,
    handleShowMore,
    handleLike,
    handleReport,
    handleAddReview,
    handleReviewSubmit,
    isModalOpen,
  };
};

export default useEstablishment;
