import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../services/user.services";
import {
  AssociateOwnerToEstablishment,
  getEstablishments,
} from "../../services/establishements.services";
import Swal from "sweetalert2";

const BackOfficeAssociateOwner = () => {
  const [users, setUsers] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getAllUsers();
      if (response?.users) {
        const owners = response.users.filter((user) => user.role === "owner");
        setUsers(owners);
      }
    };

    const fetchEstablishments = async () => {
      const response = await getEstablishments();
      if (response?.data?.establishments) {
        setEstablishments(response.data.establishments);
      }
    };

    fetchUsers();
    fetchEstablishments();
  }, []);

  const handleUserSelect = (event) => {
    const userId = event.target.value;
    setSelectedUser(users.find((user) => user._id === userId));
  };

  const handleEstablishmentSelect = (event) => {
    const establishmentId = event.target.value;
    setSelectedEstablishment(
      establishments.find((est) => est._id === establishmentId)
    );
  };

  const handleAssociate = async () => {
    if (selectedUser && selectedEstablishment) {
      try {
        const response = await AssociateOwnerToEstablishment(
          selectedUser._id,
          selectedEstablishment._id
        );

        if (response?.status === "success") {
          Swal.fire({
            title: "Succès",
            text: `${selectedUser.firstname} a été associé à ${selectedEstablishment.name}`,
            icon: "success",
            confirmButtonColor: "#122C39",
          });
        } else {
          throw new Error("Association échouée");
        }
      } catch (error) {
        Swal.fire({
          title: "Erreur",
          text: "Impossible d'associer cet utilisateur à l'établissement.",
          icon: "error",
          confirmButtonColor: "#D9512D",
        });
      }
    } else {
      Swal.fire({
        title: "Erreur",
        text: "Veuillez sélectionner un utilisateur et un établissement.",
        icon: "warning",
        confirmButtonColor: "#FF7B5A",
      });
    }
  };

  return (
    <div className="bg-background-600  flex items-center justify-center p-6">
      <div className="bg-white shadow-lg rounded-12 p-8 w-full max-w-2xl">
        <h1 className="text-tertiary-600 text-center text-2xl font-semibold mb-6">
          Associer un utilisateur à un établissement
        </h1>

        <div className="mb-4">
          <label className="block text-primary-600 font-medium mb-2">
            Sélectionner un utilisateur :
          </label>
          <select
            className="w-full p-3 border border-primary-400 rounded-6 bg-primary-300 text-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600"
            onChange={handleUserSelect}
            defaultValue=""
          >
            <option value="" disabled>
              -- Sélectionner un utilisateur --
            </option>
            {users.map((user) => (
              <option key={user._id} value={user._id}>
                {user.firstname} {user.lastname} - {user.email}
              </option>
            ))}
          </select>
        </div>

        {/* Sélection de l'établissement */}
        <div className="mb-4">
          <label className="block text-primary-600 font-medium mb-2">
            Sélectionner un établissement :
          </label>
          <select
            className="w-full p-3 border border-primary-400 rounded-6 bg-primary-300 text-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600"
            onChange={handleEstablishmentSelect}
            defaultValue=""
          >
            <option value="" disabled>
              -- Sélectionner un établissement --
            </option>
            {establishments.map((est) => (
              <option key={est._id} value={est._id}>
                {est.name}
              </option>
            ))}
          </select>
        </div>

        {/* Bouton d'association qui apparaît seulement si une sélection est faite */}
        {selectedUser && selectedEstablishment && (
          <button
            onClick={handleAssociate}
            className="w-full bg-secondary-600 hover:bg-secondary-700 text-white font-bold py-3 px-6 rounded-8 transition-all"
          >
            Associer
          </button>
        )}
      </div>
    </div>
  );
};

export default BackOfficeAssociateOwner;
