import React, { useState } from "react";
import Input from "../../../elements/Input/Input";
import Button from "../../../elements/Button/Button";
import SelectFilter from "../../../elements/select/SelectFilter";
import { createEstablishment } from "../../../../services/establishements.services";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Services, TypeOfCooking } from "../../../../utils/predefinedTerms";
import { getImageOfTheLabelAndName } from "../../../../utils/Labels";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const availableLabels = [
  "ecotable",
  "la_clef_verte",
  "green_food",
  "bon_pour_le_climat",
  "v_label",
  "fig",
  "framheim",
  "ab_bio",
  "ecolabel_eur",
  "ethic_ocean",
  "asc_aquaculture",
];

const initialFormData = {
  name: "",
  photo: [],
  description: "",
  street: "",
  city: "",
  postalCode: "",
  country: "",
  website: "",
  phoneNumber: "",
  typeOfCooking: "",
  price: "",
  infos: [],
  schedule: {
    openingTime: "",
    closingTime: "",
    daysOpen: [],
  },
  labels: [],
};

const AddEstablishment = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const [searchTerm, setSearchTerm] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleFileChange = (files) => {
    setFormData((prev) => ({ ...prev, photo: files }));
  };

  const handleDayToggle = (day) => {
    setFormData((prev) => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        daysOpen: prev.schedule.daysOpen.includes(day)
          ? prev.schedule.daysOpen.filter((d) => d !== day)
          : [...prev.schedule.daysOpen, day],
      },
    }));
  };

  const handleInfosClick = (info) => {
    setFormData((prev) => ({
      ...prev,
      infos: prev.infos.includes(info)
        ? prev.infos.filter((i) => i !== info)
        : [...prev.infos, info],
    }));
  };

  const handleLabelToggle = (label) => {
    setFormData((prev) => ({
      ...prev,
      labels: prev.labels.includes(label)
        ? prev.labels.filter((l) => l !== label)
        : [...prev.labels, label],
    }));
  };

  const websiteRegex =
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9#]+\/?)*$/;
  const phoneNumberRegex = /^\+?\d{10,15}$/;

  const validateStep = () => {
    const validationErrors = {};

    // Étape 1 : Nom
    if (step === 1 && !formData.name)
      validationErrors.name = "Le nom est requis.";

    // Étape 2 : Adresse
    if (step === 2) {
      if (!formData.street) validationErrors.street = "La rue est requise.";
      if (!formData.city) validationErrors.city = "La ville est requise.";
      if (!formData.postalCode)
        validationErrors.postalCode = "Le code postal est requis.";
      if (!formData.country) validationErrors.country = "Le pays est requis.";

      // Validation du site web
      if (formData.website && !websiteRegex.test(formData.website)) {
        validationErrors.website = "Veuillez entrer un site web valide.";
      }

      // Validation du numéro de téléphone
      if (
        formData.phoneNumber &&
        !phoneNumberRegex.test(formData.phoneNumber)
      ) {
        validationErrors.phoneNumber =
          "Veuillez entrer un numéro de téléphone valide.";
      }
    }

    // Étape 3 : Horaires
    if (step === 3) {
      if (!formData.schedule.openingTime)
        validationErrors.openingTime = "L'heure d'ouverture est requise.";
      if (!formData.schedule.closingTime)
        validationErrors.closingTime = "L'heure de fermeture est requise.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateStep()) return;

    try {
      setIsLoading(true);
      await createEstablishment(formData);

      setIsLoading(false);

      Swal.fire({
        title: "Restaurant ajouté avec succès",
        icon: "success",
      }).then(() => {
        setFormData(initialFormData);
        navigate("/my-establishements");
      });
    } catch (error) {
      console.error("Erreur lors de l’ajout du restaurant:", error);
    }
  };

  const renderStep1 = () => (
    <div className="bg-white rounded-40 shadow-lg w-[694px] p-[64px]">
      <h1 className="text-roca-h4-5 !text-secondary-600 mb-8">
        Commençons par le nom
      </h1>
      <Input
        placeholder="Nom du restaurant"
        label="Nom du restaurant"
        name="name"
        value={formData.name}
        onChange={handleChange}
        hasError={!!errors.name}
      />
      {errors.name && (
        <p className="text-red-500 text-sm mb-4">{errors.name}</p>
      )}
      <Input
        placeholder="Décrivez votre restaurant"
        label="Décrivez votre restaurant"
        name="description"
        textArea
        value={formData.description}
        onChange={handleChange}
        additionalClasses="mb-4"
      />

      <Input
        isFiles
        label="Photos de votre restaurant"
        onFilesChange={handleFileChange}
      />
      <div className="flex justify-center mt-4">
        <Button
          colorType="secondary"
          onClick={() => validateStep() && setStep(2)}
        >
          Suivant
        </Button>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="bg-white rounded-40 shadow-lg w-[694px] p-[64px]">
      <h2 className="text-roca-h4-5 !text-secondary-600 mb-8">
        Adresse et Contact
      </h2>
      <div className="flex flex-row gap-5">
        <div className="w-full">
          <Input
            label="Rue"
            name="street"
            placeholder="Ex : 123 rue de la Paix"
            value={formData.street}
            onChange={handleChange}
            hasError={!!errors.street}
          />
          {errors.street && (
            <p className="text-red-500 text-sm">{errors.street}</p>
          )}
        </div>
        <div className="w-full">
          <Input
            label="Code postal"
            name="postalCode"
            placeholder="Ex : 75000"
            value={formData.postalCode}
            onChange={handleChange}
            hasError={!!errors.postalCode}
          />
          {errors.postalCode && (
            <p className="text-red-500 text-sm">{errors.postalCode}</p>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-5">
        <div className="w-full">
          <Input
            label="Ville"
            name="city"
            placeholder="Ex : Paris"
            value={formData.city}
            onChange={handleChange}
            hasError={!!errors.city}
          />
          {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
        </div>

        <div className="w-full">
          <Input
            label="Pays"
            name="country"
            placeholder="Ex : France"
            value={formData.country}
            onChange={handleChange}
            hasError={!!errors.country}
          />
          {errors.country && (
            <p className="text-red-500 text-sm">{errors.country}</p>
          )}
        </div>
      </div>
      <Input
        label="Site web"
        name="website"
        placeholder="Ex : https://restaurant.fr"
        value={formData.website}
        onChange={handleChange}
        hasError={!!errors.website}
      />
      {errors.website && (
        <p className="text-red-500 text-sm">{errors.website}</p>
      )}
      <Input
        label="Téléphone"
        name="phoneNumber"
        placeholder="Ex : 07 54 63 64 54"
        value={formData.phoneNumber}
        onChange={handleChange}
        hasError={!!errors.phoneNumber}
      />
      {errors.phoneNumber && (
        <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
      )}
      <div className="flex justify-between mt-4">
        <Button colorType="secondary" onClick={() => setStep(1)}>
          Précédent
        </Button>
        <Button
          colorType="secondary"
          onClick={() => validateStep() && setStep(3)}
        >
          Suivant
        </Button>
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="bg-white rounded-40 shadow-lg w-[694px] p-[64px]">
      <h1 className="text-roca-h4-5 !text-secondary-600 mb-8">
        Informations pratiques
      </h1>
      <div className="flex flex-row gap-8 ">
        <div className="">
          <Input
            type="time"
            label="Heure d'ouverture"
            name="openingTime"
            value={formData.schedule.openingTime}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                schedule: {
                  ...prev.schedule,
                  openingTime: e.target.value,
                },
              }))
            }
            className="w-full bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
          />
          {errors.openingTime && (
            <p className="text-red-500 text-sm mt-2">{errors.openingTime}</p>
          )}

          <h2 className="text-poppins-small mb-5 mt-8">Heures de fermeture</h2>
          <Input
            type="time"
            label="Heure de fermeture"
            name="closingTime"
            value={formData.schedule.closingTime}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                schedule: {
                  ...prev.schedule,
                  closingTime: e.target.value,
                },
              }))
            }
            className="w-full bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
          />
          {errors.closingTime && (
            <p className="text-red-500 text-sm mt-2">{errors.closingTime}</p>
          )}

          <h2 className="text-poppins-small mb-5 mt-8">Jours d'ouverture</h2>
          <div className="flex gap-2 flex-wrap">
            {days.map((day) => (
              <button
                key={day}
                type="button"
                className={`px-4 py-2 rounded-lg ${
                  formData.schedule.daysOpen?.includes(day)
                    ? "bg-secondary-600 text-white"
                    : "border border-primary-600 text-primary-900"
                }`}
                onClick={() => handleDayToggle(day)}
              >
                {day}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button colorType="secondary" onClick={() => setStep(2)}>
          Précédent
        </Button>
        <Button colorType="secondary" onClick={() => setStep(4)}>
          Suivant
        </Button>
      </div>
    </div>
  );

  const renderStep4 = () => (
    <div className="bg-white rounded-40 shadow-lg w-[694px] p-[64px]">
      <h2 className="text-roca-h4-5 !text-secondary-600 mb-8">
        Informations supplémentaires
      </h2>
      <div>
        <h3 className="mt-4 text-poppins-small mb-2">Type de Cuisine</h3>
        <select
          name="typeOfCooking"
          id="typeOfCooking"
          value={formData.typeOfCooking}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              typeOfCooking: e.target.value,
            }))
          }
          className="w-full bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
        >
          <option value="" disabled>
            Sélectionnez un type de cuisine
          </option>
          {TypeOfCooking.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <h3 className="mt-4  text-poppins-small mb-2">
          Quelle est l'addition moyenne par client ?
        </h3>
        <Input
          type="number"
          min="0"
          name="price"
          placeholder="Prix moyen"
          value={formData.price}
          onChange={({ target }) =>
            setFormData((prev) => ({
              ...prev,
              price: target.value,
            }))
          }
        />
      </div>
      <div>
        <h3 className="mt-4">Informations supplémentaires</h3>
        <div className="flex gap-2 flex-wrap">
          {Services.map((service) => (
            <Button
              key={service.label}
              colorType={
                formData.infos.includes(service.label)
                  ? "secondary"
                  : "white_border"
              }
              onClick={() => handleInfosClick(service.label)}
              className="flex items-center gap-2"
            >
              <span>{service.name}</span>
            </Button>
          ))}
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button colorType="secondary" onClick={() => setStep(3)}>
          Précédent
        </Button>
        <Button colorType="secondary" onClick={() => setStep(5)}>
          Suivant
        </Button>
      </div>
    </div>
  );

  const renderStep5 = () => (
    <div className="bg-white rounded-40 shadow-lg w-[694px] p-[64px]">
      <h2 className="text-roca-h4-5 !text-secondary-600 mb-8">
        Possédez-vous déjà un label ?
      </h2>
      <Input
        type="text"
        name="search"
        placeholder="Rechercher un label"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        icon={faSearch}
      />
      <h2 className="text-poppins-small">Choisissez un ou plusieurs labels</h2>
      <div className="mt-4 grid grid-cols-5 gap-4">
        {availableLabels
          .filter((label) =>
            getImageOfTheLabelAndName(label)
              .name.toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
          .map((label) => {
            const { name, image } = getImageOfTheLabelAndName(label);
            const isSelected = formData.labels.includes(label);

            return (
              <div
                key={label}
                onClick={() => handleLabelToggle(label)}
                className={`flex flex-col items-center p-4 rounded-lg cursor-pointer transition-colors ${
                  isSelected
                    ? "bg-secondary-100 border-2 border-secondary-600"
                    : ""
                }`}
              >
                <img
                  src={image}
                  alt={name}
                  className="w-16 h-16 object-contain rounded-full shadow-xl"
                />
                <span className="text-center text-poppins-extra-small mt-2">
                  {name}
                </span>
              </div>
            );
          })}
      </div>
      <div className="flex justify-between mt-4">
        <Button colorType="secondary" onClick={() => setStep(4)}>
          Précédent
        </Button>
        <Button colorType="secondary" onClick={() => handleSubmit()}>
          {isLoading ? "Chargement..." : "Soumettre"}
        </Button>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto flex justify-center items-center mt-20">
      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step === 3 && renderStep3()}
      {step === 4 && renderStep4()}
      {step === 5 && renderStep5()}
    </div>
  );
};

export default AddEstablishment;
