import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useDebounce } from "../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { searchEstablishments } from "../../../services/establishements.services";
import { predefinedTerms } from "../../../utils/predefinedTerms";

const SearchInput = ({ currentCity }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPredefinedTerms, setFilteredPredefinedTerms] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ajout du ref pour le dropdown

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchSuggestions(debouncedSearchTerm);

      const filtered = predefinedTerms.filter((term) =>
        term.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredPredefinedTerms(filtered);
    } else {
      setSuggestions([]);
      setFilteredPredefinedTerms([]);
    }
  }, [debouncedSearchTerm]);

  const fetchSuggestions = async (term) => {
    try {
      const lat = currentCity.lat; // Remplace par la latitude actuelle
      const lon = currentCity.lon; // Remplace par la longitude actuelle
      const distance = 10000; // Rayon de recherche, par exemple 10 km
      const res = await searchEstablishments(term, lat, lon, distance, {});
      setSuggestions(res);
      setIsDropdownOpen(true);
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions :", error);
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    navigate(`/establishment/${suggestion._id}`);
    setIsDropdownOpen(false);
  };

  const handlePredefinedTermClick = (term) => {
    navigate(`/establishments?search=${term.label.toLowerCase()}`);
    setIsDropdownOpen(false);
  };

  const handleShowAllEstablishments = async () => {
    try {
      const lat = currentCity.lat;
      const lon = currentCity.lon;
      const distance = 10000;
      const res = await searchEstablishments("", lat, lon, distance, {});
      navigate("/establishments", {
        state: { establishments: res },
      });
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des établissements :",
        error
      );
    }
  };

  // Gestion du clic en dehors du dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className="relative flex-grow flex items-center">
      <input
        type="text"
        placeholder="Rechercher des lieux"
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={() => setIsDropdownOpen(true)}
        className="bg-transparent focus:outline-none flex-grow  p-2 rounded-lg"
      />
      <button className="mr-5">
        <FontAwesomeIcon icon={faSearch} />
      </button>

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`absolute left-0 top-full mt-2 bg-white shadow-lg w-full z-10 rounded-8 overflow-y-auto ${
            filteredPredefinedTerms.length > 0 ? "h-[220px]" : ""
          }`}
        >
          <div
            onClick={handleShowAllEstablishments}
            className="px-3 py-2 cursor-pointer hover:bg-gray-100 flex justify-between items-center hover:rounded-8"
          >
            <div className="flex flex-row gap-3 items-center">
              <FontAwesomeIcon icon={faSearch} />
              <h2 className="text-poppins-small p-3">
                Voir tous les restaurants
              </h2>
            </div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          {suggestions.map((suggestion, index) => (
            <div
              key={suggestion._id}
              className={`cursor-pointer hover:bg-gray-100 rounded-8 p-3 flex gap-3  ${
                index === suggestions.length - 1 ? "hover:rounded-8" : ""
              }`}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              <div>
                <img
                  src={suggestion?.photos[0]?.fileUrl}
                  alt={suggestion.name}
                  className="w-12 h-12 rounded-8"
                />
              </div>
              <div>
                <p className="text-poppins-small">{suggestion.name}</p>
                <p className="text-poppins-extra-small !text-gray-400">
                  {suggestion.address.postalCode}, {suggestion.address.city}
                </p>
              </div>
            </div>
          ))}

          {filteredPredefinedTerms.length > 0 && (
            <>
              {filteredPredefinedTerms.map((term) => (
                <div
                  key={term.label}
                  className="p-3 cursor-pointer hover:bg-gray-100 flex  flex-row justify-start gap-3 border-none"
                  onClick={() => handlePredefinedTermClick(term)}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={term.icon}></FontAwesomeIcon>
                  </div>
                  <div>
                    <p className="italic text-poppins-extra-small">
                      {term.categoryLabel}
                    </p>
                    <h2 className="text-poppins-small"> {term.name}</h2>
                  </div>
                </div>
              ))}
              <hr className="my-2" />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
