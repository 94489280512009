import React, { useState } from "react";
import ModalAnswer from "../ModalAnswer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const FaqShow = ({ faqs, expandedCategoryIndices, onCategoryToggle }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleQuestion = (question, answer) => {
    setSelectedQuestion({ question, answer });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedQuestion(null);
  };

  return (
    <div className="space-y-4">
      {faqs.map((item, index) => (
        <div key={index} className="bg-white rounded-lg shadow-lg">
          <div
            onClick={() => onCategoryToggle(index)}
            className="cursor-pointer p-4 text-lg font-semibold border-gray-200 flex justify-between items-center"
          >
            {item.category}
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`transform transition-transform duration-300 ${
                expandedCategoryIndices.includes(index)
                  ? "rotate-180"
                  : "rotate-0"
              }`}
            />
          </div>
          {expandedCategoryIndices.includes(index) && (
            <div
              className="text-left overflow-hidden transition-max-height duration-300 ease-in-out"
              style={{
                maxHeight: expandedCategoryIndices.includes(index)
                  ? "500px"
                  : "0px",
              }}
            >
              {item.questions.map((question, i) => (
                <div
                  key={i}
                  onClick={() =>
                    handleToggleQuestion(question.question, question.answer)
                  }
                  className="cursor-pointer p-4 text-gray-800 hover:bg-gray-100 hover:rounded-b-lg border-gray-200 hover:underline"
                >
                  {question.question}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {selectedQuestion && (
        <ModalAnswer
          isOpen={isModalOpen}
          onClose={closeModal}
          question={selectedQuestion.question}
          answer={selectedQuestion.answer}
        />
      )}
    </div>
  );
};

export default FaqShow;
