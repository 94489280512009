import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";

const VersionHistoryModal = ({
  isOpen,
  onClose,
  versions,
  onDownload,
  onView,
}) => {
  if (!isOpen) return null;

  const getStatusColor = (status) => {
    const colors = {
      pending: "bg-yellow-100 text-yellow-800",
      in_review: "bg-blue-100 text-blue-800",
      approved: "bg-green-100 text-green-800",
      rejected: "bg-red-100 text-red-800",
      needs_more_info: "bg-orange-100 text-orange-800",
    };
    return colors[status] || "bg-gray-100 text-gray-800";
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Historique des versions</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <div className="space-y-4">
          {versions.map((version) => (
            <div key={version._id} className="border rounded-lg p-4">
              <div className="flex justify-between items-start">
                <div>
                  <div className="font-medium">
                    Version {version.versionNumber}
                  </div>
                  <div className="text-sm text-gray-500">
                    {new Date(version.createdAt).toLocaleDateString()} à{" "}
                    {new Date(version.createdAt).toLocaleTimeString()}
                  </div>
                  <div
                    className={`mt-2 inline-block px-2 py-1 rounded-full text-sm ${getStatusColor(
                      version.status
                    )}`}
                  >
                    {version.status}
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => onDownload(version._id)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                    title="Télécharger"
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </button>
                  <button
                    onClick={() => onView(version._id)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                    title="Visualiser"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </div>
              </div>

              {version.auditorFeedback &&
                version.auditorFeedback.length > 0 && (
                  <div className="mt-3 text-sm">
                    <div className="font-medium">Retours auditeur :</div>
                    {version.auditorFeedback.map((feedback, idx) => (
                      <div key={idx} className="mt-1 text-gray-600">
                        {feedback.feedback}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VersionHistoryModal;
