import React from "react";
import { getImageOfTheLabelAndName } from "../../utils/Labels";
import Button from "../elements/Button/Button";
import ModalOpenEstablishement from "./ModalOpenEstablishement";
import { useAuth } from "../Auth/Provider/AuthProvider";

const CardLabel = ({ label }) => {
  const labelInfo = getImageOfTheLabelAndName(label.slugLabel);
  const [isOpen, setIsOpen] = React.useState(false);
  const [labelSelected, setLabelSelected] = React.useState(null);

  const { user } = useAuth();

  const handleOpenModalChoseEstablishment = () => {
    setLabelSelected(label);
    setIsOpen(true);
  };

  return (
    <div className="bg-white rounded-20 shadow-md p-6 w-full md:w-[482px] md:h-[405px] overflow-auto">
      <div className="flex gap-5">
        {labelInfo && labelInfo.image && (
          <img
            src={labelInfo.image}
            alt={labelInfo.name}
            className="w-16 h-16 rounded-full"
          />
        )}
        <div>
          <h3 className="text-roca-h6">
            {labelInfo ? labelInfo.name : label.name}
          </h3>
        </div>
      </div>
      <div className="h-[1px] bg-black bg-opacity-20 my-4"></div>
      <div>
        <p className="text-poppins-small">{label.description}</p>
      </div>
      {user && user !== "owner" && (
        <div className="flex justify-end mt-4">
          <Button onClick={handleOpenModalChoseEstablishment}>
            Obtenir ce label
          </Button>
        </div>
      )}

      <ModalOpenEstablishement
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        selectedLabel={labelSelected}
      />
    </div>
  );
};

export default CardLabel;
