import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  faEnvelope,
  faLock,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleLogin } from "@react-oauth/google";
import { ReactComponent as FacebookIcon } from "../../../assets/images/Login/FacebookLogin.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/images/Login/googleLogin.svg";
import { ReactComponent as AppleIcon } from "../../../assets/images/Login/appleLogin.svg";
import Button from "../../elements/Button/Button";
import Input from "../../elements/Input/Input";
import useSocialLogin from "../../../hooks/Login/useSocialLogin";
import { initFacebookSDK } from "../../../hooks/Login/facebookSDK";

const SocialButton = ({ icon: Icon, onClick, text, disabled, className }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={`flex items-center justify-center bg-white text-gray-600 border border-gray-300 rounded-full px-4 py-2 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 transition duration-150 ease-in-out w-full mb-3 ${className}`}
  >
    <Icon className="w-6 h-6 mr-2" />
    {text && <span>{text}</span>}
  </button>
);

const LoginForm = ({ onLoginSuccess, goTo }) => {
  const [isFBSDKLoaded, setIsFBSDKLoaded] = useState(false);

  const location = useLocation();
  const from = goTo || location.pathname;

  const {
    handleGoogleLogin,
    handleFacebookLogin,
    handleAppleLogin,
    handleSubmit,
    handleChange,
    isLoading,
    formData,
  } = useSocialLogin({
    from,
    onLoginSuccess,
    goTo,
  });

  useEffect(() => {
    const loadFacebookSDK = async () => {
      try {
        await initFacebookSDK();
        setIsFBSDKLoaded(true);
      } catch (error) {
        console.error("Error loading Facebook SDK:", error);
      }
    };

    loadFacebookSDK();
  }, []);

  const handleFacebookButtonClick = () => {
    if (!isFBSDKLoaded || !window.FB) {
      console.error("Facebook SDK n'est pas chargé");
      return;
    }

    window.FB.login(
      function (response) {
        if (response.authResponse) {
          handleFacebookLogin(response.authResponse);
        } else {
          console.log("Connexion Facebook annulée");
        }
      },
      { scope: "email,public_profile" }
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md w-full bg-white p-8 rounded-xl shadow-lg pt-[63px] pb-[63px] pl-[65px] pr-[65px]"
    >
      <h1 className="text-left text-roca-h4-5 -600 mb-4 !text-secondary-600">
        Bienvenue dans la famille !
      </h1>

      <h2 className="text-left text-poppins-extra-small mb-6">
        Une communauté de plus de 500 lieux éco-responsables dans toute l'Europe
        ! Faisons de notre maison un meilleur endroit
      </h2>

      <div className="space-y-4">
        <Input
          name="email"
          placeholder="Email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          icon={faEnvelope}
        />

        <Input
          name="password"
          placeholder="Mot de passe"
          type="password"
          value={formData.password}
          onChange={handleChange}
          required
          isShowPassword
          icon={faLock}
        />
      </div>

      <div className="flex justify-between items-center mt-4 mb-6">
        <label className="flex items-center space-x-2 text-primary-600 text-sm">
          <input type="checkbox" className="rounded-full" />
          <span className="text-poppins-extra-small">Se souvenir de moi</span>
        </label>

        <a
          href="/forgot-password"
          className="text-sm !text-primary-600 text-poppins-extra-small"
        >
          Mot de passe oublié ?
        </a>
      </div>

      <div className="flex w-full items-center justify-center">
        <Button
          type="submit"
          colorType="secondary"
          AddtionnalClasses="w-[220px]"
          disabled={isLoading}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
          ) : (
            "Se connecter"
          )}
        </Button>
      </div>

      {/* Links section */}
      <div className="mt-6 space-y-2">
        <p className="text-center text-gray-600 text-poppins-extra-small">
          Pas de compte ?{" "}
          <a href="/register" className="text-orange-600 hover:underline">
            Inscrivez-vous ici
          </a>
        </p>

        <p className="text-center text-gray-600 text-poppins-extra-small">
          Vous êtes un professionnel ?{" "}
          <a href="/register/pro" className="text-orange-600 hover:underline">
            Inscrivez-vous ici
          </a>
        </p>
      </div>

      <div className="mt-6 flex flex-col items-center">
        <p className="text-gray-500 mb-4 text-poppins-extra-small">
          Ou continuer avec
        </p>
        <div className="w-full space-y-3">
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => console.log("Erreur lors de la connexion Google")}
            render={(renderProps) => (
              <SocialButton
                icon={GoogleIcon}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled || isLoading}
                text="Continuer avec Google"
              />
            )}
          />

          {/* <SocialButton
            icon={FacebookIcon}
            onClick={handleFacebookButtonClick}
            disabled={isLoading}
            text="Continuer avec Facebook"
          />

          <SocialButton
            icon={AppleIcon}
            onClick={() => handleAppleLogin()}
            disabled={isLoading}
            text="Continuer avec Apple"
          /> */}
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
