import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoHand from "../../assets/images/Logo/Icône.png";

const CardSection = ({ icon, title, text }) => {
  return (
    <div className="rounded-20 shadow-lg p-4 bg-white w-full max-w-sm mx-auto">
      <div className="flex justify-center items-center">
        <img src={logoHand} alt="logo" className="w-40 h-auto object-contain" />
      </div>
      <div className="flex flex-row gap-5 justify-center items-center mt-8">
        <FontAwesomeIcon icon={icon} className="text-primary-600 text-xl" />
        <h1 className="text-poppins-paragraph text-center font-semibold text-gray-800">
          {title}
        </h1>
      </div>
      <div className="p-4">
        <p className="text-poppins-paragraph text-center text-gray-600">
          {text}
        </p>
      </div>
    </div>
  );
};

export default CardSection;
