import React, { useState } from "react";
import Button from "../elements/Button/Button";
import QuestionCard from "./QuestionCard";
import { saveTest } from "../../services/test.services";
import { useAuth } from "../../components/Auth/Provider/AuthProvider";
import { useNavigate } from "react-router-dom";

/**
 * Les 11 labels à évaluer
 */
const LABELS = [
  "Écotable",
  "La Clef Verte",
  "Green Food",
  "Bon pour le Climat",
  "V-Label",
  "Fig",
  "Framheim",
  "AB Bio",
  "Ecolabel Européen",
  "Ethic Ocean",
  "ASC",
];

/**
 * 25 questions, chacune avec :
 *  - id
 *  - category
 *  - question
 *  - answers : tableau de réponses
 *  - labelPoints : { "LabelName": [pointsRéponse0, pointsRéponse1, ...], ... }
 */
const questionsData = [
  // A. Approvisionnement
  {
    id: 1,
    category: "Approvisionnement",
    question:
      "Sur l’ensemble de vos achats alimentaires, quel pourcentage provient de fournisseurs situés à moins de 200 km ?",
    answers: ["Moins de 30%", "Entre 30% et 50%", "Plus de 50%"],
    labelPoints: {
      Écotable: [0, 0.3, 1],
      "La Clef Verte": [0, 0.3, 1],
      "Green Food": [0, 0.3, 1],
      "Bon pour le Climat": [0, 0.3, 1],
      "V-Label": [0, 0, 0],
      Fig: [0, 0, 0],
      Framheim: [0, 0.3, 1],
      "AB Bio": [0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 1],
      "Ethic Ocean": [0, 0, 0],
      ASC: [0, 0, 0],
    },
  },
  {
    id: 2,
    category: "Approvisionnement",
    question:
      "Sur l’ensemble de vos achats alimentaires, quel pourcentage est certifié bio (AB, EU Bio…) ?",
    answers: ["Moins de 10%", "10% - 30%", "30% - 50%", "Plus de 50%"],
    labelPoints: {
      Écotable: [0, 0.3, 0.6, 1],
      "La Clef Verte": [0, 0.3, 0.6, 1],
      "Green Food": [0, 0.3, 1, 1],
      "Bon pour le Climat": [0, 0.3, 0.6, 1],
      "V-Label": [0, 0, 0, 0],
      Fig: [0, 0.3, 0.6, 1],
      Framheim: [0, 0.3, 0.6, 1],
      "AB Bio": [0, 0.3, 0.8, 1],
      "Ecolabel Européen": [0, 0.3, 0.6, 1],
      "Ethic Ocean": [0, 0, 0, 0],
      ASC: [0, 0, 0, 0],
    },
  },
  {
    id: 3,
    category: "Approvisionnement",
    question:
      "Proposez-vous au moins un plat dont 95% des ingrédients sont certifiés bio (conforme au label AB) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 1],
      "Green Food": [0, 1],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 0],
      Fig: [0, 0.3],
      Framheim: [0, 1],
      "AB Bio": [0, 1],
      "Ecolabel Européen": [0, 0.3],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },
  {
    id: 4,
    category: "Approvisionnement",
    question:
      "Tenez-vous à jour un registre de tous vos fournisseurs avec preuves de leurs certifications (bio, local, etc.) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 1],
      "Green Food": [0, 1],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 0.3],
      Fig: [0, 0.3],
      Framheim: [0, 1],
      "AB Bio": [0, 1],
      "Ecolabel Européen": [0, 1],
      "Ethic Ocean": [0, 0.3],
      ASC: [0, 0.3],
    },
  },
  {
    id: 5,
    category: "Approvisionnement",
    question:
      "Proposez-vous en permanence au moins un plat végétarien ou végan ?",
    answers: ["Non", "Oui, végétarien", "Oui, végan"],
    labelPoints: {
      Écotable: [0, 1, 1],
      "La Clef Verte": [0, 1, 1],
      "Green Food": [0, 1, 1],
      "Bon pour le Climat": [0, 1, 1],
      "V-Label": [0, 1, 1],
      Fig: [0, 0.3, 1],
      Framheim: [0, 1, 1],
      "AB Bio": [0, 0, 0],
      "Ecolabel Européen": [0, 1, 1],
      "Ethic Ocean": [0, 0, 0],
      ASC: [0, 0, 0],
    },
  },

  // B. Déchets & gaspillage
  {
    id: 6,
    category: "Déchets & gaspillage",
    question:
      "Avez-vous mis en place un tri sélectif interne (carton, plastique, verre...) et veillez-vous à son suivi ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 1],
      "Green Food": [0, 1],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 0],
      Fig: [0, 0],
      Framheim: [0, 1],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 1],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },
  {
    id: 7,
    category: "Déchets & gaspillage",
    question:
      "Quelle est la part de vos biodéchets que vous valorisez (compost, collecte dédiée...) ?",
    answers: ["Aucune valorisation", "Une partie", "Tous mes biodéchets"],
    labelPoints: {
      Écotable: [0, 0.3, 1],
      "La Clef Verte": [0, 0.3, 1],
      "Green Food": [0, 0.3, 1],
      "Bon pour le Climat": [0, 0.3, 0.6],
      "V-Label": [0, 0, 0],
      Fig: [0, 0.3, 1],
      Framheim: [0, 0.3, 1],
      "AB Bio": [0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 1],
      "Ethic Ocean": [0, 0, 0],
      ASC: [0, 0, 0],
    },
  },
  {
    id: 8,
    category: "Déchets & gaspillage",
    question:
      "Quelles actions mettez-vous en place pour réduire le gaspillage alimentaire ?",
    answers: [
      "Aucune action spécifique",
      "1 action (ex. doggy-bags)",
      "2 à 3 actions",
      "Plus de 3 actions",
    ],
    labelPoints: {
      Écotable: [0, 0.3, 1, 1],
      "La Clef Verte": [0, 0.3, 1, 1],
      "Green Food": [0, 0.3, 1, 1],
      "Bon pour le Climat": [0, 0.3, 1, 1],
      "V-Label": [0, 0, 0, 0],
      Fig: [0, 0.3, 1, 1],
      Framheim: [0, 0.3, 1, 1],
      "AB Bio": [0, 0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 1, 1],
      "Ethic Ocean": [0, 0, 0, 0],
      ASC: [0, 0, 0, 0],
    },
  },
  {
    id: 9,
    category: "Déchets & gaspillage",
    question:
      "Avez-vous supprimé ou fortement réduit les plastiques à usage unique ?",
    answers: ["Non", "Partiellement", "Oui, totalement"],
    labelPoints: {
      Écotable: [0, 0.3, 1],
      "La Clef Verte": [0, 0.3, 1],
      "Green Food": [0, 0.3, 1],
      "Bon pour le Climat": [0, 0.3, 1],
      "V-Label": [0, 0, 0],
      Fig: [0, 0, 0],
      Framheim: [0, 0.3, 1],
      "AB Bio": [0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 1],
      "Ethic Ocean": [0, 0, 0],
      ASC: [0, 0, 0],
    },
  },

  // C. Énergie & eau
  {
    id: 10,
    category: "Énergie & eau",
    question:
      "Avez-vous installé des dispositifs d’économie d’eau (mousseurs, chasses d’eau double débit...) ?",
    answers: ["Non", "Partiellement", "Oui, partout"],
    labelPoints: {
      Écotable: [0, 0.3, 1],
      "La Clef Verte": [0, 0.3, 1],
      "Green Food": [0, 0.3, 1],
      "Bon pour le Climat": [0, 0.3, 1],
      "V-Label": [0, 0, 0],
      Fig: [0, 0, 0],
      Framheim: [0, 0.3, 1],
      "AB Bio": [0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 1],
      "Ethic Ocean": [0, 0, 0],
      ASC: [0, 0, 0],
    },
  },
  {
    id: 11,
    category: "Énergie & eau",
    question:
      "Suivez-vous régulièrement (hebdo/mensuel) votre consommation d’eau afin de détecter les fuites ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 1],
      "Green Food": [0, 1],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 0],
      Fig: [0, 0],
      Framheim: [0, 1],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 1],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },
  {
    id: 12,
    category: "Énergie & eau",
    question:
      "Quelles mesures appliquez-vous pour réduire votre consommation d’énergie ?",
    answers: [
      "Aucune mesure particulière",
      "Quelques mesures (LED, consignes d’extinction...)",
      "Mesures avancées + suivi régulier",
      "Mesures très avancées (isolation, équipements classe A...)",
    ],
    labelPoints: {
      Écotable: [0, 0.3, 0.6, 1],
      "La Clef Verte": [0, 0.3, 0.6, 1],
      "Green Food": [0, 0.3, 0.6, 1],
      "Bon pour le Climat": [0, 0.3, 0.6, 1],
      "V-Label": [0, 0, 0, 0],
      Fig: [0, 0, 0.3, 0.6],
      Framheim: [0, 0.3, 0.6, 1],
      "AB Bio": [0, 0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 0.6, 1],
      "Ethic Ocean": [0, 0, 0, 0],
      ASC: [0, 0, 0, 0],
    },
  },
  {
    id: 13,
    category: "Énergie & eau",
    question:
      "Avez-vous souscrit à un fournisseur d’électricité verte ou installé des énergies renouvelables (solaire...) ?",
    answers: ["Non", "Partiellement", "Oui"],
    labelPoints: {
      Écotable: [0, 0.3, 1],
      "La Clef Verte": [0, 0.3, 1],
      "Green Food": [0, 0.3, 1],
      "Bon pour le Climat": [0, 0.3, 1],
      "V-Label": [0, 0, 0],
      Fig: [0, 0, 0.3],
      Framheim: [0, 0.3, 1],
      "AB Bio": [0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 1],
      "Ethic Ocean": [0, 0, 0],
      ASC: [0, 0, 0],
    },
  },

  // D. Communication & management
  {
    id: 14,
    category: "Communication & management",
    question:
      "Le personnel est-il formé ou sensibilisé aux bonnes pratiques environnementales (tri, gaspillage, économies d'énergie…) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 1],
      "Green Food": [0, 1],
      "Bon pour le Climat": [0, 1],
      "V-Label": [0, 0],
      Fig: [0, 0.3],
      Framheim: [0, 1],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 1],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },
  {
    id: 15,
    category: "Communication & management",
    question:
      "Avez-vous rédigé et diffusé une charte d’engagement écologique (affichage interne, site web, menu...) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 1],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 1],
      "V-Label": [0, 0],
      Fig: [0, 0.3],
      Framheim: [0, 1],
      "AB Bio": [0, 0.3],
      "Ecolabel Européen": [0, 1],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },
  {
    id: 16,
    category: "Communication & management",
    question:
      "Indiquez-vous sur la carte (ou ailleurs) les origines géographiques, le caractère bio ou labellisé des ingrédients ?",
    answers: ["Non", "Partiellement", "Oui"],
    labelPoints: {
      Écotable: [0, 0.3, 1],
      "La Clef Verte": [0, 0.3, 1],
      "Green Food": [0, 0.3, 1],
      "Bon pour le Climat": [0, 0.3, 1],
      "V-Label": [0, 0.3, 1],
      Fig: [0, 0.3, 1],
      Framheim: [0, 1, 1],
      "AB Bio": [0, 1, 1],
      "Ecolabel Européen": [0, 1, 1],
      "Ethic Ocean": [0, 0.3, 1],
      ASC: [0, 0.3, 1],
    },
  },
  {
    id: 17,
    category: "Communication & management",
    question:
      "Calculez-vous ou affichez-vous une estimation carbone pour certains plats (empreinte CO₂) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 0.3],
      "La Clef Verte": [0, 0.3],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 1],
      "V-Label": [0, 0],
      Fig: [0, 1],
      Framheim: [0, 0.3],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 0.3],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },

  // E. Pêche durable & aquaculture
  {
    id: 18,
    category: "Pêche durable & aquaculture",
    question:
      "Vérifiez-vous systématiquement la zone de pêche et la méthode de capture des poissons / fruits de mer que vous achetez ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 0.3],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 0],
      Fig: [0, 0.3],
      Framheim: [0, 0.3],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 1],
      "Ethic Ocean": [0, 1],
      ASC: [0, 0.3],
    },
  },
  {
    id: 19,
    category: "Pêche durable & aquaculture",
    question:
      "Évitez-vous les espèces listées comme menacées ou sur liste rouge (ex. IUCN) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 1],
      "La Clef Verte": [0, 1],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 0],
      Fig: [0, 0.3],
      Framheim: [0, 1],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 1],
      "Ethic Ocean": [0, 1],
      ASC: [0, 0.3],
    },
  },
  {
    id: 20,
    category: "Pêche durable & aquaculture",
    question:
      "Pour les poissons/crustacés d’élevage, choisissez-vous des fournisseurs certifiés ASC ?",
    answers: ["Non", "Parfois", "Oui, systématiquement"],
    labelPoints: {
      Écotable: [0, 0.3, 1],
      "La Clef Verte": [0, 0.3, 1],
      "Green Food": [0, 0.3, 1],
      "Bon pour le Climat": [0, 0.3, 1],
      "V-Label": [0, 0, 0],
      Fig: [0, 0.3, 1],
      Framheim: [0, 0.3, 1],
      "AB Bio": [0, 0, 0],
      "Ecolabel Européen": [0, 0.3, 1],
      "Ethic Ocean": [0, 0.3, 1],
      ASC: [0, 0.3, 1],
    },
  },
  {
    id: 21,
    category: "Pêche durable & aquaculture",
    question:
      "Séparez-vous clairement en cuisine/stockage les produits ASC des autres pour éviter toute confusion ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 0.3],
      "La Clef Verte": [0, 0.3],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 0],
      "V-Label": [0, 0],
      Fig: [0, 0],
      Framheim: [0, 0.3],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 0.3],
      "Ethic Ocean": [0, 0],
      ASC: [0, 1],
    },
  },

  // F. V-Label
  {
    id: 22,
    category: "V-Label (options végan / végé)",
    question:
      "Pour vos plats étiquetés 'végétarien' ou 'végan', vérifiez-vous scrupuleusement l’absence de tout ingrédient d’origine animale (gélatine, présure, etc.) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 0.3],
      "La Clef Verte": [0, 0],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 1],
      Fig: [0, 0.3],
      Framheim: [0, 0.3],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 0.3],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },
  {
    id: 23,
    category: "V-Label (options végan / végé)",
    question:
      "Avez-vous mis en place des procédures pour éviter la contamination croisée (ustensiles dédiés, zones séparées...) ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 0.3],
      "La Clef Verte": [0, 0],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 0.3],
      "V-Label": [0, 1],
      Fig: [0, 0.3],
      Framheim: [0, 0.3],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 0.3],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },

  // G. Fig (transparence & nutrition)
  {
    id: 24,
    category: "Fig (transparence & nutrition)",
    question:
      "Indiquez-vous sur votre menu des informations nutritionnelles (allergènes, teneur en sel/sucre...) ?",
    answers: ["Non", "Partiellement", "Oui, complet"],
    labelPoints: {
      Écotable: [0, 0.3, 0.6],
      "La Clef Verte": [0, 0.3, 0.6],
      "Green Food": [0, 0.3, 0.6],
      "Bon pour le Climat": [0, 0, 0],
      "V-Label": [0, 0.3, 1],
      Fig: [0, 0.3, 1],
      Framheim: [0, 0.3, 0.6],
      "AB Bio": [0, 0.3, 0.6],
      "Ecolabel Européen": [0, 0.3, 0.6],
      "Ethic Ocean": [0, 0.3, 0.6],
      ASC: [0, 0, 0],
    },
  },
  {
    id: 25,
    category: "Fig (transparence & nutrition)",
    question:
      "Avez-vous déjà réalisé un scoring (Eco-score, Nutri-score, score Fig...) et le communiquez-vous aux clients ?",
    answers: ["Non", "Oui"],
    labelPoints: {
      Écotable: [0, 0.3],
      "La Clef Verte": [0, 0.3],
      "Green Food": [0, 0.3],
      "Bon pour le Climat": [0, 1],
      "V-Label": [0, 0],
      Fig: [0, 1],
      Framheim: [0, 0.3],
      "AB Bio": [0, 0],
      "Ecolabel Européen": [0, 0.3],
      "Ethic Ocean": [0, 0],
      ASC: [0, 0],
    },
  },
];

export default function LabelTest() {
  const navigate = useNavigate();

  const [answers, setAnswers] = useState(
    Array(questionsData.length).fill(null)
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();

  // Met à jour la réponse pour la question index
  const handleAnswerChange = (questionIndex, answerIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = answerIndex;
    setAnswers(newAnswers);
  };

  // Gère le bouton "Suivant" ou "Soumettre"
  const handleNext = () => {
    if (currentIndex < questionsData.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    } else {
      // Dernière question => soumission
      handleSubmit();
    }
  };

  // Calcule les résultats pour chaque label
  const calculateResults = () => {
    let labelScores = {};
    let labelMaxScores = {};

    questionsData.forEach((question, qIndex) => {
      const userAnswerIndex = answers[qIndex];
      // On itère sur chaque label pour accumuler les scores
      Object.entries(question.labelPoints).forEach(([label, pointsArray]) => {
        const questionMax = Math.max(...pointsArray);
        labelMaxScores[label] = (labelMaxScores[label] || 0) + questionMax;

        if (userAnswerIndex !== null) {
          const score = pointsArray[userAnswerIndex] || 0;
          labelScores[label] = (labelScores[label] || 0) + score;
        }
      });
    });

    // On convertit chaque score en pourcentage
    let results = {};
    LABELS.forEach((label) => {
      const totalScore = labelScores[label] || 0;
      const maxScore = labelMaxScores[label] || 0;
      const percent = maxScore > 0 ? (totalScore / maxScore) * 100 : 0;
      results[label] = parseFloat(percent.toFixed(1));
    });

    return results;
  };

  // Fonction de soumission : calcul + envoi + passage à l'écran "résultats"
  const handleSubmit = async () => {
    setIsLoading(true);

    // 1) Calculer les résultats
    const results = calculateResults();

    try {
      // 2) Sauvegarder côté back-end
      // On suppose que user.id contient l'ID de l'utilisateur
      const response = await saveTest(results, user?.id);
      console.log("Résultats enregistrés :", response);
    } catch (error) {
      console.error("Erreur lors de l'enregistrement :", error);
    } finally {
      // 3) Fin du chargement et affichage du résultat
      setIsLoading(false);
      setIsSubmitted(true);
    }
  };

  // On recalcule les résultats pour l'affichage final
  const results = calculateResults();

  // Loader (après le clic sur "Soumettre")
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mb-4"></div>
        <p className="text-blue-600 font-semibold">Calcul en cours...</p>
      </div>
    );
  }

  // Une fois soumis, on affiche un résumé
  if (isSubmitted) {
    // On affiche clairement le premier label
    const firstLabel = LABELS[0];
    const firstPercent = results[firstLabel] || 0;
    // Les autres labels
    const otherLabels = LABELS.slice(1);

    return (
      <div className="container mx-auto p-4">
        <h1 className="text-roca-h4-5 mb-6 text-center">
          Résultats du questionnaire
        </h1>

        <div className="bg-white rounded-lg shadow p-6 min-h-[300px] flex flex-col">
          <h2 className="text-roca-h6 font-semibold mb-4 !text-secondary-600">
            Vos scores
          </h2>

          {/* 1) Affichage distinct du premier label */}
          <div className="text-poppins-small mb-4">
            <p className="font-medium">
              {firstLabel} : {firstPercent.toFixed(1)}%
            </p>
            <div className="w-full bg-gray-200 h-2 rounded">
              <div
                className="bg-primary-600 h-2 rounded"
                style={{ width: `${firstPercent}%` }}
              />
            </div>
          </div>

          {/* 2) Autres labels floutés */}
          <div className="relative">
            {/* 
            Remarque : on peut laisser ou enlever le blur CSS.
            Ici, on met un blur "pour le style", 
            mais de toute façon on n'affiche pas la vraie valeur (???).
          */}
            <div
              className="space-y-4 text-poppins-small pointer-events-none select-none"
              style={{ filter: "blur(4px)" }}
            >
              {otherLabels.map((label) => {
                // On n'utilise PAS results[label]
                // On affiche "???" et une barre à 0%
                return (
                  <div key={label}>
                    <p className="font-medium">{label} : ???%</p>
                    <div className="w-full bg-gray-200 h-2 rounded">
                      <div
                        className="bg-primary-600 h-2 rounded"
                        style={{ width: `0%` }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            {/* 3) Overlay pour accès au rapport complet */}
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <button
                className="bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700"
                onClick={() => navigate("payement")}
              >
                Accéder au rapport complet
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Sinon, on affiche la question courante
  const q = questionsData[currentIndex];
  const userAnswer = answers[currentIndex];

  return (
    <QuestionCard
      q={q}
      currentIndex={currentIndex}
      questionsData={questionsData}
      userAnswer={userAnswer}
      handleAnswerChange={handleAnswerChange}
      handleNext={handleNext}
    />
  );
}
