import React from "react";
import ImageMission from "../../assets/images/Mission/Mission.png";

const Mission = () => {
  return (
    <div className="flex flex-col items-center bg-[#F4F1E9] py-12 px-6 h-auto md:h-screen">
      {/* Titre et sous-titre */}
      <div className="w-full max-w-6xl mb-12 text-center">
        <p className="text-poppins-extra-small mb-2 text-left">
          Notre raison d’être
        </p>
        <h1 className="text-roca-h3 text-left">Mission</h1>
      </div>

      {/* Contenu principal : image et texte */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl items-center">
        {/* Colonne de gauche : image */}
        <div className="flex justify-center">
          <img
            src={ImageMission}
            alt="Illustration de la mission"
            className="w-full h-auto object-contain"
          />
        </div>

        {/* Colonne de droite : texte descriptif */}
        <div className="flex flex-col justify-center items-start gap-4">
          <div>
            <h1 className="font-bold text-roca-paragraph">
              Sensibiliser et informer
            </h1>
            <p className="text-poppins-small">
              Nous aidons les restaurateurs à mieux comprendre les enjeux de
              l’écoresponsabilité et les bénéfices d’une cuisine durable grâce à
              des formations et des ressources pédagogiques.
            </p>
          </div>
          <div>
            <h1 className="font-bold text-roca-paragraph">
              Accompagner et conseiller
            </h1>
            <p className="text-poppins-small">
              Chaque établissement est unique. Nous proposons des audits
              personnalisés et un plan d’action adapté pour répondre aux
              critères des labels choisis.
            </p>
          </div>
          <div>
            <h1 className="font-bold text-roca-paragraph">
              Valoriser les engagements
            </h1>
            <p className="text-poppins-small">
              Nous aidons les restaurants labellisés à se démarquer grâce à des
              outils de communication, une mise en avant sur notre plateforme et
              auprès de nos partenaires.
            </p>
          </div>
          <div>
            <h1 className="font-bold text-roca-paragraph">
              Créer une communauté écoresponsable
            </h1>
            <p className="text-poppins-small">
              Rejoignez un réseau de restaurateurs engagés, partagez vos
              expériences, et inspirez d’autres établissements à suivre le
              mouvement !
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
