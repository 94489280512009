import React, { useEffect, useState } from "react";
import DropDownSelect from "./DropDownSelect";
import SearchInput from "./SearchInput";

const SearchBar = () => {
  const cities = [
    { label: "Paris", value: "paris", lat: 48.8566, lon: 2.3522 },
    { label: "Rennes", value: "rennes", lat: 48.1173, lon: -1.6778 },
    { label: "Lyon", value: "lyon", lat: 45.764, lon: 4.8357 },
    { label: "Marseille", value: "marseille", lat: 43.2965, lon: 5.3698 },
    { label: "Toulouse", value: "toulouse", lat: 43.6047, lon: 1.4442 },
    { label: "Bordeaux", value: "bordeaux", lat: 44.8378, lon: -0.5792 },
  ];

  const [selectedCity, setSelectedCity] = useState(cities[0]);
  const [geolocatedCity, setGeolocatedCity] = useState(null);

  const handleCityChange = (city) => {
    setSelectedCity(city); // Stocke la ville avec ses coordonnées
  };

  const handleGeolocation = (city) => {
    setGeolocatedCity(city); // Met à jour la ville récupérée via la géolocalisation
  };

  const currentCity = geolocatedCity || selectedCity;

  useEffect(() => {
    localStorage.setItem("currentCity", JSON.stringify(currentCity));
  }, [currentCity]);

  return (
    <div className="flex items-center bg-white rounded-4 shadow-md">
      <DropDownSelect
        cities={cities}
        selectedCity={currentCity}
        handleCityChange={handleCityChange}
        handleGeolocation={handleGeolocation}
      />
      <SearchInput currentCity={currentCity} />
    </div>
  );
};

export default SearchBar;
