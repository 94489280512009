import React from "react";
import MultiStepRegistrationForm from "./MultiStepRegistrationForm";
import loginImage from "../../../assets/images/Login/login3.png";

const RegisterPart = () => {
  return (
    <div className="w-full h-screen flex overflow-hidden">
      <div className="hidden md:block mt-[17px] ml-[17px] mb-[17px] md:w-[684px] rounded-20">
        <img
          src={loginImage}
          alt="Illustration de connexion"
          className="h-full w-[684px]"
        />
      </div>

      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full md:w-[530px]">
          <MultiStepRegistrationForm />
        </div>
      </div>
    </div>
  );
};

export default RegisterPart;
