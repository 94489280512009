import React, { useState } from "react";
import { useAuth } from "../Auth/Provider/AuthProvider";
import Input from "../elements/Input/Input";
import Button from "../elements/Button/Button";
import Swal from "sweetalert2";
import { updateUserById } from "../../services/user.services";

const AuthenticationRequired = () => (
  <div className="text-center p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
    <h2 className="text-xl font-semibold mb-4">Connexion requise</h2>
    <p className="text-gray-600 mb-6">
      Pour devenir propriétaire sur notre plateforme, vous devez d'abord vous
      connecter ou créer un compte.
    </p>
    <div className="flex flex-col gap-4">
      <Button link="/login" colorType="secondary">
        Se connecter
      </Button>
      <Button link="/register/pro">Créer un compte professionnel</Button>
      <Button link="/" colorType="tertiary">
        Retour à l'accueil
      </Button>
    </div>
  </div>
);

const AlreadyOwner = () => (
  <div className="text-center p-6 bg-white rounded-lg shadow-lg max-w-md mx-auto">
    <h2 className="text-xl font-semibold mb-4">Statut propriétaire</h2>
    <p className="text-gray-600 mb-6">
      Vous êtes déjà enregistré en tant que propriétaire sur notre plateforme.
    </p>
    <div className="flex flex-col gap-4">
      <Button link="/profil" colorType="secondary">
        Accéder à mon profil
      </Button>
      <Button link="/" colorType="tertiary">
        Retour à l'accueil
      </Button>
    </div>
  </div>
);

const BenefitsSection = () => (
  <div className="bg-white p-6 rounded-20 shadow-lg">
    <h2 className="text-2xl font-semibold mb-6 text-secondary-600">
      Pourquoi devenir propriétaire ?
    </h2>
    <div className="space-y-6">
      <BenefitItem
        icon="🌱"
        title="Impact écologique"
        description="Rejoignez une communauté engagée dans la transition écologique et contribuez à un tourisme plus responsable."
      />
      <BenefitItem
        icon="💼"
        title="Visibilité accrue"
        description="Bénéficiez d'une plateforme dédiée pour mettre en valeur votre établissement auprès d'une clientèle sensible aux valeurs écologiques."
      />
      <BenefitItem
        icon="🤝"
        title="Accompagnement personnalisé"
        description="Profitez de notre expertise et de nos outils pour optimiser votre transition écologique et valoriser vos efforts."
      />
      <BenefitItem
        icon="📈"
        title="Opportunités business"
        description="Accédez à un marché en pleine croissance et développez votre activité auprès d'une clientèle engagée."
      />
    </div>
  </div>
);

const BenefitItem = ({ icon, title, description }) => (
  <div>
    <h3 className="font-medium text-lg mb-2 text-gray-800">
      {icon} {title}
    </h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const RegistrationForm = ({ user, onSubmit, isLoading }) => {
  const [formData, setFormData] = useState({
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    email: user?.email || "",
    company_name: "",
    company_siret: "",
    company_address: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "company_siret") {
      const cleanValue = value.replace(/\D/g, "").slice(0, 14);
      const formattedValue = cleanValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{5})/,
        "$1 $2 $3 $4"
      );
      setFormData((prev) => ({ ...prev, [name]: formattedValue }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.company_name) {
      newErrors.company_name = "Le nom de l'entreprise est requis";
    }
    if (!formData.company_siret) {
      newErrors.company_siret = "Le numéro SIRET est requis";
    } else if (formData.company_siret.replace(/\s/g, "").length !== 14) {
      newErrors.company_siret = "Le numéro SIRET doit contenir 14 chiffres";
    }
    if (!formData.company_address) {
      newErrors.company_address = "L'adresse de l'entreprise est requise";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-8 rounded-20 shadow-lg">
      <h2 className="text-xl font-semibold mb-6 text-gray-800">
        Informations de votre établissement
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Input
          label="Prénom"
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
          disabled
        />
        <Input
          label="Nom"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          disabled
        />
      </div>

      <Input
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        disabled
      />

      <Input
        label="Nom de l'entreprise"
        name="company_name"
        value={formData.company_name}
        onChange={handleChange}
        error={errors.company_name}
      />

      <Input
        label="Numéro SIRET"
        name="company_siret"
        value={formData.company_siret}
        onChange={handleChange}
        placeholder="XXX XXX XXX XXXXX"
        error={errors.company_siret}
      />

      <Input
        label="Adresse de l'entreprise"
        name="company_address"
        value={formData.company_address}
        onChange={handleChange}
        error={errors.company_address}
      />

      <div className="flex justify-center mt-6">
        <Button
          type="submit"
          colorType="secondary"
          disabled={isLoading}
          className="w-full md:w-auto"
        >
          {isLoading ? "Envoi en cours..." : "Devenir propriétaire"}
        </Button>
      </div>
    </form>
  );
};

const BecomeOwner = () => {
  const { user, handleLogin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistrationSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const updatedUser = await updateUserById(user.id, {
        ...formData,
        role: "owner",
      });

      await Swal.fire({
        icon: "success",
        title: "Votre demande a été envoyée !",
        text: "Nous examinerons votre demande dans les plus brefs délais.",
        confirmButtonText: "OK",
      });

      handleLogin(updatedUser.token);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue",
        text: error.response?.data?.message || "Veuillez réessayer plus tard",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative min-h-screen">
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat opacity-10"
        style={{
          backgroundImage: "url('/images/map-background.jpg')",
          zIndex: 0,
        }}
      />

      <div className="relative z-10 max-w-7xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold text-center mb-12">
          Devenir propriétaire
        </h1>

        {!user ? (
          <AuthenticationRequired />
        ) : user.role === "owner" ? (
          <AlreadyOwner />
        ) : (
          <div className="flex flex-col lg:flex-row gap-12 items-start">
            <div className="lg:w-1/3">
              <BenefitsSection />
            </div>
            <div className="lg:w-2/3">
              <RegistrationForm
                user={user}
                onSubmit={handleRegistrationSubmit}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BecomeOwner;
