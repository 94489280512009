import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faLocationArrow } from "@fortawesome/free-solid-svg-icons";

const customIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/684/684908.png", // URL de l'icône de pin
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const MapPinMyRestaurant = ({ setPinCoordinates, pinCoordinates }) => {
  const AddPin = ({ setCoordinates, pinCoordinates }) => {
    useMapEvents({
      click(e) {
        setCoordinates({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        });
      },
    });
    return pinCoordinates === null ? null : (
      <Marker position={pinCoordinates} icon={customIcon} />
    );
  };
  return (
    <div className="relative">
      <p className="mb-4 bg-yellow-100 border-l-8 border-yellow-300 rounded-8 p-4">
        Cliquez sur la carte pour placer un point à l'emplacement exact de votre
        restaurant. Attention, le point que vous placez seras en prioritée sur
        l'adresse
      </p>

      <MapContainer
        center={[48.8566, 2.3522]}
        zoom={13}
        scrollWheelZoom={true}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <AddPin
          pinCoordinates={pinCoordinates}
          setCoordinates={setPinCoordinates}
        />
      </MapContainer>
    </div>
  );
};

export default MapPinMyRestaurant;
