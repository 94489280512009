import React, { useState } from "react";
import Button from "../../elements/Button/Button";

const RatingFilter = ({ onFilterChange }) => {
  const [activeFilter, setActiveFilter] = useState("");

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    onFilterChange(filter);
  };

  const resetFilter = () => {
    setActiveFilter("");
    onFilterChange(""); // Informe le parent que le filtre est réinitialisé
  };

  return (
    <div className="flex flex-row space-x-4">
      <Button
        colorType={activeFilter === "bestToWorst" ? "primary" : "white_border"}
        onClick={() => handleFilterChange("bestToWorst")}
        className="text-blue-500"
      >
        Les mieux notés
      </Button>
      <Button
        colorType={activeFilter === "worstToBest" ? "primary" : "white_border"}
        onClick={() => handleFilterChange("worstToBest")}
        className="text-blue-500"
      >
        Les moins bien notés
      </Button>
      <Button
        colorType={activeFilter === "mostRecent" ? "primary" : "white_border"}
        onClick={() => handleFilterChange("mostRecent")}
        className="text-blue-500"
      >
        Les plus récents
      </Button>
      <Button
        colorType={activeFilter === "mostLiked" ? "primary" : "white_border"}
        onClick={() => handleFilterChange("mostLiked")}
        className="text-blue-500"
      >
        Les plus aimés
      </Button>

      {/* Bouton "Réinitialiser" qui apparaît si un filtre est actif */}
      {activeFilter && (
        <Button
          colorType=""
          onClick={resetFilter}
          className="text-red-500"
        >
          Réinitialiser
        </Button>
      )}
    </div>
  );
};

export default RatingFilter;
