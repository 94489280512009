import React from "react";
import AddDocuments from "./AddDocuments";
import AddEtiquette from "../AddEtiquette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFile,
  faFilePdf,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import imageFiles from "../../../../../assets/images/Icons/files.png";

const FieldSet = ({
  legend,
  description,
  content,
  FieldDocumentData,
  FieldDocumentText,
  checkBoxData,

  EtiquetteData,
  EtiqueteFields,
  EtiquetteText,
}) => {
  const handleAddInvoice = (invoices, setter) => (document) => {
    setter([...invoices, document]);
  };

  const handleAddEtiquette = (list, setter) => (item) => {
    setter([...list, item]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = FieldDocumentData[0].filter((_, i) => i !== index);
    FieldDocumentData[1](newFiles);
  };

  const handleRemoveItem = (index) => {
    const newData = EtiquetteData[0].filter((_, i) => i !== index);
    EtiquetteData[1](newData);
  };

  const getContent = (content) => {
    if (content === "document") {
      return (
        <div>
          <AddDocuments
            category={FieldDocumentText.category}
            title={FieldDocumentText.title}
            onAdd={handleAddInvoice(FieldDocumentData[0], FieldDocumentData[1])}
          />

          {FieldDocumentData[0].length > 0 && (
            <div className="border border-gray-300 flex flex-col gap-4 p-4 mt-4 rounded-20">
              <h1 className="text-poppins-small">
                Vous avec choisi les document suivant
              </h1>

              {FieldDocumentData[0].map((doc, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between border border-gray-300 p-4 rounded-lg"
                >
                  {/* Icône PDF et Infos Fichier */}
                  <div className="flex items-center gap-4">
                    <img src={imageFiles} alt="" />

                    <div>
                      <p className="text-poppins-small font-medium">
                        {doc.file.name}
                      </p>
                      <p className="text-sm text-gray-500">
                        {(doc.file.size / 1024).toFixed(2)} KB
                      </p>
                    </div>
                  </div>

                  {/* Actions : Voir & Supprimer */}
                  <div className="flex gap-3">
                    <a
                      href={URL.createObjectURL(doc.file)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-700 hover:text-primary-600"
                    >
                      <FontAwesomeIcon icon={faEye} className="text-xl" />
                    </a>

                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-xl" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    if (content === "etiquette") {
      return (
        <div>
          <AddEtiquette
            title={EtiquetteText.title}
            fields={EtiqueteFields}
            onAdd={handleAddEtiquette(EtiquetteData[0], EtiquetteData[1])}
          />

          {/* Affichage des étiquettes ajoutées */}
          {EtiquetteData[0].length > 0 && (
            <div className="border border-gray-300 flex flex-col gap-4 p-4 mt-4 rounded-lg bg-white shadow-md">
              <h1 className="text-lg font-semibold text-gray-800">
                Vous avez ajouté les éléments suivants :
              </h1>
              <div className=" flex flex-col gap-4">
                {EtiquetteData[0].map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between text-poppins-small border border-gray-300 p-4 rounded-lg gap-4"
                  >
                    <div className="flex gap-4">
                      <p>
                        {item.name} - {item.example}
                      </p>

                      {item.file && (
                        <div className="flex gap-3">
                          <a
                            href={URL.createObjectURL(item.file)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-700 hover:text-primary-600"
                          >
                            <FontAwesomeIcon icon={faEye} className="text-xl" />
                          </a>
                        </div>
                      )}
                    </div>

                    <button
                      onClick={() => handleRemoveItem(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-lg" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    if (content === "checkbox") {
      return (
        <div>
          <label className="text-poppins-small">
            <input
              type="checkbox"
              checked={checkBoxData[0]}
              onChange={(e) => checkBoxData[1](e.target.checked)}
              className="mr-2"
            />
            Oui
          </label>
        </div>
      );
    }
  };

  return (
    <fieldset className="">
      <legend className="text-poppins-paragraph">{legend}</legend>
      <p className="text-poppins-small">{description}</p>

      <div className="mt-4">{getContent(content)}</div>
    </fieldset>
  );
};

export default FieldSet;
