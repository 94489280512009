import React from "react";
import ShowSelectedFiles from "../ShowFiles/ShowSelectedFiles";
import InformationRequest from "./InformationRequest";

const GreenFoodComponent = ({
  labelData,
  labelRequest,
  isAuditor,
  onDocumentStatusChange,
  onDocumentFeedback,
}) => {
  if (!labelData) return null;


  // Fonction d’aide pour afficher une section de fichiers
  const renderFilesSection = (invoices, defaultTitle) => (
    <ShowSelectedFiles
      title={invoices.map((doc) => doc.title || defaultTitle)}
      fileIds={invoices.map((doc) => doc.file)}
      isAuditor={isAuditor}
      onStatusChange={onDocumentStatusChange}
      onFeedbackSubmit={onDocumentFeedback}
    />
  );

  return (
    <div className="space-y-4 text-poppins-small">
      <div className="border rounded-lg p-4">
        {!isAuditor && <InformationRequest labelRequest={labelRequest} />}

        <div className="mt-4 space-y-4">
          {/* Tri Sélectif */}
          <p>
            <strong>Tri sélectif :</strong>{" "}
            {labelData.selectiveSorting ? "Oui" : "Non"}
          </p>

          {/* Doggy Bag */}
          <p>
            <strong>Doggy Bag :</strong> {labelData.doggyBag ? "Oui" : "Non"}
          </p>

          {/* Produits locaux / bio */}
          <div>
            <h5 className="font-bold">Produits locaux / bio</h5>
            {renderFilesSection(
              labelData.localProductsInvoices,
              "Produits locaux / bio"
            )}
          </div>

          {/* Produits d'entretien écologique */}
          <div>
            <h5 className="font-bold">Produits d'entretien écologique</h5>
            {renderFilesSection(
              labelData.ecoCleaningInvoices,
              "Produits d'entretien écologique"
            )}
          </div>

          {/* Associations */}
          <div>
            <h5 className="font-bold">Associations</h5>
            {labelData.associations.length > 0 ? (
              labelData.associations.map((association, index) => (
                <div key={index} className="border p-2 rounded-md mb-2">
                  <p>
                    <strong>Nom :</strong> {association.name || "Non spécifié"}
                  </p>
                  <p>
                    <strong>Exemple :</strong>{" "}
                    {association.example || "Non spécifié"}
                  </p>
                </div>
              ))
            ) : (
              <p>Aucune donnée</p>
            )}
          </div>

          {/* Revalorisation des déchets organiques */}
          <div>
            <h5 className="font-bold">Revalorisation des déchets organiques</h5>
            {labelData.organicWasteExamples.length > 0 ? (
              labelData.organicWasteExamples.map((example, index) => (
                <div key={index} className="border p-2 rounded-md mb-2">
                  <p>
                    <strong>Exemple :</strong>{" "}
                    {example.example || "Non spécifié"}
                  </p>
                </div>
              ))
            ) : (
              <p>Aucune donnée</p>
            )}
          </div>

          {/* Produits frais */}
          <div>
            <h5 className="font-bold">Produits frais</h5>
            {renderFilesSection(
              labelData.freshProductsInvoices,
              "Produits frais"
            )}
          </div>

          {/* Factures d'eau et d'énergie */}
          <div>
            <h5 className="font-bold">Factures d'eau et d'énergie</h5>
            {renderFilesSection(
              labelData.waterEnergyInvoices,
              "Factures d'eau et d'énergie"
            )}
          </div>

          {/* Menu végétarien */}
          <div>
            <h5 className="font-bold">Menu végétarien</h5>
            {renderFilesSection(
              labelData.vegetarianMenuFiles,
              "Menu végétarien"
            )}
          </div>

          {/* Énergies renouvelables */}
          <div>
            <h5 className="font-bold">Énergies renouvelables</h5>
            {renderFilesSection(
              labelData.renewableEnergyDocs,
              "Énergies renouvelables"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreenFoodComponent;
