import React from "react";
import Button from "../elements/Button/Button";

const ModalAnswer = ({ isOpen, onClose, question, answer }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 w-full h-full">
      <div className="bg-white rounded-lg p-8 w-11/12 max-w-md mx-auto shadow-lg transform transition-all duration-300">
        <div className="text-black text-center mb-6">
          <h1 className="text-2xl font-bold mb-2">{question}</h1>
        </div>
        <div className="text-gray-700 mt-4 text-center text-lg">{answer}</div>
        <div className="mt-6 text-center">
          <Button onClick={onClose}>Compris</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalAnswer;
